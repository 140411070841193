export const HOME = '/private/'
export const LOGIN = '/login'

// Private routes
export const USER_ONBOARDING = '/private/onboarding'
export const LINK_STORE = '/private/link-store'
export const ADMIN_ACCOUNT_MANAGEMENT = '/private/admin/accounts'
export const ADMIN_PREAPPROVED_USERS = '/private/admin/pre-approve-users'
export const LOGOUT = '/private/logout'
export const USER_PROFILE = '/private/user/profile'
export const ORDER_STATUS = '/private/orders'
export const PRODUCTS = '/private/products'
const NEXT3PL_TENANT_ID = 'zY3KWhYaCQYy4b0bLR2M'
export const NEXT3PL_CLIENT = `/private/${NEXT3PL_TENANT_ID}`
export const NEXT3PL_TENANT_PROFILE = `/private/tenant/${NEXT3PL_TENANT_ID}`
export const ADD_CONNECTOR = '/private/connector-dashboard'
export const EBAY_OAUTH_SUCCESS = '/private/ebay-oauth-success'
export const XERO_OAUTH_SUCCESS = '/private/xero-oauth-success'
export const VIEW_CONNECTORS = '/private/view-connectors'
export const DASHBOARD = '/private/dashboard'
export const BILLING = '/private/billing'
export const XERO_LINKS = '/private/xero-links'
