import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const ChoosePlatform = props => {
  const { handlePlatformChange, updateCurrentStep, parentPlatform } = props
  const [platform, setPlatform] = useState()

  useEffect(() => {
    if (parentPlatform) {
      setPlatform(parentPlatform)
    }
  }, [parentPlatform])

  const onChange = e => {
    handlePlatformChange(e.target.value)
    setPlatform(e.target.value)
  }
  const handleNext = () => {
    if (platform) {
      updateCurrentStep(2)
    }
  }
  return (
    <>
      <div className='max-w-2xl container mx-auto px-4'>
        <form className='space-y-8 divide-y divide-gray-200'>
          <div className='space-y-8 divide-y divide-gray-200'>
            <div className='pt-8'>
              <div>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  Choose a platform
                </h3>
              </div>
              <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='sm:col-span-6'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-gray-700'
                  >
                    Platform
                  </label>
                  <select
                    required
                    id='country'
                    name='country'
                    autoComplete='country'
                    onChange={e => onChange(e)}
                    className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                    defaultValue='default'
                  >
                    <option value='default' disabled>
                      Select a platform
                    </option>
                    <option value='Amazon'>Amazon</option>
                    <option value='BigCommerce'>BigCommerce</option>
                    <option value='eBay'>eBay</option>
                    <option value='Neto'>Maropost Commerce Cloud (formerly Neto)</option>
                    <option value='Magento'>Magento</option>
                    <option value='Shopify'>Shopify</option>
                    <option value='WooCommerce'>WooCommerce</option>
                    <option value='Xero'>Xero</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                onClick={() => handleNext()}
                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

ChoosePlatform.propTypes = {
  handlePlatformChange: PropTypes.func.isRequired,
  updateCurrentStep: PropTypes.func.isRequired,
  parentPlatform: PropTypes.string.isRequired
}

export default ChoosePlatform
