import { useContext, useState } from 'react'
import { SmileOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import { Navigate, Link } from 'react-router-dom'
import { FirestoreContext } from '../Firebase/firestoreContext'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { NEXT3PL_TENANT_PROFILE, VIEW_CONNECTORS } from '../../constants/routes'
import ChoosePlatformForm from './choosePlatformForm'
import EnterDetailsForm from './enterDetailsForm'
import Breadcrumb from '../Breadcrumb'
import { CheckIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'

/* eslint-enable no-template-curly-in-string */
const ConnectorDashboard = props => {
  const { hidden, handleTabChange, tenantId, clientId } = props
  const { userDoc } = useContext(FirestoreContext)
  const [currentStep, setCurrrentStep] = useState(1)
  const [platform, setPlatform] = useState('Shopify')
  const [steps, setSteps] = useState([
    { id: '01', name: 'Choose platform', href: '#', status: 'current' },
    { id: '02', name: 'Enter Details', href: '#', status: 'upcoming' },
    { id: '03', name: 'Complete', href: '#', status: 'upcoming' }
  ])

  const handlePlatformChange = e => {
    setPlatform(e)
  }

  const updateCurrentStep = step => {
    setCurrrentStep(step)
    const newSteps = [...steps]
    if (step === 1) {
      newSteps[0].status = 'current'
      newSteps[1].status = 'upcoming'
      newSteps[2].status = 'upcoming'
    } else if (step === 2) {
      newSteps[0].status = 'complete'
      newSteps[1].status = 'current'
      newSteps[2].status = 'upcoming'
    } else if (step === 3) {
      newSteps[0].status = 'complete'
      newSteps[1].status = 'complete'
      newSteps[2].status = 'complete'
    }
    setSteps(newSteps)
  }

  return (
    <>
      {userDoc && !userDoc.isNextLogisticsCustomer
        ? (
          <>
            <Breadcrumb
              hidden={hidden}
              pages={[{ name: 'Connector Setup', href: '#', current: true }]}
            />
            <div className='pb-12 sm:rounded-lg shadow bg-white'>
              <nav aria-label='Progress'>
                <ol className='border border-gray-300 divide-y divide-gray-300 md:flex md:divide-y-0 bg-white'>
                  {steps.map((step, stepIdx) => (
                    <li key={step.name} className='relative md:flex-1 md:flex'>
                      {step.status === 'complete'
                        ? (
                          <div className='group flex items-center w-full'>
                            <span className='px-6 py-4 flex items-center text-sm font-medium'>
                              <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800'>
                                <CheckIcon
                                  className='w-6 h-6 text-white'
                                  aria-hidden='true'
                                />
                              </span>
                              <span className='ml-4 text-sm font-medium text-gray-900'>
                                {step.name}
                              </span>
                            </span>
                          </div>
                          )
                        : step.status === 'current'
                          ? (
                            <div
                              className='px-6 py-4 flex items-center text-sm font-medium'
                              aria-current='step'
                            >
                              <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full'>
                                <span className='text-indigo-600'>{step.id}</span>
                              </span>
                              <span className='ml-4 text-sm font-medium text-indigo-600'>
                                {step.name}
                              </span>
                            </div>
                            )
                          : (
                            <div className='group flex items-center'>
                              <span className='px-6 py-4 flex items-center text-sm font-medium'>
                                <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400'>
                                  <span className='text-gray-500 group-hover:text-gray-900'>
                                    {step.id}
                                  </span>
                                </span>
                                <span className='ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900'>
                                  {step.name}
                                </span>
                              </span>
                            </div>
                            )}

                      {stepIdx !== steps.length - 1
                        ? (
                          <>
                            {/* Arrow separator for lg screens and up */}
                            <div
                              className='hidden md:block absolute top-0 right-0 h-full w-5'
                              aria-hidden='true'
                            >
                              <svg
                                className='h-full w-full text-gray-300'
                                viewBox='0 0 22 80'
                                fill='none'
                                preserveAspectRatio='none'
                              >
                                <path
                                  d='M0 -2L20 40L0 82'
                                  vectorEffect='non-scaling-stroke'
                                  stroke='currentcolor'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          </>
                          )
                        : null}
                    </li>
                  ))}
                </ol>
              </nav>
              <div className='pt-4'>
                {currentStep === 1
                  ? (
                    <ChoosePlatformForm
                      handlePlatformChange={handlePlatformChange}
                      updateCurrentStep={updateCurrentStep}
                      parentPlatform={platform}
                    />
                    )
                  : currentStep === 2
                    ? (
                      <EnterDetailsForm
                        platform={platform}
                        updateCurrentStep={updateCurrentStep}
                        handleTabChange={handleTabChange}
                        tenantId={tenantId}
                        clientId={clientId}
                      />
                      )
                    : currentStep === 3
                      ? (
                        <Result
                          icon={<SmileOutlined />}
                          title='Great, we have done all the operations!'
                          subTitle='It may take a few minutes for your changes to be processed'
                          extra={
                            <Link to={VIEW_CONNECTORS}>
                              <button
                                type='primary'
                                size='middle'
                                className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              >
                                View Connectors
                              </button>
                            </Link>
                  }
                        />
                        )
                      : (
                        <></>
                        )}
              </div>
            </div>
          </>
          )
        : (
          <>{userDoc && <Navigate to={ NEXT3PL_TENANT_PROFILE } />}</>
          )}
    </>
  )
}

ConnectorDashboard.propTypes = {
  hidden: PropTypes.bool,
  handleTabChange: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired, // will need to change this when designing system for independant users
  clientId: PropTypes.string.isRequired
}

export default ConnectorDashboard
