import React, { useContext, useEffect, useState } from 'react'
import { Table, Tag } from 'antd'
import _ from 'lodash'
import { PencilAltIcon } from '@heroicons/react/outline'
import { AuthContext } from '../Firebase/firebaseContext'
import { FirestoreContext } from '../Firebase/firestoreContext'
import ClientStoreWoocommerce from './clientStoreWoocommerce.js'
import ClientStoreShopify from './clientStoreShopify'
import ClientStoreMagento from './clientStoreMagento'
import ClientStoreBigCommerce from './clientStoreBigCommerce'
import ClientStoreEbay from './clientStoreEbay'
import ClientStoreAmazon from './clientStoreAmazon'
import ClientStoreNeto from './clientStoreNeto'
import XeroLinkingPage from './xeroLinkingPage'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { GOOGLE_API_GATEWAY_2 } from '../../constants/constants'

const columns = [
  {
    title: 'Store name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform'
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
    render: function renderFunction (enabled) {
      const color = enabled ? 'green' : 'volcano'
      return (
        <span>
          <Tag color={color} key={enabled}>
            {enabled ? 'Enabled' : 'Disabled'}
          </Tag>
        </span>
      )
    }
  }
]

const ClientStoresTab = props => {
  const { user } = useContext(AuthContext)
  const { tenant, client, clear, setDetailsChanged } = props
  const { userAccess } = useContext(FirestoreContext)
  const [tenantReadAccessAllowed, setTenantReadAccessAllowed] = useState(false)
  const [clientReadAccessAllowed, setClientReadAccessAllowed] = useState(false)
  const [clientWriteAccessAllowed, setClientWriteAccessAllowed] = useState(false)
  const [isAdministrator, setAdministrator] = useState(false)
  const [stores, setStores] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRow, setSelectedRow] = useState({ fulfillment: { ftp: {} } })
  const [currentMenuItemKey, setCurrentMenuItemKey] = useState()
  const [loading, setLoading] = useState(true)
  const [scratchStore, setScratchStore] = useState({})

  const evaluateUserAccessToClient = () => {
    if (userAccess) {
      setAdministrator(userAccess.administrator)
      if (tenant && tenant.id) {
        if (userAccess.administrator || _.includes(userAccess.tenants.write, tenant.id)) {
        // If user has WRITE access then the user automatically has READ access.
          setTenantReadAccessAllowed(true)
        } else {
          setTenantReadAccessAllowed(userAccess.administrator || _.includes(userAccess.tenants.read, tenant.id))
        }
      } else if (userAccess.administrator) {
        setTenantReadAccessAllowed(true)
      } else {
        setTenantReadAccessAllowed(false)
      }

      if (client && client.id) {
        if (userAccess.administrator || _.includes(userAccess.clients.write, client.id)) {
        // If user has WRITE access then the user automatically has READ access.
          setClientWriteAccessAllowed(true)
          setClientReadAccessAllowed(true)
        } else {
          setClientReadAccessAllowed(userAccess.administrator || _.includes(userAccess.clients.read, client.id))
        }
      } else if (userAccess.administrator) {
        setClientWriteAccessAllowed(true)
        setClientReadAccessAllowed(true)
      } else {
        setClientWriteAccessAllowed(false)
        setClientReadAccessAllowed(false)
      }
    } else {
      setTenantReadAccessAllowed(false)
      setClientWriteAccessAllowed(false)
      setClientReadAccessAllowed(false)
    }
  }

  useEffect(() => {
    // Evaluate the user's access every time the userAccess property changed.
    evaluateUserAccessToClient()
  }, [userAccess, client])

  useEffect(() => {
    const doAsyncStuff = async () => {
      if (isAdministrator || (tenantReadAccessAllowed && clientReadAccessAllowed)) {
        // Retrieve Client's stores
        const idToken = await user.getIdToken()
        const data = await window
          .fetch(
            `${GOOGLE_API_GATEWAY_2}/query-firestore/?operation=retrieve-client-stores&tenantId=${tenant.id}&clientId=${client.id}`,
            {
              headers: { Authorization: 'Bearer ' + idToken }
            }
          )
          .then(res => res.json())
          .then(json => {
            if (json.success) {
              return json.data
            } else {
              return null
            }
          }).catch(() => {
            return null
          })

        if (data) {
          const docs = []
          data.forEach(item => {
            docs.push({
              key: item.store.id,
              ...item.store
            })
          })

          setStores(docs)
          if (docs.length > 0) {
            setSelectedRowKeys([docs[0].key])
            setSelectedRow(docs[0])
          }
        }
      }
    }
    doAsyncStuff()
  }, [isAdministrator, tenantReadAccessAllowed, clientReadAccessAllowed, client])

  useEffect(() => {
    if (clear) {
      setCurrentMenuItemKey()
    }
  }, [clear])

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRow(selectedRows[0])
  }

  const rowSelection = {
    type: 'radio',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys1, selectedRows) => {
      onSelectChange(selectedRowKeys1, selectedRows)
    }
  }

  const handleMenuItemClick = e => {
    setCurrentMenuItemKey(e)
  }

  const handleSave = async () => {
    const idToken = await user.getIdToken()
    return await window
      .fetch(
        `${GOOGLE_API_GATEWAY_2}/store-change-event`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          },
          body: JSON.stringify({
            event: 'STORE_CHANGE_EVENT',
            payload: {
              tenantId: tenant.id,
              clientId: client.id,
              store: scratchStore,
              uid: user.uid,
              timestamp: DateTime.now().toISO()
            }
          })
        }
      )
      .then(res => res.json())
      .then(json => {
        if (json.success) {
          setCurrentMenuItemKey()
          setDetailsChanged(false)
        }
      })
  }

  const handleCancel = () => {
    setCurrentMenuItemKey()
    setDetailsChanged(false)
  }

  return (
    <>
      {clientReadAccessAllowed
        ? (
          <>
            <div className='flex-1 max-h-full xl:overflow-y-auto bg-gray-100'>
              <div className='max-w-full mx-auto py-10 px-4 sm:my-6 sm:px-6 sm:mx-6 lg:py-6 lg:px-10 bg-gray-100 rounded-md'>
                <h1 className='text-3xl font-extrabold text-blue-gray-900 flex'>
                  <span>Store Details</span>
                </h1>

                {selectedRow.platform
                  ? (
                    <div className='mt-4'>
                      {currentMenuItemKey === 'edit'
                        ? (
                          <button className='bg-gray-200 opacity-50 pointer-events-none focus:outline-none text-gray-700 text-sm flex mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md mb-4'>
                            Edit
                            <PencilAltIcon
                              className='h-6 w-6 ml-4'
                              aria-hidden='true'
                              viewBox='0 3 30 18'
                            />
                          </button>
                          )
                        : (
                          <button
                            onClick={() => handleMenuItemClick('edit')}
                            className='bg-white hover:bg-gray-100 focus:outline-none text-gray-700 text-sm flex mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md mb-4'
                          >
                            Edit
                            <PencilAltIcon
                              className='h-6 w-6 ml-4'
                              aria-hidden='true'
                              viewBox='0 3 30 18'
                            />
                          </button>
                          )}
                    </div>
                    )
                  : <span />}

                {selectedRow.platform === 'Shopify'
                  ? (
                    <>
                      <div className='grid grid-cols-3 gap-6 '>
                        <div className='col-span-3 sm:col-span-2'>
                          <ClientStoreShopify
                            tenant={tenant}
                            client={client}
                            store={selectedRow}
                            clear={clear}
                            editModeEnabled={currentMenuItemKey === 'edit'}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            setDetailsChanged={setDetailsChanged}
                            scratchStore={scratchStore}
                            setScratchStore={setScratchStore}
                          />
                        </div>
                        <div className='col-span-3 sm:col-span-1'>
                          <div className='max-w-5xl '>
                            <Table
                              dataSource={stores}
                              columns={columns}
                              pagination={{ pageSize: 5, hideOnSinglePage: true }}
                              rowSelection={rowSelection}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                    )
                  : selectedRow.platform === 'WooCommerce'
                    ? (
                      <>
                        <div className='grid grid-cols-3 gap-6'>
                          <div className='col-span-3 sm:col-span-2'>
                            <ClientStoreWoocommerce
                              tenant={tenant}
                              client={client}
                              store={selectedRow}
                              editModeEnabled={currentMenuItemKey === 'edit'}
                              onSave={handleSave}
                              onCancel={handleCancel}
                              clear={clear}
                              setDetailsChanged={setDetailsChanged}
                              scratchStore={scratchStore}
                              setScratchStore={setScratchStore}
                            />
                          </div>
                          <div className='col-span-3 sm:col-span-1'>
                            <div className='min-w-full '>
                              <Table
                                dataSource={stores}
                                columns={columns}
                                pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                rowSelection={rowSelection}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                      )
                    : selectedRow.platform === 'Magento'
                      ? (
                        <div className='grid grid-cols-3 gap-6'>
                          <div className='col-span-3 sm:col-span-2'>
                            <ClientStoreMagento
                              tenant={tenant}
                              client={client}
                              store={selectedRow}
                              editModeEnabled={currentMenuItemKey === 'edit'}
                              onSave={handleSave}
                              onCancel={handleCancel}
                              clear={clear}
                              setDetailsChanged={setDetailsChanged}
                              scratchStore={scratchStore}
                              setScratchStore={setScratchStore}
                            />
                          </div>
                          <div className='col-span-3 sm:col-span-1'>
                            <div className='min-w-full '>
                              <Table
                                dataSource={stores}
                                columns={columns}
                                pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                rowSelection={rowSelection}
                              />
                            </div>
                          </div>
                        </div>
                        )
                      : selectedRow.platform === 'BigCommerce'
                        ? (
                          <div className='grid grid-cols-3 gap-6'>
                            <div className='col-span-3 sm:col-span-2'>
                              <ClientStoreBigCommerce
                                tenant={tenant}
                                client={client}
                                store={selectedRow}
                                editModeEnabled={currentMenuItemKey === 'edit'}
                                onSave={handleSave}
                                onCancel={handleCancel}
                                clear={clear}
                                setDetailsChanged={setDetailsChanged}
                                scratchStore={scratchStore}
                                setScratchStore={setScratchStore}
                              />
                            </div>
                            <div className='col-span-3 sm:col-span-1'>
                              <div className='min-w-full '>
                                <Table
                                  dataSource={stores}
                                  columns={columns}
                                  pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                  rowSelection={rowSelection}
                                />
                              </div>
                            </div>
                          </div>
                          )
                        : selectedRow.platform === 'eBay'
                          ? (
                            <div className='grid grid-cols-3 gap-6'>
                              <div className='col-span-3 sm:col-span-2'>
                                <ClientStoreEbay
                                  tenant={tenant}
                                  client={client}
                                  store={selectedRow}
                                  editModeEnabled={currentMenuItemKey === 'edit'}
                                  onSave={handleSave}
                                  onCancel={handleCancel}
                                  clear={clear}
                                  setDetailsChanged={setDetailsChanged}
                                  scratchStore={scratchStore}
                                  setScratchStore={setScratchStore}
                                />
                              </div>
                              <div className='col-span-3 sm:col-span-1'>
                                <div className='min-w-full '>
                                  <Table
                                    dataSource={stores}
                                    columns={columns}
                                    pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                    rowSelection={rowSelection}
                                  />
                                </div>
                              </div>
                            </div>
                            )
                          : selectedRow.platform === 'Neto'
                            ? (
                              <div className='grid grid-cols-3 gap-6'>
                                <div className='col-span-3 sm:col-span-2'>
                                  <ClientStoreNeto
                                    tenant={tenant}
                                    client={client}
                                    store={selectedRow}
                                    editModeEnabled={currentMenuItemKey === 'edit'}
                                    onSave={handleSave}
                                    onCancel={handleCancel}
                                    clear={clear}
                                    setDetailsChanged={setDetailsChanged}
                                    scratchStore={scratchStore}
                                    setScratchStore={setScratchStore}
                                  />
                                </div>
                                <div className='col-span-3 sm:col-span-1'>
                                  <div className='min-w-full '>
                                    <Table
                                      dataSource={stores}
                                      columns={columns}
                                      pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                      rowSelection={rowSelection}
                                    />
                                  </div>
                                </div>
                              </div>
                              )
                            : selectedRow.platform === 'Xero'
                              ? (
                                <>
                                  <div className='grid grid-cols-3 gap-6 '>
                                    <div className='col-span-3 sm:col-span-2'>
                                      <XeroLinkingPage
                                        tenant={tenant}
                                        client={client}
                                        store={selectedRow}
                                        editModeEnabled={currentMenuItemKey === 'edit'}
                                        onSave={handleSave}
                                        clear={clear}
                                        setDetailsChanged={setDetailsChanged}
                                        scratchStore={scratchStore}
                                        setScratchStore={setScratchStore}
                                      />
                                    </div>
                                    <div className='col-span-3 sm:col-span-1'>
                                      <div className='min-w-full '>
                                        <Table
                                          dataSource={stores}
                                          columns={columns}
                                          pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                          rowSelection={rowSelection}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                                )
                              : selectedRow.platform === 'Amazon'
                                ? (
                                  <>
                                    <div className='grid grid-cols-3 gap-6 '>
                                      <div className='col-span-3 sm:col-span-2'>
                                        <ClientStoreAmazon
                                          tenant={tenant}
                                          client={client}
                                          store={selectedRow}
                                          editModeEnabled={currentMenuItemKey === 'edit'}
                                          onSave={handleSave}
                                          clear={clear}
                                          setDetailsChanged={setDetailsChanged}
                                          scratchStore={scratchStore}
                                          setScratchStore={setScratchStore}
                                        />
                                      </div>
                                      <div className='col-span-3 sm:col-span-1'>
                                        <div className='min-w-full '>
                                          <Table
                                            dataSource={stores}
                                            columns={columns}
                                            pagination={{ pageSize: 5, hideOnSinglePage: true }}
                                            rowSelection={rowSelection}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  )
                                : (
                                    'This client does not have any store yet. Use \'Add Connector\' to connect to a store.'
                                  )}
              </div>
            </div>
          </>
          )
        : 'Access Denied'}
    </>
  )
}

ClientStoresTab.propTypes = {
  tenant: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  clear: PropTypes.bool.isRequired,
  setDetailsChanged: PropTypes.func.isRequired
}

export default ClientStoresTab
