import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { doSignOut } from '../Firebase/firebaseConfig'
import { LOGIN } from '../../constants/routes'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { doc, deleteDoc } from 'firebase/firestore'
import { AuthContext } from '../Firebase/firebaseContext'

const Logout = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { firestore } = useContext(FirestoreContext)

  // Don't write useEffect(async () => ...) otherwise the component will crash.
  // If you need to await inside useEffect() do it in the following way.
  useEffect(() => {
    const signout = () => {
      const sessionId = localStorage.getItem('sessionId')
      localStorage.removeItem('sessionId')
      if (sessionId) {
        console.log(`Deleting users/${user.uid}/user_sessions/${sessionId} ...`)
        deleteDoc(doc(firestore, `users/${user.uid}/user_sessions/${sessionId}`)).then(() => {
          console.log(`Successfully delete session in firestore`)

        }).catch(reason => {
          console.error(reason)
        }).finally(() => {
          doSignOut().then(success => {
            console.log(`successfully logged out. Redirect to ${LOGIN}`)
            navigate(LOGIN)
          })
        })
      }
    }

    signout()
  }, [])
  return <></>
}

export default Logout
