import { useEffect, useContext, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Tag, Table } from 'antd'
import _ from 'lodash'
import { FirestoreContext } from '../Firebase/firestoreContext'
import PropTypes from 'prop-types'

const ShopifyOrderDetails = props => {
  const { onDrawerClose, orderDetailsDrawerVisible, selectedOrder } = props
  const { userAccess } = useContext(FirestoreContext)
  const [, setReadAccessAllowed] = useState(false)
  const [, setWriteAccessAllowed] = useState(false)
  const [, setAdministrator] = useState(false)
  const [lineItems, setLineItems] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: function renderText (text) {
        return <p style={{ margin: 0 }}>${text}</p>
      }
    }
  ]

  const evaluateUserAccess = () => {
    if (userAccess && selectedOrder) {
      if (
        userAccess.administrator ||
        (_.includes(userAccess.tenants.write, selectedOrder.tenantId) &&
          _.includes(userAccess.clients.write, selectedOrder.clientId) &&
          _.includes(userAccess.stores.write, selectedOrder.storeId))
      ) {
        // If user has WRITE access then the user automatically has READ access.
        setWriteAccessAllowed(true)
        setReadAccessAllowed(true)
      } else {
        setReadAccessAllowed(
          userAccess.administrator ||
            (_.includes(userAccess.tenants.read, selectedOrder.tenantId) &&
              _.includes(userAccess.clients.read, selectedOrder.clientId) &&
              _.includes(userAccess.stores.read, selectedOrder.storeId))
        )
      }
      setAdministrator(userAccess.administrator)
    }
  }

  useEffect(() => {
    // Evaluate the user's access every time the userAccess property changed.
    evaluateUserAccess()
  }, [userAccess])

  useEffect(() => {
    evaluateUserAccess()
    if (selectedOrder && selectedOrder.data) {
      const newLineItems = []
      Promise.all(
        selectedOrder.data.line_items.map(item => {
          newLineItems.push({
            key: item.name,
            name: item.name,
            quantity: item.quantity,
            price: item.price * item.quantity
          })
        })
      ).then(() => {
        setLineItems(newLineItems)
      })
    }
  }, [selectedOrder])
  return (
    <Transition.Root show={orderDetailsDrawerVisible} as={Fragment}>
      <Dialog
        as='div'
        static
        className='fixed inset-0 overflow-hidden z-50'
        onClose={onDrawerClose}
      >
        <div className='absolute inset-0 overflow-hidden'>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-y-0 right-0 pl-10 flex'>
            <Transition.Child
              as={Fragment}
              enter='transform transition ease-in-out duration-500 sm:duration-700'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transform transition ease-in-out duration-500 sm:duration-700'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
            >
              <div className='w-screen max-w-screen sm:max-w-7xl'>
                {selectedOrder && (
                  <div className='h-full flex flex-col pt-6  bg-white shadow-xl overflow-y-scroll'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                          Details for Order {selectedOrder.data.name}
                        </Dialog.Title>
                        <div className='ml-3 h-7 flex items-center'>
                          <button
                            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => onDrawerClose()}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='mt-6 relative flex-1 px-4 sm:px-12 bg-gray-200'>
                      <table className='w-full mt-6'>
                        <tbody>
                          <div className='grid grid-cols-2 gap-4'>
                            <div className='col-span-2 sm:col-span-1'>
                              <div>
                                <div className='shadow sm:rounded-md sm:overflow-hidden mb-6'>
                                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6 '>
                                    <h2 className='font-bold text-lg mb-6'>
                                      Order Details
                                    </h2>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Ordered At:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            placeholder='www.example.com'
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='block text-sm font-medium text-gray-700'
                                        >
                                          Fulfillment Status:
                                        </label>
                                        <div className='mt-2 flex'>
                                          {selectedOrder.data
                                            .fulfillment_status ===
                                          'fulfilled'
                                            ? (
                                              <Tag color='green'>Fulfilled</Tag>
                                              )
                                            : (
                                              <Tag color='orange'>Pending</Tag>
                                              )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Fulfilled At:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            className='pointer-events-none  shadow-sm block w-full sm:text-sm border-gray-300 rounded-md'
                                            readOnly
                                            placeholder='N/A'
                                            value={
                                              selectedOrder.data.closed_at &&
                                              selectedOrder.data.closed_at.toDate()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='block text-sm font-medium text-gray-700'
                                        >
                                          Tracking URL(s):
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          {selectedOrder.odeo &&
                                            selectedOrder.odeo.fulfillment &&
                                            selectedOrder.odeo.fulfillment
                                              .trackingUrls &&
                                            selectedOrder.odeo.fulfillment
                                              .trackingUrls.length > 0 &&
                                            selectedOrder.odeo.fulfillment.trackingUrls.map(
                                              trackingUrl => {
                                                return (
                                                  <td
                                                    key={trackingUrl}
                                                    style={{
                                                      paddingTop: 10,
                                                      paddingLeft: 5,
                                                      width: 400
                                                    }}
                                                  >
                                                    <input
                                                      type='text'
                                                      className='pointer-events-none  shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                                                      readOnly
                                                      value={trackingUrl}
                                                    />
                                                  </td>
                                                )
                                              }
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className='shadow sm:rounded-md sm:overflow-hidden mb-6'>
                                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                                    <h2 className='font-bold text-lg mb-6'>
                                      Buyer Details
                                    </h2>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Buyer Name:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            value={
                                              selectedOrder.data.billing_address
                                                .name
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Phone:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            className='pointer-events-none  shadow-sm block w-full sm:text-sm border-gray-300 rounded-md'
                                            readOnly
                                            value={
                                              selectedOrder.data.billing_address
                                                .phone || 'N/A'
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Email:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            className='pointer-events-none  shadow-sm block w-full sm:text-sm border-gray-300 rounded-md'
                                            readOnly
                                            placeholder='N/A'
                                            value={
                                              selectedOrder.data
                                                .contact_email || 'N/A'
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className='shadow sm:rounded-md sm:overflow-hidden mb-6'>
                                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                                    <h2 className='font-bold text-lg mb-6'>
                                      Address
                                    </h2>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Street Address 1:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            value={
                                              selectedOrder.data.billing_address
                                                .address1
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Street Address 2:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            value={
                                              selectedOrder.data.billing_address
                                                .address2
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          City:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            className='pointer-events-none  shadow-sm block w-full sm:text-sm border-gray-300 rounded-md'
                                            readOnly
                                            value={
                                              selectedOrder.data.billing_address
                                                .city
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          State:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            className='pointer-events-none  shadow-sm block w-full sm:text-sm border-gray-300 rounded-md'
                                            readOnly
                                            placeholder='N/A'
                                            value={
                                              selectedOrder.data.billing_address
                                                .province_code
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Postcode:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            value={
                                              selectedOrder.data.billing_address
                                                .zip
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='grid grid-cols-3 gap-6'>
                                      <div className='col-span-3 sm:col-span-3'>
                                        <label
                                          htmlFor='company-website'
                                          className='pointer-events-none  block text-sm font-medium text-gray-700'
                                        >
                                          Country:
                                        </label>
                                        <div className='mt-1 flex rounded-md shadow-sm'>
                                          <input
                                            type='text'
                                            name='company-website'
                                            id='company-website'
                                            className='pointer-events-none flex-1 block w-full  rounded-md sm:text-sm border-gray-300'
                                            value={
                                              selectedOrder.data.billing_address
                                                .country
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-span-2 sm:col-span-1'>
                              <div>
                                <div className='shadow sm:rounded-md sm:overflow-hidden mb-6'>
                                  <div className='px-4 py-5 bg-white space-y-6 sm:p-6'>
                                    <h2 className='font-bold text-lg mb-6'>
                                      Items Ordered
                                    </h2>
                                    <Table
                                      style={{ marginTop: 10 }}
                                      dataSource={lineItems}
                                      columns={columns}
                                      pagination={{
                                        pageSize: 10,
                                        hideOnSinglePage: true
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ShopifyOrderDetails.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  orderDetailsDrawerVisible: PropTypes.bool.isRequired,
  selectedOrder: PropTypes.object
}

export default ShopifyOrderDetails
