import { useContext, useState } from 'react'
import { FirestoreContext } from '../Firebase/firestoreContext'
import ShopifyDetailsForm from './shopifyDetailsForm'
import EbayDetailsForm from './eBayDetailsForm'
import MagentoDetailsForm from './magentoDetailsForm'
import WoocommerceDetailsForm from './woocommerceDetailsForm'
import AmazonDetailsForm from './amazonDetailsFrom'
import XeroOAuthForm from './xeroOAuthForm'
import PropTypes from 'prop-types'
import BigCommerceDetailsForm from './bigCommerceDetailsForm'
import NetoDetailsForm from './netoDetailsForm'
import { AuthContext } from '../Firebase/firebaseContext'
import { GOOGLE_API_GATEWAY_2 } from '../../constants/constants'

const EnterDetailsForm = props => {
  const {
    updateCurrentStep,
    platform,
    handleTabChange,
    tenantId,
    clientId
  } = props
  const { userDoc } = useContext(FirestoreContext)
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const { user } = useContext(AuthContext)
  const [errorMessage, setErrorMessage] = useState('')

  const onFinish = async values => {
    setErrorMessage('')
    let payload
    if (platform === 'Shopify') {
      payload = {
        tenantId,
        clientId,
        store: {
          platform: platform,
          storeUrl: values.storeURL,
          apiKey: values.apiKey,
          apiPassword: values.apiSecretKey,
          accessToken: values.accessToken,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
          fulfillment: {
            excludeOrderFiltersV2: values.filters
          }
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    } else if (platform === 'WooCommerce') {
      payload = {
        tenantId,
        clientId,
        store: {
          storeName: values.storeName,
          platform: platform,
          storeUrl: values.storeURL,
          consumerKey: values.consumerKey,
          consumerSecretKey: values.consumerSecretKey,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    } else if (platform === 'Magento') {
      payload = {
        tenantId,
        clientId,
        store: {
          storeName: values.storeName,
          platform: platform,
          storeURL: values.storeURL,
          consumerKey: values.consumerKey,
          consumerSecretKey: values.consumerSecretKey,
          accessToken: values.accessToken,
          accessTokenSecret: values.accessTokenSecret,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    } else if (platform === 'BigCommerce') {
      payload = {
        tenantId,
        clientId,
        store: {
          platform: platform,
          apiPath: values.apiPath,
          accessToken: values.accessToken,
          clientSecret: values.consumerSecret,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
          storeName: values.storeName
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    } else if (platform === 'Neto') {
      payload = {
        tenantId,
        clientId,
        store: {
          platform: platform,
          apiKey: values.apiKey,
          apiUser: values.apiUser,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
          storeUrl: values.storeURL,
          storeName: values.storeName
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    } else if (platform === 'Amazon') {
      payload = {
        tenantId,
        clientId,
        store: {
          platform: platform,
          sellerId: values.sellerId,
          marketplaceId: values.marketplaceId,
          mwsAuthToken: values.mwsAuthToken,
          storeName: values.storeName,
          sftpUsername: values.sftpUsername,
          sftpPassword: values.sftpPassword,
          next3plAssignedOrderPrefix: values.next3plAssignedOrderPrefix,
          orderFilePrefix: values.orderFilePrefix,
        },
        createdBy: userDoc.id,
        createdAt: new Date().toISOString()
      }
    }

    setWaitingForResponse(true)
    const idToken = await user.getIdToken()
    window
      .fetch(`${GOOGLE_API_GATEWAY_2}/new-octoplux-store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken
        },
        body: JSON.stringify(payload)
      })
      .then(res => res.json())
      .then(data => {
        setWaitingForResponse(false)
        if (data.success) {
          handleTabChange({ name: 'Stores' })
        } else {
          setErrorMessage(data.message)
        }
      })
  }

  return (
    <>
      {platform === 'Shopify' ? (
        <ShopifyDetailsForm
          platform={platform}
          updateCurrentStep={updateCurrentStep}
          onFinish={onFinish}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'eBay' ? (
        <EbayDetailsForm
          platform={platform}
          tenantId={tenantId}
          clientId={clientId}
          updateCurrentStep={updateCurrentStep}
          onFinish={onFinish}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'Magento' ? (
        <MagentoDetailsForm
          platform={platform}
          updateCurrentStep={updateCurrentStep}
          onFinish={onFinish}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'WooCommerce' ? (
        <WoocommerceDetailsForm
          platform={platform}
          updateCurrentStep={updateCurrentStep}
          onFinish={onFinish}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'Xero' ? (
        <XeroOAuthForm
          platform={platform}
          updateCurrentStep={updateCurrentStep}
          onFinish={onFinish}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'Amazon' ? (
        <AmazonDetailsForm
          platform={platform}
          onFinish={onFinish}
          updateCurrentStep={updateCurrentStep}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'BigCommerce' ? (
        <BigCommerceDetailsForm
          platform={platform}
          onFinish={onFinish}
          updateCurrentStep={updateCurrentStep}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : platform === 'Neto' ? (
        <NetoDetailsForm
          platform={platform}
          onFinish={onFinish}
          updateCurrentStep={updateCurrentStep}
          waitingForResponse={waitingForResponse}
          errorMessage={errorMessage}
        />
      ) : (
        <></>
      )}
    </>
  )
}

EnterDetailsForm.propTypes = {
  updateCurrentStep: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func,
  tenantId: PropTypes.string,
  clientId: PropTypes.string
}

export default EnterDetailsForm
