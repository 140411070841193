import { useState } from 'react'
import PropTypes from 'prop-types'

const ShopifyDetailsForm = props => {
  const {
    updateCurrentStep,
    platform,
    onFinish,
    waitingForResponse,
    errorMessage
  } = props
  const [inventorySync] = useState(false)
  const [storeURL, setStoreURL] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [apiSecretKey, setApiSecretKey] = useState('')
  const [sftpUsername, setSftpUsername] = useState('')
  const [sftpPassword, setSftpPassword] = useState('')
  const [next3plAssignedOrderPrefix, setNext3plAssignedOrderPrefix] = useState('')
  const [orderFilePrefix, setOrderFilePrefix] = useState('')
  const [filters] = useState([])
  const handleBack = () => {
    updateCurrentStep(1)
  }

  const handleStoreURLChange = e => {
    setStoreURL(e.target.value)
  }

  const handleApiKeyChange = e => {
    setApiKey(e.target.value)
  }

  const handleApiSecretKeyChange = e => {
    setApiSecretKey(e.target.value)
  }

  const handleAccessTokenChange = e => {
    setAccessToken(e.target.value)
  }

  const handleSftpUsernameChange = e => {
    setSftpUsername(e.target.value)
  }

  const handleSftpPasswordChange = e => {
    setSftpPassword(e.target.value)
  }

  const handleNext3plAssignedOrderPrefixChange = e => {
    setNext3plAssignedOrderPrefix(e.target.value)
  }

  const handleOrderFilePrefixChange = e => {
    setOrderFilePrefix(e.target.value)
  }

  return (
    <>
      <div className='max-w-2xl container mx-auto px-4'>
        <form
          className='space-y-8 divide-y divide-gray-200'
          onSubmit={e => {
            e.preventDefault()
            onFinish({
              storeURL,
              apiKey,
              accessToken,
              apiSecretKey,
              sftpUsername,
              sftpPassword,
              next3plAssignedOrderPrefix,
              orderFilePrefix,
              inventorySync,
              filters
            })
          }}
        >
          <div className='space-y-8 divide-y divide-gray-200'>
            <div className='pt-8'>
              <div>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {platform} Information
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  This information will be used to connect your platform to the
                  system
                </p>
              </div>
              <div
                className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="store-url"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Store URL
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="store-url"
                      id="store-url"
                      onChange={e => handleStoreURLChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label
                    htmlFor="api-key"
                    className="block text-sm font-medium text-gray-700"
                  >
                    API Key
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="api-key"
                      id="api-key"
                      onChange={e => handleApiKeyChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="api-secret-key"
                    className="block text-sm font-medium text-gray-700"
                  >
                    API Secret Key
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      type="text"
                      name="api-secret-key"
                      id="api-secret-key"
                      onChange={e => handleApiSecretKeyChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="access-token"
                    className="block text-sm font-medium text-gray-700"
                  >
                    API Access Token
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="access-token"
                      name="access-token"
                      type="text"
                      onChange={e => handleAccessTokenChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="sftp-username"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SFTP User Name
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="sftp-username"
                      name="sftp-username"
                      type="text"
                      onChange={e => handleSftpUsernameChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="sftp-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    SFTP Password
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="sftp-password"
                      name="sftp-password"
                      type="text"
                      onChange={e => handleSftpPasswordChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="next3pl-assigned-order-prefix"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Next3PL Assigned Order Prefix
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="next3pl-assigned-order-prefix"
                      name="next3pl-assigned-order-prefix"
                      type="text"
                      onChange={e => handleNext3plAssignedOrderPrefixChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="order-file-prefix"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Order File Prefix
                  </label>
                  <div className="mt-1">
                    <input
                      required
                      id="order-file-prefix"
                      name="order-file-prefix"
                      type="text"
                      onChange={e => handleOrderFilePrefixChange(e)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => handleBack()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
              <button
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                Save
                {waitingForResponse && (
                  <svg
                    className='animate-spin ml-3 -mr-1 h-5 w-5 text-white'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    />
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    />
                  </svg>
                )}
              </button>
            </div>
            {errorMessage.length > 0 && (
              <div className='flex justify-center text-base text-red-500'>
                {errorMessage}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  )
}

ShopifyDetailsForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  waitingForResponse: PropTypes.bool.isRequired,
  updateCurrentStep: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired
}

export default ShopifyDetailsForm
