import { ADD_SETTING, UPDATE_SETTING, REMOVE_SETTING } from './actionTypes'

export const appSettingReducer = (state, action) => {
  switch (action.type) {
    case ADD_SETTING:
      return { ...state, ...action.payload }
    case UPDATE_SETTING:
      return { ...state, ...action.payload }
    case REMOVE_SETTING:
      delete state[action.payload.key]
      return state
    default:
      return state
  }
}
