import React, { useContext, useEffect, useState, Fragment } from 'react'
import _ from 'lodash'
import { Dialog, Transition } from '@headlessui/react'
import {
  PencilAltIcon,
  UserAddIcon,
  CheckIcon,
  XIcon
} from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { AuthContext } from '../Firebase/firebaseContext'
import { FirestoreContext } from '../Firebase/firestoreContext'
import {
  GOOGLE_CLOUD_PROJECT_ID,
  GOOGLE_API_GATEWAY_2
} from '../../constants/constants'
import { Switch } from '@headlessui/react'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const ClientProfileTab = props => {
  const { user } = useContext(AuthContext)
  const { userAccess } = useContext(FirestoreContext)
  const { tenant, parentClient, setDetailsChanged } = props
  const [clientReadAccessAllowed, setClientReadAccessAllowed] = useState(false)
  const [clientWriteAccessAllowed, setClientWriteAccessAllowed] = useState(
    false
  )
  const [isAdministrator, setAdministrator] = useState(false)
  const [editModeEnabled, setEditModeEnabled] = useState(false)
  const [unchangedClient, setUnchangedClient] = useState({})
  const [client, setClient] = useState({ clientDetails: {} })
  const [modalOpen, setModalOpen] = useState(false)
  const [setInviteCode] = useState('')
  const [copied, setCopied] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [emailSending, setEmailSending] = useState(false)
  const [inviteEmail, setInviteEmail] = useState('')
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [enabled, setEnabled] = useState(false)

  const evaluateUserAccessToClient = () => {
    if (userAccess) {
      setAdministrator(userAccess.administrator)
      if (parentClient && parentClient.id) {
        if (
          userAccess.administrator ||
          _.includes(userAccess.clients.write, parentClient.id)
        ) {
          // If user has WRITE access then the user automatically has READ access.
          setClientWriteAccessAllowed(true)
          setClientReadAccessAllowed(true)
        } else {
          setClientReadAccessAllowed(
            userAccess.administrator ||
              _.includes(userAccess.clients.read, parentClient.id)
          )
        }
      } else if (userAccess.administrator) {
        setClientReadAccessAllowed(true)
        setClientReadAccessAllowed(true)
      } else {
        setClientReadAccessAllowed(false)
        setClientReadAccessAllowed(false)
      }
    } else {
      setClientReadAccessAllowed(false)
      setClientReadAccessAllowed(false)
    }
  }

  useEffect(() => {
    // Evaluate the user's access to the selected client every time the userAccess or selectedClient property changed.
    evaluateUserAccessToClient()
  }, [userAccess])

  useEffect(() => {
    // Evaluate the user's access every time the userAccess property changed.
    evaluateUserAccessToClient()
    // Set Client Data
    const data1 = _.cloneDeep(parentClient)
    if (!parentClient.clientDetails) {
      setClient({ ...data1, clientDetails: {} })
      // Create a copy of client data if user wants to revert editted changes
      const data2 = _.cloneDeep(parentClient)
      setUnchangedClient({ ...data2, clientDetails: {} })
    } else {
      setClient({ ...data1 })
      // Create a copy of client data if user wants to revert editted changes
      const data2 = _.cloneDeep(parentClient)
      setUnchangedClient({ ...data2 })
    }
  }, [parentClient])

  const handleCancel = () => {
    const oldClient = _.cloneDeep(unchangedClient)
    setClient(oldClient)
    setEditModeEnabled(false)
    setDetailsChanged(false)
  }

  const handleClientNameChange = e => {
    const newClient = { ...client }
    newClient.name = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleDescriptionChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.description = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleUrlChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.url = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleFirstNameChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.firstName = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleLastNameChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.lastName = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleEmailChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.emailAddress = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handlePhoneChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.phone = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleCountryChange = e => {
    const newClient = { ...client }
    newClient.clientDetails.country = e.target.value
    setClient(newClient)
    setDetailsChanged(true)
  }

  const handleSave = async () => {
    const idToken = await user.getIdToken()
    setWaitingForResponse(true)
    window
      .fetch(`${GOOGLE_API_GATEWAY_2}/client-change-event`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken
        },
        body: JSON.stringify({
          event: 'CLIENT_CHANGE_EVENT',
          payload: {
            tenantId: tenant.id,
            client: client,
            uid: user.uid,
            timestamp: DateTime.now().toISO()
          }
        })
      })
      .then(res => res.json())
      .then(json => {
        setWaitingForResponse(false)
        if (json.success) {
          setEditModeEnabled(false)
          setDetailsChanged(false)
          // Reset unchanged client to new saved client
          const data2 = _.cloneDeep(client)
          setUnchangedClient({ ...data2 })
        }
      })
  }

  const handleSendInviteEmail = async () => {
    setEmailSending(true)
    const idToken = await user.getIdToken()
    window
      .fetch(
        `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/invite-user/tenant/${tenant.id}/client/${client.id}/`,
        {
          method: 'POST',
          headers: { Authorization: 'Bearer ' + idToken },
          body: JSON.stringify({
            tenantId: tenant.id,
            clientId: client.id,
            inviteEmail,
            userId: user.uid,
            timestamp: DateTime.now().toISO()
          })
        }
      )
      .then(res => res.json())
      .then(json => {
        setEmailSending(false)
        if (json.success) {
          setEmailSent(true)
        }
      })
  }

  const generateUuidv4 = async () => {
    const uuid = uuidv4()
    setInviteCode(uuid)
  }

  return (
    <>
      {clientReadAccessAllowed && client.clientDetails ? (
        <div className=' max-h-full xl:overflow-y-auto bg-gray-200 container mx-auto'>
          <div className='max-w-full  py-6 px-4 sm:px-10 sm:mx-5 lg:my-6 lg:mx-6 bg-white rounded-md'>
            <h1 className='text-3xl font-extrabold text-blue-gray-900 flex'>
              <span>Client Details 1</span>
            </h1>
            {clientWriteAccessAllowed ? (
              <div className='mt-4'>
                {editModeEnabled ? (
                  <button className='bg-gray-200 opacity-50 pointer-events-none text-gray-700 text-sm flex mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md'>
                    Edit
                    <PencilAltIcon
                      className='h-6 w-6 ml-4'
                      aria-hidden='true'
                      viewBox='0 3 30 18'
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => setEditModeEnabled(true)}
                    className='bg-white hover:bg-gray-100 focus:outline-none text-gray-700 text-sm flex mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md'
                  >
                    Edit
                    <PencilAltIcon
                      className='h-6 w-6 ml-4'
                      aria-hidden='true'
                      viewBox='0 3 30 18'
                    />
                  </button>
                )}
                <button
                  onClick={() => {
                    generateUuidv4()
                    setModalOpen(true)
                  }}
                  className='bg-white hover:bg-gray-100 focus:outline-none text-gray-700 text-sm mx-6 mt-2 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm leading-4 font-medium rounded-md'
                >
                  Generate Invite Code
                  <UserAddIcon
                    className='h-6 w-6 ml-4'
                    aria-hidden='true'
                    viewBox='0 3 30 18'
                  />
                </button>
                <div className='mt-10'>
                  <Switch.Group
                    as='div'
                    className={classNames(
                      editModeEnabled
                        ? ''
                        : 'pointer-events-none hover:cursor-not-allowed',
                      'flex items-center'
                    )}
                  >
                    <Switch
                      checked={enabled}
                      onChange={setEnabled}
                      className={classNames(
                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
                      )}
                    >
                      <span
                        aria-hidden='true'
                        className={classNames(
                          enabled ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                        )}
                      />
                    </Switch>
                    <Switch.Label as='span' className='ml-3'>
                      <span className='text-sm font-medium text-gray-900'>
                        Enabled{' '}
                      </span>
                      <span className='text-sm text-gray-500'>
                        (Affects all stores)
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className='mt-6 space-y-8 divide-y divide-y-blue-gray-200'>
              <div className='grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6'>
                <div className='sm:col-span-6'>
                  <h2 className='text-2xl font-bold text-blue-gray-900'>
                    Company Information
                  </h2>
                </div>

                <div className='col-span-6 sm:col-start-1 sm:col-end-4'>
                  <label
                    htmlFor='business-name'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Business Name
                  </label>
                  <input
                    readOnly={!editModeEnabled}
                    type='text'
                    name='business-name'
                    id='business-name'
                    value={client.name || ''}
                    onChange={e => handleClientNameChange(e)}
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>
                <div className='col-span-6 sm:col-start-1 sm:col-end-4'>
                  <label
                    htmlFor='description'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Description
                  </label>
                  <div className='mt-1'>
                    <textarea
                      readOnly={!editModeEnabled}
                      id='description'
                      name='description'
                      rows={4}
                      value={
                        client.clientDetails.description &&
                        client.clientDetails.description
                      }
                      onChange={e => handleDescriptionChange(e)}
                      className='block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                    />
                  </div>
                  <p className='mt-3 text-sm text-blue-gray-500'>
                    Brief description for your profile. URLs are hyperlinked.
                  </p>
                </div>

                <div className='col-span-6 sm:col-start-1 sm:col-end-4'>
                  <label
                    htmlFor='url'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    URL
                  </label>
                  <input
                    readOnly={!editModeEnabled}
                    type='text'
                    name='url'
                    id='url'
                    value={client.clientDetails.url && client.clientDetails.url}
                    onChange={e => handleUrlChange(e)}
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>
              </div>

              <div className='grid grid-cols-1 pt-6 gap-y-6 sm:grid-cols-6 sm:gap-x-6'>
                <div className='sm:col-span-6'>
                  <h2 className='text-2xl font-bold text-blue-gray-900'>
                    Contact Details
                  </h2>
                </div>

                <div className='sm:col-span-3'>
                  <label
                    htmlFor='first-name'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    First name
                  </label>
                  <input
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                    type='text'
                    name='first-name'
                    id='first-name'
                    readOnly={!editModeEnabled}
                    value={client.clientDetails.firstName || ''}
                    onChange={e => handleFirstNameChange(e)}
                    autoComplete='given-name'
                  />
                </div>

                <div className='sm:col-span-3'>
                  <label
                    htmlFor='last-name'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Last name
                  </label>
                  <input
                    readOnly={!editModeEnabled}
                    type='text'
                    name='last-name'
                    id='last-name'
                    autoComplete='family-name'
                    value={client.clientDetails.lastName || ''}
                    onChange={e => handleLastNameChange(e)}
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>

                <div className='sm:col-span-6'>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Email
                  </label>
                  <input
                    readOnly={!editModeEnabled}
                    type='email'
                    name='email'
                    id='email'
                    value={client.clientDetails.emailAddress || ''}
                    onChange={e => handleEmailChange(e)}
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>

                <div className='sm:col-span-3'>
                  <label
                    htmlFor='phone'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Phone Number
                  </label>
                  <input
                    readOnly={!editModeEnabled}
                    type='text'
                    name='phone'
                    id='phone'
                    value={client.clientDetails.phone || ''}
                    onChange={e => handlePhoneChange(e)}
                    className='mt-1 block w-full border-gray-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                  />
                </div>

                <div className='sm:col-span-3'>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium text-blue-gray-900'
                  >
                    Country
                  </label>
                  <div className='mt-1 sm:mt-0 sm:col-span-3'>
                    <select
                      disabled={!editModeEnabled}
                      id='country'
                      name='country'
                      autoComplete='country'
                      value={client.clientDetails.country || 'default'}
                      onChange={e => handleCountryChange(e)}
                      className='mt-1 w-full block focus:ring-indigo-500 focus:border-indigo-500  shadow-sm sm:text-sm border-gray-300 rounded-md '
                    >
                      <option disabled value='default'>
                        Choose a country
                      </option>
                      <option value='Austrlaia'>Australia</option>
                      <option value='China'>China</option>
                      <option value='United States'>United States</option>
                    </select>
                  </div>
                </div>
              </div>

              {editModeEnabled && (
                <div className='pt-8 flex justify-end'>
                  <button
                    type='button'
                    onClick={() => handleCancel()}
                    className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => handleSave()}
                    className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none'
                  >
                    Save
                    {waitingForResponse && (
                      <svg
                        className='animate-spin ml-3 -mr-1 h-5 w-5 text-white'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        />
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        />
                      </svg>
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
          <Transition.Root show={modalOpen} as={Fragment}>
            <Dialog
              as='div'
              className='fixed z-40 inset-0 overflow-y-auto'
              onClose={() => {
                setCopied(false)
                setModalOpen(false)
              }}
            >
              <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className='hidden sm:inline-block sm:align-middle sm:h-screen'
                  aria-hidden='true'
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6'>
                    <div>
                      <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                        <CheckIcon
                          className='h-6 w-6 text-green-600'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <Dialog.Title
                          as='h3'
                          className='text-lg leading-6 font-medium text-gray-900'
                        >
                          Send Email Invite
                        </Dialog.Title>

                        <div className='my-6 '>
                          <label
                            htmlFor='code'
                            className='block text-left text-sm font-medium text-gray-700'
                          >
                            Receiver&apos;s Email
                          </label>
                          <div className='mt-1 flex rounded-md shadow-sm'>
                            <div className='relative flex items-stretch flex-grow focus-within:z-10'>
                              <input
                                type='email'
                                name='email'
                                id='email'
                                className=' block w-full rounded-none rounded-l-md sm:text-sm border-gray-300'
                                placeholder='Email'
                                value={inviteEmail || ''}
                                onChange={e => {
                                  setEmailSent(false)
                                  setInviteEmail(e.target.value)
                                }}
                              />
                            </div>
                            <button
                              type='button'
                              className={
                                emailSent
                                  ? 'pointer-events-none -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-500 text-sm font-medium rounded-r-md text-white bg-gray-500'
                                  : '-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-indigo-800 text-sm font-medium rounded-r-md text-white bg-indigo-600 hover:bg-indigo-700'
                              }
                              onClick={() => {
                                if (inviteEmail !== '') {
                                  handleSendInviteEmail()
                                }
                              }}
                            >
                              <UserAddIcon
                                className='h-5 w-5 text-white'
                                aria-hidden='true'
                              />
                              <span>Send</span>
                              {emailSending && (
                                <svg
                                  className='animate-spin ml-3 -mr-1 h-5 w-5 text-white'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                >
                                  <circle
                                    className='opacity-25'
                                    cx='12'
                                    cy='12'
                                    r='10'
                                    stroke='currentColor'
                                    strokeWidth='4'
                                  />
                                  <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-5 sm:mt-6'>
                      <button
                        type='button'
                        className='inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 sm:text-sm'
                        onClick={() => {
                          setCopied(false)
                          setModalOpen(false)
                        }}
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </Transition.Child>
              </div>
              <div
                aria-live='assertive'
                className='fixed inset-x-0 bottom-0 flex items-center px-4 py-6 pointer-events-none sm:p-6 sm:items-center'
              >
                <div className='w-full flex flex-col items-center space-y-4 sm:items-center'>
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition
                    show={copied}
                    as={Fragment}
                    afterEnter={() => {
                      setTimeout(() => {
                        setCopied(false)
                      }, 3000)
                    }}
                    enter='transform ease-out duration-300 transition'
                    enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                    enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50'>
                      <div className='p-4'>
                        <div className='flex items-center'>
                          <div className='w-0 flex-1 flex justify-between'>
                            <p className='w-0 flex-1 text-sm font-medium text-gray-900'>
                              Code copied to clipboard!
                            </p>
                          </div>
                          <div className='ml-4 flex-shrink-0 flex'>
                            <button
                              className='bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              onClick={() => {
                                setCopied(false)
                              }}
                            >
                              <span className='sr-only'>Close</span>
                              <XIcon className='h-5 w-5' aria-hidden='true' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
              <div
                aria-live='assertive'
                className='fixed inset-x-0 bottom-0 flex items-center px-4 py-6 pointer-events-none sm:p-6 sm:items-center'
              >
                <div className='w-full flex flex-col items-center space-y-4 sm:items-center'>
                  {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                  <Transition
                    show={emailSent}
                    as={Fragment}
                    enter='transform ease-out duration-300 transition'
                    enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
                    enterTo='translate-y-0 opacity-100 sm:translate-x-0'
                    leave='transition ease-in duration-100'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden z-50'>
                      <div className='p-4'>
                        <div className='flex items-center'>
                          <div className='w-0 flex-1 flex justify-between'>
                            <p className='w-0 flex-1 text-sm font-medium text-gray-900'>
                              Invite Email Sent!
                            </p>
                          </div>
                          <div className='ml-4 flex-shrink-0 flex'>
                            <button
                              className='bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              onClick={() => {
                                setEmailSent(false)
                              }}
                            >
                              <span className='sr-only'>Close</span>
                              <XIcon className='h-5 w-5' aria-hidden='true' />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      ) : (
        'Access denied'
      )}
    </>
  )
}

ClientProfileTab.propTypes = {
  tenant: PropTypes.object.isRequired,
  parentClient: PropTypes.object.isRequired,
  setDetailsChanged: PropTypes.func.isRequired
}

export default ClientProfileTab
