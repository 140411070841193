import { useEffect, useState } from 'react'
import {
  Input,
  Drawer,
  Row,
  Col,
  Table,
  Tag
} from 'antd'
import PropTypes from 'prop-types'

const ShopifyProductDetails = props => {
  const { onDrawerClose, orderDetailsDrawerVisible, selectedOrder } = props
  const [lineItems, setLineItems] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'Price ($)',
      dataIndex: 'price',
      key: 'price'
    }
  ]
  useEffect(() => {
    if (selectedOrder.data) {
      const newLineItems = []
      Promise.all(
        selectedOrder.data.line_items.map(item => (
          newLineItems.push({
            name: item.name,
            quantity: item.quantity,
            price: item.price * item.quantity
          })
        ))
      ).then(() => {
        setLineItems(newLineItems)
      })
    }
  }, [selectedOrder])
  return (
    <>
      {selectedOrder.data && (
        <Drawer
          title={'Details for Order ' + selectedOrder.data.name}
          width='70%'
          onClose={onDrawerClose}
          visible={orderDetailsDrawerVisible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <table style={{ marginTop: 10 }}>
            <tbody>
              <Row key={selectedOrder.data.name}>
                <Col sm={12}>
                  <tr>
                    <h2 style={{ color: '#1890ff', width: 150 }}>
                      Order Details
                    </h2>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Ordered At:
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.created_at.toDate()}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Fulfillment Status:
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      {selectedOrder.data.fulfillment_status === 'fulfilled'
                        ? (
                          <Tag color='green'>Fulfilled</Tag>
                          )
                        : (
                          <Tag color='orange'>Pending</Tag>
                          )}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Fulfilled At:
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={
                          selectedOrder.data.closed_at &&
                          selectedOrder.data.closed_at.toDate()
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Tracking URL(s):
                    </td>
                    {selectedOrder.odeo &&
                      selectedOrder.odeo.fulfillment &&
                      selectedOrder.odeo.fulfillment.trackingUrls.length > 0 &&
                      selectedOrder.odeo.fulfillment.trackingUrls.map(
                        trackingUrl => {
                          return (
                            <td
                              key={trackingUrl}
                              style={{
                                paddingTop: 10,
                                paddingLeft: 5,
                                width: 400
                              }}
                            >
                              <Input readOnly value={trackingUrl} />
                            </td>
                          )
                        }
                      )}
                  </tr>
                  <tr>
                    <h2 style={{ color: '#1890ff', width: 150, marginTop: 10 }}>
                      Buyer Details
                    </h2>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Buyer Name :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Phone :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.phone}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Email :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.contact_email}
                      />
                    </td>
                  </tr>
                  <tr>
                    <h2 style={{ color: '#1890ff', width: 150, marginTop: 20 }}>
                      Address
                    </h2>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Street Address 1:
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.address1}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Street Address 2:
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.address2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      City :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.city}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      State :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.province_code}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Postcode :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.zip}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Country :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input
                        readOnly
                        value={selectedOrder.data.billing_address.country}
                      />
                    </td>
                  </tr>
                </Col>
                <Col sm={12} style={{ paddingLeft: 50 }}>
                  <tr>
                    <h2 style={{ color: '#1890ff' }}>Items Ordered</h2>
                  </tr>
                  <Table
                    style={{ marginTop: 10 }}
                    dataSource={lineItems}
                    columns={columns}
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                  />
                </Col>
              </Row>
            </tbody>
          </table>
        </Drawer>
      )}
    </>
  )
}

ShopifyProductDetails.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  orderDetailsDrawerVisible: PropTypes.bool.isRequired,
  selectedOrder: PropTypes.object.isRequired
}

export default ShopifyProductDetails
