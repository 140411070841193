import React, { useContext, useEffect, Fragment } from 'react'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { useLocation } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import PropTypes from 'prop-types'
import * as ROUTES from '../../constants/routes'

const userNavigation = [
  { name: 'Sign out', href: ROUTES.LOGOUT }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = props => {
  const { setSidebarOpen } = props
  const { userAccess, userDoc } = useContext(FirestoreContext)
  const location = useLocation()

  useEffect(() => {
  }, [userDoc])

  useEffect(() => {
  }, [location.pathname])

  return (
    <>
      {userAccess && userAccess.menuItems && (
        <div className='relative z-10 flex-shrink-0 flex h-16 bg-white border sm:pr-6'>
          <button
            className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
          </button>
          <div className='flex-1 px-4 flex justify-between'>
            <div className='flex-1 flex' />
            <div className='ml-4 flex items-center md:ml-6'>
              {/* <button className='bg-white p-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> */}
              {/*   <span className='sr-only'>View notifications</span> */}
              {/*   <BellIcon className='h-7 w-7' aria-hidden='true' /> */}
              {/* </button> */}

              {/* Profile dropdown */}
              <Menu as='div' className='ml-3 relative'>
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                        <span className='sr-only'>Open user menu</span>
                        <img
                          className='h-10 w-10 rounded-full'
                          src='https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                          alt=''
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter='transition ease-out duration-100'
                      enterFrom='transform opacity-0 scale-95'
                      enterTo='transform opacity-100 scale-100'
                      leave='transition ease-in duration-75'
                      leaveFrom='transform opacity-100 scale-100'
                      leaveTo='transform opacity-0 scale-95'
                    >
                      <Menu.Items
                        static
                        className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
                      >
                        {userNavigation.map(item => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Header.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired
}

export default Header
