import React, { useEffect, useReducer } from 'react'
import { appSettingReducer } from '../../reducers/appSettingsReducer'
import PropTypes from 'prop-types'
export const AppSettingsContext = React.createContext()

export const AppSettingsProvider = ({ children }) => {
  const [appSettings, dispatchAppSettings] = useReducer(
    appSettingReducer,
    {},
    () => {
      const localData = window.localStorage.getItem('appSettings')
      return localData
        ? JSON.parse(localData)
        : {
            hideInactiveMembers: true,
            hideInactiveAccounts: true,
            hideDisabledPreapprovedUsers: true
          }
    }
  )

  useEffect(() => {
    window.localStorage.setItem('appSettings', JSON.stringify(appSettings))
  }, [appSettings])

  // This method has been replaced by Reducer.
  // const updateSettings = (data) => {
  //   // Remember setXXX method in useState() is asynchronous (ie. There will be a delay between setting the data and the data being updated.)
  //   // In order to ensure the most recent data is used, access the data from useEffect. The pattern is akin to a callback function for a async function.
  //   setSettings({ ...settings, ...data })
  // }

  return (
    <AppSettingsContext.Provider value={{ appSettings, dispatchAppSettings }}>
      {children}
    </AppSettingsContext.Provider>
  )
}

AppSettingsProvider.propTypes = {
  children: PropTypes.object
}
