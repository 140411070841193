import React, { useContext, useEffect, Fragment } from 'react'
import { FirestoreContext } from '../Firebase/firestoreContext'
import {
  NEXT3PL_CLIENT,
  USER_PROFILE,
  NEXT3PL_TENANT_PROFILE,
  ORDER_STATUS,
  ADD_CONNECTOR,
  VIEW_CONNECTORS,
  BILLING,
  HOME,
  DASHBOARD
} from '../../constants/routes'
import { Link, useLocation } from 'react-router-dom'
import {
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XIcon,
  ClipboardListIcon
} from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import Logo from '../img/odeo-logo.png'
import { VERSION } from '../../constants/constants'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navigation = props => {
  const { sidebarOpen, setSidebarOpen } = props
  const { userAccess, userDoc } = useContext(FirestoreContext)
  const location = useLocation()

  useEffect(() => {}, [userDoc])

  useEffect(() => {}, [location.pathname])

  return userAccess && userAccess.menuItems
    ? (
      <>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            static
            className='fixed inset-0 flex z-40 md:hidden'
            open={sidebarOpen}
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex-shrink-0 flex items-center px-4'>
                  <img className='h-8 w-auto' src={Logo} alt='Odeo' />
                </div>
                <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                  <nav className='px-2 space-y-1'>
                    <Link
                      key='dashboard'
                      to={DASHBOARD}
                      className={classNames(
                        location.pathname === HOME
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      <HomeIcon
                        className={classNames(
                          location.pathname === HOME
                            ? 'text-gray-300'
                            : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden='true'
                      />
                      Dashboard
                    </Link>
                    {userAccess.menuItems && userAccess.menuItems.profile
                      ? (
                          userDoc.isTenant
                            ? (
                              <Link
                                key='tenant-profile'
                                to={NEXT3PL_TENANT_PROFILE}
                                className={classNames(
                                  location.pathname === NEXT3PL_TENANT_PROFILE
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                )}
                              >
                                <UsersIcon
                                  className={classNames(
                                    location.pathname === NEXT3PL_TENANT_PROFILE
                                      ? 'text-gray-300'
                                      : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden='true'
                                />
                                My Profile
                              </Link>
                              )
                            : (
                              <Link
                                key='user-profile'
                                to={USER_PROFILE}
                                className={classNames(
                                  location.pathname === USER_PROFILE
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                )}
                              >
                                <UsersIcon
                                  className={classNames(
                                    location.pathname === USER_PROFILE
                                      ? 'text-gray-300'
                                      : 'text-gray-400 group-hover:text-gray-300',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden='true'
                                />
                                My Profile
                              </Link>
                              )
                        )
                      : (
                          ''
                        )}

                    {userAccess.menuItems &&
                  userAccess.menuItems.connectorDashboard &&
                  !userDoc.isNextLogisticsCustomer
                      ? (
                        <Link
                          key='add-connector'
                          to={ADD_CONNECTOR}
                          className={classNames(
                            location.pathname === ADD_CONNECTOR
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <FolderIcon
                            className={classNames(
                              location.pathname === ADD_CONNECTOR
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          Add New Connectors
                        </Link>
                        )
                      : (
                          ''
                        )}
                    {userAccess.menuItems &&
                  userAccess.menuItems.connectorDashboard &&
                  !userDoc.isNextLogisticsCustomer
                      ? (
                        <Link
                          key='view-connector'
                          to={VIEW_CONNECTORS}
                          className={classNames(
                            location.pathname === VIEW_CONNECTORS
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <FolderIcon
                            className={classNames(
                              location.pathname === VIEW_CONNECTORS
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          View Connectors
                        </Link>
                        )
                      : (
                          ''
                        )}

                    {userAccess.menuItems &&
                  userAccess.menuItems.billing &&
                  !userDoc.isNextLogisticsCustomer
                      ? (
                        <Link
                          key='billing'
                          to={BILLING}
                          className={classNames(
                            location.pathname === BILLING
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <ClipboardListIcon
                            className={classNames(
                              location.pathname === BILLING
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          Billing
                        </Link>
                        )
                      : (
                          ''
                        )}

                    {userAccess.menuItems &&
                  userAccess.menuItems.clients &&
                  userDoc.isTenant
                      ? (
                        <Link
                          key='tenant-clients'
                          to={NEXT3PL_CLIENT}
                          className={classNames(
                            location.pathname === NEXT3PL_CLIENT
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <ClipboardListIcon
                            className={classNames(
                              location.pathname === NEXT3PL_CLIENT
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          Clients
                        </Link>
                        )
                      : (
                          ''
                        )}

                    {userAccess.menuItems && userDoc.isTenant
                      ? (
                        <Link
                          key='order-status'
                          to={ORDER_STATUS}
                          className={classNames(
                            location.pathname === ORDER_STATUS
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <ClipboardListIcon
                            className={classNames(
                              location.pathname === ORDER_STATUS
                                ? 'text-gray-300'
                                : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          Orders
                        </Link>
                        )
                      : (
                          ''
                        )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className='flex-shrink-0 w-14' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        <div className='hidden md:flex md:flex-shrink-0'>
          <div className='flex flex-col w-64'>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className='flex flex-col h-0 flex-1'>
              <div className='flex items-center h-16 flex-shrink-0 px-4 bg-gray-900'>
                <img className='h-10 w-auto' src={Logo} alt='Odeo' />
              </div>
              <div className='flex-1 flex flex-col overflow-y-auto'>
                <nav className='flex-1 px-2 py-4 bg-gray-800 space-y-1'>
                  <Link
                    key='dashboard'
                    to={DASHBOARD}
                    className={classNames(
                      location.pathname === DASHBOARD
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <HomeIcon
                      className={classNames(
                        location.pathname === DASHBOARD
                          ? 'text-gray-300'
                          : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    Dashboard
                  </Link>
                  {userAccess.menuItems && userAccess.menuItems.profile
                    ? (
                        userDoc.isTenant
                          ? (
                            <Link
                              key='tenant-profile'
                              to={NEXT3PL_TENANT_PROFILE}
                              className={classNames(
                                location.pathname === NEXT3PL_TENANT_PROFILE
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                              )}
                            >
                              <UsersIcon
                                className={classNames(
                                  location.pathname === NEXT3PL_TENANT_PROFILE
                                    ? 'text-gray-300'
                                    : 'text-gray-400 group-hover:text-gray-300',
                                  'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                              />
                              My Profile
                            </Link>
                            )
                          : (
                            <Link
                              key='user-profile'
                              to={USER_PROFILE}
                              className={classNames(
                                location.pathname === USER_PROFILE
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                              )}
                            >
                              <UsersIcon
                                className={classNames(
                                  location.pathname === USER_PROFILE
                                    ? 'text-gray-300'
                                    : 'text-gray-400 group-hover:text-gray-300',
                                  'mr-3 flex-shrink-0 h-6 w-6'
                                )}
                                aria-hidden='true'
                              />
                              My Profile
                            </Link>
                            )
                      )
                    : (
                        ''
                      )}

                  {userAccess.menuItems &&
                userAccess.menuItems.connectorDashboard &&
                !userDoc.isNextLogisticsCustomer
                    ? (
                      <Link
                        key='add-connector'
                        to={ADD_CONNECTOR}
                        className={classNames(
                          location.pathname === ADD_CONNECTOR
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <FolderIcon
                          className={classNames(
                            location.pathname === ADD_CONNECTOR
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        Add New Connectors
                      </Link>
                      )
                    : (
                        ''
                      )}

                  {userAccess.menuItems &&
                userAccess.menuItems.connectorDashboard &&
                !userDoc.isNextLogisticsCustomer
                    ? (
                      <Link
                        key='view-connector'
                        to={VIEW_CONNECTORS}
                        className={classNames(
                          location.pathname === VIEW_CONNECTORS
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <FolderIcon
                          className={classNames(
                            location.pathname === VIEW_CONNECTORS
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        View Connectors
                      </Link>
                      )
                    : (
                        ''
                      )}

                  {userAccess.menuItems &&
                userAccess.menuItems.billing &&
                !userDoc.isNextLogisticsCustomer
                    ? (
                      <Link
                        key='billing'
                        to={BILLING}
                        className={classNames(
                          location.pathname === BILLING
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <ClipboardListIcon
                          className={classNames(
                            location.pathname === BILLING
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        Billing
                      </Link>
                      )
                    : (
                        ''
                      )}

                  {userAccess.menuItems &&
                userAccess.menuItems.clients &&
                userDoc.isTenant
                    ? (
                      <Link
                        key='tenant-clients'
                        to={NEXT3PL_CLIENT}
                        className={classNames(
                          location.pathname === NEXT3PL_CLIENT
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <ClipboardListIcon
                          className={classNames(
                            location.pathname === NEXT3PL_CLIENT
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        Clients
                      </Link>
                      )
                    : (
                        ''
                      )}

                  {userAccess.menuItems && userDoc.isTenant
                    ? (
                      <Link
                        key='order-status'
                        to={ORDER_STATUS}
                        className={classNames(
                          location.pathname === ORDER_STATUS
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                      >
                        <ClipboardListIcon
                          className={classNames(
                            location.pathname === ORDER_STATUS
                              ? 'text-gray-300'
                              : 'text-gray-400 group-hover:text-gray-300',
                            'mr-3 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        Orders
                      </Link>
                      )
                    : (
                        ''
                      )}
                </nav>
              </div>
              <div className='flex-shrink-0 flex bg-gray-700 p-4'>
                <a href='#' className='flex-shrink-0 w-full group block'>
                  <div className='flex items-center'>
                    <div>
                      <img
                        className='inline-block h-9 w-9 rounded-full'
                        src='https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                        alt=''
                      />
                    </div>
                    <div className='ml-5'>
                      <p className='text-sm font-medium text-white'>
                        {userDoc && userDoc.name}
                      </p>
                      <p className='text-xs text-white'>
                        v {VERSION}
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
      )
    : (
        ''
      )
}

Navigation.propTypes = {
  sidebarOpen: PropTypes.bool.isRequired,
  setSidebarOpen: PropTypes.func.isRequired
}

export default Navigation
