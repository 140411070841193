import { useContext, useEffect, useState } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { SmileOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import { AuthContext } from '../Firebase/firebaseContext'
import { FirestoreContext } from '../Firebase/firestoreContext'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { NEXT3PL_TENANT_PROFILE } from '../../constants/routes'
import Breadcrumb from '../Breadcrumb'
import { CheckIcon } from '@heroicons/react/solid'
import * as queryString from 'query-string'
import { GOOGLE_CLOUD_PROJECT_ID } from '../../constants/constants'
import { doc, getDoc, updateDoc } from '@firebase/firestore'
import PropTypes from 'prop-types'

const ConnectorDashboard = props => {
  const { hidden } = props
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const { firestore, userDoc } = useContext(FirestoreContext)
  const [steps] = useState([
    { id: '01', name: 'Choose platform', href: '#', status: 'complete' },
    { id: '02', name: 'Enter Details', href: '#', status: 'complete' },
    { id: '03', name: 'Complete', href: '#', status: 'complete' }
  ])

  useEffect( () => {
    const doAsyncStuff = async () => {
      const params = queryString.parse(location.search)
      const state = params.state

      const docSnapshot = await getDoc(doc(firestore, 'xero_oauth', user.uid))
      if (docSnapshot.exists()) {
        const data = {
          ...docSnapshot.data().data,
          authorizationCode: { ...params, createdAt: new Date() }
        }

        await updateDoc(doc(firestore, 'xero_oauth', user.uid), {
          data,
          updatedAt: new Date()
        })

        window
          .fetch(
            `https://australia-southeast1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/xero-oauth-cloud-function`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ state })
            }
          )
          .then(res => res.json()) // expecting a json response
      }
    }
    doAsyncStuff()
  }, [])

  return (
    <>
      {userDoc && !userDoc.isNextLogisticsCustomer
        ? (
          <>
            <Breadcrumb
              hidden={hidden}
              pages={[{ name: 'Connector Setup', href: '#', current: true }]}
            />
            <div className='pb-12 sm:rounded-lg shadow'>
              <nav aria-label='Progress'>
                <ol className='border border-gray-300 divide-y divide-gray-300 md:flex md:divide-y-0'>
                  {steps.map((step, stepIdx) => (
                    <li key={step.name} className='relative md:flex-1 md:flex'>
                      {step.status === 'complete'
                        ? (
                          <div className='group flex items-center w-full'>
                            <span className='px-6 py-4 flex items-center text-sm font-medium'>
                              <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800'>
                                <CheckIcon
                                  className='w-6 h-6 text-white'
                                  aria-hidden='true'
                                />
                              </span>
                              <span className='ml-4 text-sm font-medium text-gray-900'>
                                {step.name}
                              </span>
                            </span>
                          </div>
                          )
                        : step.status === 'current'
                          ? (
                            <div
                              className='px-6 py-4 flex items-center text-sm font-medium'
                              aria-current='step'
                            >
                              <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full'>
                                <span className='text-indigo-600'>{step.id}</span>
                              </span>
                              <span className='ml-4 text-sm font-medium text-indigo-600'>
                                {step.name}
                              </span>
                            </div>
                            )
                          : (
                            <div className='group flex items-center'>
                              <span className='px-6 py-4 flex items-center text-sm font-medium'>
                                <span className='flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400'>
                                  <span className='text-gray-500 group-hover:text-gray-900'>
                                    {step.id}
                                  </span>
                                </span>
                                <span className='ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900'>
                                  {step.name}
                                </span>
                              </span>
                            </div>
                            )}

                      {stepIdx !== steps.length - 1
                        ? (
                          <>
                            {/* Arrow separator for lg screens and up */}
                            <div
                              className='hidden md:block absolute top-0 right-0 h-full w-5'
                              aria-hidden='true'
                            >
                              <svg
                                className='h-full w-full text-gray-300'
                                viewBox='0 0 22 80'
                                fill='none'
                                preserveAspectRatio='none'
                              >
                                <path
                                  d='M0 -2L20 40L0 82'
                                  vectorEffect='non-scaling-stroke'
                                  stroke='currentcolor'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            </div>
                          </>
                          )
                        : null}
                    </li>
                  ))}
                </ol>
              </nav>
              <div className='pt-4'>
                <Result
                  icon={<SmileOutlined />}
                  title='Great, we have done all the operations!'
                  subTitle='It may take a few minutes for your changes to be processed'
                />
              </div>
            </div>
          </>
          )
        : (
          <>{userDoc && <Navigate to={ NEXT3PL_TENANT_PROFILE } />}</>
          )}
    </>
  )
}

ConnectorDashboard.propTypes = {
  hidden: PropTypes.bool.isRequired
}

export default ConnectorDashboard
