import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { GOOGLE_API_GATEWAY_2 } from '../../constants/constants'
import { AuthContext } from '../Firebase/firebaseContext'

export default function AddClientForm (props) {
  const {
    setAddClientDrawerVisible,
    setDetailsChanged,
    tenantId,
    setupNewClientSnapshot
  } = props
  const { user } = useContext(AuthContext)
  const { userDoc } = useContext(FirestoreContext)
  const [businessName, setBusinessName] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [waitingForResponse, setWaitingForResponse] = useState(false)

  const handleSubmit = async e => {
    setWaitingForResponse(true)
    setErrorMessage('')
    e.preventDefault()
    const payload = {
      tenantId,
      userId: userDoc.id,
      client: {
        businessName,
        description,
        url,
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        country
      },
      createdBy: userDoc.id,
      createdAt: new Date()
    }

    const idToken = await user.getIdToken()
    window
      .fetch(`${GOOGLE_API_GATEWAY_2}/new-octoplux-client`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken
        },
        body: JSON.stringify(payload)
      })
      .then(res => res.json())
      .then(data => {
        setWaitingForResponse(false)
        if (data.success) {
          setupNewClientSnapshot(data.data.tenantId, data.data.clientId)
          // TODO
          // refresh page
        } else {
          setErrorMessage('Error: ' + data.message)
        }
      })
  }
  return (
    <form
      className='space-y-8 divide-y divide-gray-200 pb-12 bg-white py-6 px-6 rounded-md'
      onSubmit={e => handleSubmit(e)}
    >
      <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
        <div>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Client Information
            </h3>
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              This information will be used to link your new client to the
              system.
            </p>
          </div>

          <div className='mt-6 sm:mt-5 space-y-6 sm:space-y-5'>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='username'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Business Name
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    required
                    type='text'
                    name='company'
                    id='company'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-md sm:text-sm border-gray-300'
                    onChange={e => {
                      setDetailsChanged(true)
                      setBusinessName(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='about'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Description
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <textarea
                  id='about'
                  name='about'
                  rows={3}
                  className='max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md'
                  defaultValue=''
                  onChange={e => {
                    setDetailsChanged(true)
                    setDescription(e.target.value)
                  }}
                />
                <p className='mt-2 text-sm text-gray-500'>
                  Any extra information about your client can be added here.
                </p>
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='url'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                URL
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <div className='max-w-lg flex rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='url'
                    id='url'
                    className='flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-md sm:text-sm border-gray-300'
                    onChange={e => {
                      setDetailsChanged(true)
                      setUrl(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='pt-8 space-y-6 sm:pt-10 sm:space-y-5'>
          <div>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>
              Contact Information
            </h3>
            <p className='mt-1 max-w-2xl text-sm text-gray-500'>
              Use a permanent address where you can receive mail.
            </p>
          </div>
          <div className='space-y-6 sm:space-y-5'>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='first-name'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                First name
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input
                  type='text'
                  name='first-name'
                  id='first-name'
                  autoComplete='given-name'
                  className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                  onChange={e => {
                    setDetailsChanged(true)
                    setFirstName(e.target.value)
                  }}
                />
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Last name
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input
                  type='text'
                  name='last-name'
                  id='last-name'
                  autoComplete='family-name'
                  className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                  onChange={e => {
                    setDetailsChanged(true)
                    setLastName(e.target.value)
                  }}
                />
              </div>
            </div>

            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Email address
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                  onChange={e => {
                    setDetailsChanged(true)
                    setEmailAddress(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='city'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Phone Number
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <input
                  type='text'
                  name='phone'
                  id='phone'
                  className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                  onChange={e => {
                    setDetailsChanged(true)
                    setPhoneNumber(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
              <label
                htmlFor='country'
                className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
              >
                Country / Region
              </label>
              <div className='mt-1 sm:mt-0 sm:col-span-2'>
                <select
                  id='country'
                  name='country'
                  autoComplete='country'
                  defaultValue='default'
                  className='max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
                  onChange={e => {
                    setDetailsChanged(true)
                    setCountry(e.target.value)
                  }}
                >
                  <option disabled value='default'>
                    Choose a country
                  </option>
                  <option value='Australia'>Australia</option>
                  <option value='China'>China</option>
                  <option value='United States'>United States</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-5'>
        <div className='flex justify-end'>
          <button
            type='button'
            onClick={() => setAddClientDrawerVisible(false)}
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
          >
            Cancel
          </button>
          <button className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500'>
            Save
            {waitingForResponse && (
              <svg
                className='animate-spin ml-3 -mr-1 h-5 w-5 text-white'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
              >
                <circle
                  className='opacity-25'
                  cx='12'
                  cy='12'
                  r='10'
                  stroke='currentColor'
                  strokeWidth='4'
                />
                <path
                  className='opacity-75'
                  fill='currentColor'
                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                />
              </svg>
            )}
          </button>
        </div>
        {errorMessage.length > 1 && (
          <div className='flex justify-center text-red-500 text-base'>
            {errorMessage}
          </div>
        )}
      </div>
    </form>
  )
}

AddClientForm.propTypes = {
  setAddClientDrawerVisible: PropTypes.func.isRequired,
  setDetailsChanged: PropTypes.func.isRequired,
  tenantId: PropTypes.string.isRequired,
  setupNewClientSnapshot: PropTypes.func.isRequired
}
