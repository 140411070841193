import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FirestoreContext } from '../Firebase/firestoreContext'

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CaretRightOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
  EyeTwoTone
} from '@ant-design/icons'
import { Popconfirm, Collapse, Tag, Input, DatePicker, Switch } from 'antd'
import { AuthContext } from '../Firebase/firebaseContext'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  orderBy
} from '@firebase/firestore'
import moment from 'moment'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import { GOOGLE_CLOUD_PROJECT_ID } from '../../constants/constants'

const { Panel } = Collapse
const WC_WEBHOOKS = [
  {
    topic: 'subscription_billing_attempts/failure',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'subscription_billing_attempts/success',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'carts/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'carts/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'checkouts/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'checkouts/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'checkouts/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collections/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collections/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collections/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collection_listings/add',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collection_listings/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'collection_listings/remove',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customers/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customers/disable',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customers/enable',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customers/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_payment_methods/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_payment_methods/revoke',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_payment_methods/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_groups/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_groups/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'customer_groups/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'disputes/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'disputes/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'domains/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'domains/destroy',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'domains/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'draft_orders/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'draft_orders/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'draft_orders/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'fulfillments/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'fulfillments/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'fulfillment_events/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'fulfillment_events/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_items/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_items/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_items/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_levels/connect',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_levels/disconnect',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'inventory_levels/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'locations/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'locations/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'locations/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/cancelled',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/fulfilled',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/paid',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/partially_fulfilled',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/updated',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'orders/edited',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'order_transactions/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'products/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'products/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'products/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'product_listings/add',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'product_listings/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'product_listings/remove',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'profiles/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'profiles/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'profiles/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'refunds/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'app/uninstalled',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'shop/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'locales/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'locales/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'subscription_contracts/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'subscription_contracts/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'tender_transactions/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'themes/create',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'themes/publish',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'themes/update',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  },
  {
    topic: 'themes/delete',
    icon: <CheckCircleTwoTone twoToneColor='#52c41a' />,
    created: false,
    popconfirmVisible: false,
    popconfirmText: ''
  }
]
const dateFormat = 'D-MMM-YYYY h:mm:ss a'

export default function ClientStoreWoocommerce (props) {
  const { firestore } = useContext(FirestoreContext)
  const {
    tenant,
    client,
    store: propStore,
    clear,
    editModeEnabled,
    onSave,
    onCancel,
    setDetailsChanged,
    scratchStore,
    setScratchStore
  } = props
  const { user } = useContext(AuthContext)
  const [storeWebhooks, setStoreWebhooks] = useState([])
  const [, setStore] = useState({})
  const [apiPassword, setApiPassword] = useState({ changed: false })
  const [ftpPassword, setFtpPassword] = useState({ changed: false })
  const [
    excludeOrdersBeforeDateString,
    setExcludeOrdersBeforeDateString
  ] = useState()
  const [filters, setFilters] = useState([])

  const [unchangedScratchStore, setUnchangedScratchStore] = useState()
  const [unchangedFilters, setUnchangedFilters] = useState()

  useEffect(() => {}, [])
  useEffect(() => {
    const q = query(
      collection(
        firestore,
        'tenants',
        tenant.id,
        'clients',
        client.id,
        'stores',
        propStore.id,
        'webhooks'
      ),
      orderBy('createdAt', 'desc'),
      limit(1)
    )

    getDocs(q).then(querySnapshot => {
      const webhooks = [...WC_WEBHOOKS]
      querySnapshot.forEach(doc => {
        doc.data().data.webhooks.forEach(webhook => {
          const index = _.findIndex(webhooks, { topic: webhook.topic })
          let element = webhooks.find(({ topic }) => topic === webhook.topic)

          if (element) {
            element = { ...element, created: true }
            webhooks.splice(index, 1, element)
          }
        })
      })
      setStoreWebhooks(webhooks)
    })

    getDoc(
      doc(
        firestore,
        'tenants',
        tenant.id,
        'clients',
        client.id,
        'stores',
        propStore.id
      )
    ).then(docSnapshot => {
      if (docSnapshot.exists()) {
        setStore({ id: docSnapshot.id, ...docSnapshot.data() })
        if (
          docSnapshot.data().fulfillment &&
          docSnapshot.data().fulfillment.excludeOrdersBefore
        ) {
          setExcludeOrdersBeforeDateString(
            moment(
              docSnapshot.data().fulfillment.excludeOrdersBefore.toDate()
            ).format(dateFormat)
          )
        }

        // Set scratchstore data, need to deep merge
        const data1 = _.cloneDeep(docSnapshot.data())
        setScratchStore({ id: docSnapshot.id, ...data1 })
        const data2 = _.cloneDeep(docSnapshot.data())
        setUnchangedScratchStore({
          id: docSnapshot.id,
          ...data2
        })

        // Set filters
        if (docSnapshot.data().fulfillment) {
          if (docSnapshot.data().fulfillment.excludeOrderFiltersV2) {
            setFilters(
              _.cloneDeep(docSnapshot.data().fulfillment.excludeOrderFiltersV2)
            )
            setUnchangedFilters(
              _.cloneDeep(docSnapshot.data().fulfillment.excludeOrderFiltersV2)
            )
          }
        }
      }
    })
  }, [propStore])

  useEffect(() => {
    // TODO: uncomment for production, will throw a CORS error when launching server on localhost
    if (!_.isEmpty(scratchStore) && !editModeEnabled) {
      retrieveSecretFromDynamoDB(
        'stores',
        scratchStore.id,
        scratchStore.consumerSecretKey
      ).then(credential => {
        setApiPassword({ ...apiPassword, value: credential })
      })
      retrieveSecretFromDynamoDB(
        'stores',
        scratchStore.id,
        scratchStore.fulfillment.ftp.passwordKey
      ).then(credential => {
        setFtpPassword({ ...ftpPassword, value: credential })
      })

      // if (scratchStore.webhookSecretKey !== undefined) {
      //   retrieveSecretFromDynamoDB(
      //     'stores',
      //     scratchStore.id,
      //     scratchStore.webhookSecretKey
      //   ).then(credential => {
      //     setWebhookSecret({ ...webhookSecret, value: credential })
      //   })
      // } else {
      //   setWebhookSecret({ ...webhookSecret, value: 'N/A' })
      // }
    }
  }, [scratchStore])

  useEffect(() => {
    if (clear) {
      setStoreWebhooks([...WC_WEBHOOKS])
    }
  }, [clear])

  const retrieveSecretFromDynamoDB = async (type, typeId, secretKey) => {
    if (type && typeId && secretKey) {
      const idToken = await user.getIdToken()
      const rc = await window
        .fetch(
          `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/manage-secret/${type}/${typeId}/${secretKey}`,
          {
            headers: { Authorization: 'Bearer ' + idToken }
          }
        )
        .then(res => res.json())
        .then(json => {
          if (json.success) {
            return json.item.credential.S
          } else {
            return ''
          }
        })
      return rc
    } else {
      return ''
    }
  }

  const showPopconfirm = webhook => {
    if (webhook.created) {
      // show delete webhook popupconform
      const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
      let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

      const tempWebhooks = [...storeWebhooks]
      if (element) {
        element = {
          ...element,
          popconfirmText: `Are you sure you want to delete the webhook '${webhook.topic}'?`,
          popconfirmVisible: true,
          icon: <CloseCircleTwoTone twoToneColor='#eb2f96' />
        }
        tempWebhooks.splice(index, 1, element)
        setStoreWebhooks(tempWebhooks)
      }
    } else {
      // show create webhook popupconform
      const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
      let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

      const tempWebhooks = [...storeWebhooks]
      if (element) {
        element = {
          ...element,
          popconfirmText: `Are you sure you want to create the webhook '${webhook.topic}'?`,
          popconfirmVisible: true,
          icon: <CheckCircleTwoTone twoToneColor='#52c41a' />
        }
        tempWebhooks.splice(index, 1, element)
        setStoreWebhooks(tempWebhooks)
      }
    }
  }

  const handleWebhookOk = webhook => {
    const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
    let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

    const tempWebhooks = [...storeWebhooks]
    if (element) {
      element = {
        ...element,
        popconfirmText: '',
        popconfirmVisible: false
      }
      tempWebhooks.splice(index, 1, element)
      setStoreWebhooks(tempWebhooks)
    }
  }

  const handleWebhookCancel = webhook => {
    const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
    let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

    const tempWebhooks = [...storeWebhooks]
    if (element) {
      element = {
        ...element,
        popconfirmText: '',
        popconfirmVisible: false
      }
      tempWebhooks.splice(index, 1, element)
      setStoreWebhooks(tempWebhooks)
    }
  }

  const handleCancel = () => {
    // Call the parent's handleCancel() function to cancel any changes made.
    setScratchStore(_.cloneDeep(unchangedScratchStore))
    setFilters(_.cloneDeep(unchangedFilters))
    setDetailsChanged(false)
    onCancel()
  }

  const handleSave = () => {
    // Check if any filters are empty
    const filteredFilters = []
    Promise.all(
      filters.map(filter => {
        if (filter.value) {
          const obj = {}
          obj[filter.key] = filter.value
          filteredFilters.push(obj)
        }
        return true
      })
    ).then(() => {
      scratchStore.fulfillment.excludeOrderFiltersV2 = filteredFilters
      setUnchangedScratchStore(_.cloneDeep(scratchStore))
      setUnchangedFilters(_.cloneDeep(filteredFilters))
      setFilters(_.cloneDeep(filteredFilters))
      // Call the parent's handleSave() method. You can pass back an object that contains the values to be saved.
      // Update firestore document
      onSave(scratchStore)
    })
    setDetailsChanged(false)
  }

  const handleStoreEnabledChange = async checked => {
    setScratchStore({ ...scratchStore, enabled: checked })
    setDetailsChanged(true)
  }

  const handleFtpPasswordChange = e => {
    setFtpPassword({
      changed: true,
      value: e.target.value
    })
    setDetailsChanged(true)
  }

  const handleNameChanged = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.name = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleAPIKeyChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.apiKey = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleOrderPrefixChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.orderPrefix = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpOutputFilePrefixChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.ftpOutputFilePrefix = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpHostChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.host = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpUsernameChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.username = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleInboxLocationChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.inboxLocation = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleOutboxLocationChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.outboxLocation = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleEnableFulfillmentChange = checked => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.enabled = checked
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleSyncInventoryLevelChange = checked => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.syncInventoryLevel = checked
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleExcludeOrdersBeforeDateChange = (date, dateString) => {
    // const newScratchStore = { ...scratchStore }
    // newScratchStore.fulfillment.excludeOrdersBeforeDateString = date
    // setExcludeOrdersBeforeDateString(dateString)
  }

  return (
    <div className='bg-gray-100'>
      {/* <Breadcrumb
        hidden={false}
        pages={[{ name: 'Xero Links Setup', href: '#', current: true }]}
      /> */}

      <div className='space-y-6 max-w-6xl container mx-auto pr-4 pb-6'>
        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-4 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                API Details
              </h3>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-3'>
              <table style={{ marginTop: 10 }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Store ID :
                    </td>
                    <td style={{ paddingLeft: 5, width: 600 }}>
                      <Tag color='green' key={scratchStore.id}>
                        {scratchStore.id}
                      </Tag>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Enabled :
                    </td>
                    <td style={{ paddingLeft: 5 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        checked={scratchStore.enabled}
                        onChange={handleStoreEnabledChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Platform :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Tag color='green' key={scratchStore.platform}>
                        {scratchStore.platform}
                      </Tag>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Store :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={scratchStore.name}
                        onChange={handleNameChanged}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Consumer Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={scratchStore.consumerKey}
                        onChange={handleAPIKeyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 160 }}
                    >
                      Consumer Secret Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input.Password
                        styles={{ borderRadius: '6px', borderColour: 'black' }}
                        bordered
                        value={apiPassword.value}
                        disabled={!apiPassword.value}
                        iconRender={visible =>
                          visible
                            ? (
                              <EyeTwoTone />
                              )
                            : apiPassword.value
                              ? (
                                <EyeInvisibleOutlined />
                                )
                              : (
                                <LoadingOutlined />
                                )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingTop: 10, width: 700 }}>
                      <Collapse
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                      >
                        <Panel header='Webhooks' key='1'>
                          {storeWebhooks.map(webhook => {
                            return (
                              <Popconfirm
                                key={webhook.topic}
                                icon={webhook.icon}
                                visible={
                                  webhook.popconfirmVisible && editModeEnabled
                                }
                                title={webhook.popconfirmText}
                                okText='Yes'
                                cancelText='No'
                                onConfirm={() => handleWebhookOk(webhook)}
                                onCancel={() => handleWebhookCancel(webhook)}
                              >
                                <Tag
                                  style={{ margin: 4, cursor: 'pointer' }}
                                  color={webhook.created ? 'green' : 'red'}
                                  visible
                                  onClick={() => showPopconfirm(webhook)}
                                >
                                  {webhook.topic}
                                </Tag>
                              </Popconfirm>
                            )
                          })}
                        </Panel>
                      </Collapse>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-4 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                WooCommerce Settings
              </h3>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-3'>
              <table style={{ marginTop: 10 }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Enable Fulfillment :
                    </td>
                    <td style={{ paddingLeft: 5, width: 120 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        checked={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.enabled
                        }
                        onChange={handleEnableFulfillmentChange}
                      />
                    </td>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Inventory Syncing :
                    </td>
                    <td style={{ paddingLeft: 5 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='On'
                        unCheckedChildren='Off'
                        checked={scratchStore.syncInventoryLevel}
                        onChange={handleSyncInventoryLevelChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Order Prefix :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.orderPrefix
                        }
                        onChange={handleOrderPrefixChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Exclude Orders Before :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <DatePicker
                        showTime
                        onChange={handleExcludeOrdersBeforeDateChange}
                        value={
                          excludeOrdersBeforeDateString
                            ? moment(excludeOrdersBeforeDateString, dateFormat)
                            : ''
                        }
                        format={dateFormat}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Output File Prefix :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 600 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpOutputFilePrefixChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.ftpOutputFilePrefix
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Host :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpHostChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.host
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      User name :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpUsernameChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.username
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Password :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <Input.Password
                        readOnly={!editModeEnabled}
                        value={ftpPassword.value}
                        onChange={handleFtpPasswordChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Inbox Location :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleInboxLocationChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.inboxLocation
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Outbox Location :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleOutboxLocationChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.outboxLocation
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {editModeEnabled && (
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={() => handleCancel()}
              className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 '
            >
              Cancel
            </button>
            <button
              onClick={() => handleSave()}
              type='submit'
              className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 '
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ClientStoreWoocommerce.propTypes = {
  onFinish: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  updateCurrentStep: PropTypes.func.isRequired,
  tenant: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  clear: PropTypes.bool.isRequired,
  editModeEnabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setDetailsChanged: PropTypes.func.isRequired,
  scratchStore: PropTypes.object.isRequired,
  setScratchStore: PropTypes.func.isRequired
}
