import { Navigate, Outlet, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LOGIN } from '../../constants/routes'

const ProtectedRoute = ({ user, redirectPath = LOGIN, children }) => {
  const location = useLocation()

  if (!user) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
