import { useContext, useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
// import EbayAuthToken from 'ebay-oauth-nodejs-client'
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../Firebase/firebaseContext";
import { FirestoreContext } from "../Firebase/firestoreContext";
import {
  EBAY_OAUTH_CLIENT_ID,
  EBAY_OAUTH_DEV_ID,
  EBAY_BASE_URL,
  EBAY_OAUTH_REDIRECT_URL,
  EBAY_OAUTH_SCOPES,
  EBAY_OAUTH_WEB_ENDPOINT,
} from "../../constants/constants";
import PropTypes from "prop-types";

class EbayAuthToken {
  constructor(param) {
    // todo remove placeholder
  }
}

const EbayDetailsForm = (props) => {
  const { user } = useContext(AuthContext);
  const { updateCurrentStep, tenantId, clientId, platform, errorMessage } =
    props;
  const { firestore, userDoc } = useContext(FirestoreContext);

  useEffect(() => {}, [userDoc]);

  const handleEbayLogin = () => {
    // Get user consent
    // https://developer.ebay.com/api-docs/static/oauth-consent-request.html
    const stateUuid = uuidv4();
    const scopes = Array.isArray(EBAY_OAUTH_SCOPES)
      ? EBAY_OAUTH_SCOPES.join("%20")
      : EBAY_OAUTH_SCOPES;
    const ebayOauthUserConsentUrl = `${EBAY_OAUTH_WEB_ENDPOINT}?client_id=${EBAY_OAUTH_CLIENT_ID}&locale=en-AU&prompt=login&redirect_uri=${EBAY_OAUTH_REDIRECT_URL}&response_type=code&scope=${scopes}&state=${stateUuid}`;

    setDoc(doc(firestore, "ebay_oauth", user.uid), {
      tenantId: tenantId ? tenantId : "",
      clientId: clientId ? clientId : "",
      data: {
        scopes: EBAY_OAUTH_SCOPES,
        state: stateUuid,
        userAuthorizationUrl: ebayOauthUserConsentUrl,
      },
      oauthInProgress: true,
      createdAt: new Date(),
      updatedAt: new Date(),
    })
      .then(() => {
        window.location.href = ebayOauthUserConsentUrl;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleBack = () => {
    updateCurrentStep(1);
  };

  return (
    <>
      <div className="max-w-2xl container mx-auto px-4">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {platform} Login
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Login to your {platform} account to grant access to the ODEO
                  Connector to connect to your {platform} platform.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6 justify-start flex">
                  <button
                    type="button"
                    onClick={() => handleEbayLogin()}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Login to</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-11 h-7"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 100 52"
                    >
                      <path d="M71.474 30.746c-3.794.124-6.165.804-6.165 3.32 0 1.63 1.3 3.382 4.578 3.382 4.392 0 6.743-2.392 6.743-6.33v-.433l-5.155.062zm9.362 5.196l.144 3.505h-3.897c-.103-.887-.144-1.773-.144-2.64-2.103 2.598-4.62 3.34-8.104 3.34-5.155 0-7.918-2.722-7.918-5.877 0-4.578 3.753-6.186 10.3-6.33C73 27.9 75 27.9 76.65 27.9v-.454c0-3.052-1.96-4.3-5.36-4.3-2.516 0-4.392 1.052-4.578 2.846H62.3c.474-4.495 5.196-5.63 9.34-5.63 5 0 9.176 1.773 9.176 7.032v8.557z" />
                      <path d="M35.203 28.52c-.165-3.918-3-5.382-6.02-5.382-3.258 0-5.877 1.65-6.33 5.382zM22.77 31.304c.227 3.815 2.846 6.062 6.454 6.062 2.495 0 4.722-1 5.464-3.237h4.33c-.845 4.495-5.63 6.02-9.733 6.02-7.485 0-10.784-4.124-10.784-9.67 0-6.124 3.423-10.145 10.867-10.145 5.918 0 10.248 3.093 10.248 9.857v1.114z" />
                      <path d="M50.36 37.283c3.897 0 6.557-2.804 6.557-7.032s-2.66-7.032-6.557-7.032c-3.877 0-6.557 2.804-6.557 7.032s2.68 7.032 6.557 7.032zM39.615 12.97H43.8v10.537c2.062-2.454 4.887-3.155 7.67-3.155 4.68 0 9.857 3.155 9.857 9.96 0 5.7-4.124 9.857-9.94 9.857-3.052 0-5.897-1.093-7.67-3.258 0 .866-.04 1.732-.144 2.557H39.45l.144-4.33V12.97z" />
                      <path d="M102.178 21.034L89.207 46.5h-4.7l3.732-7.073-9.753-18.393h4.908l7.176 14.372 7.155-14.372z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => handleBack()}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Back
              </button>
            </div>
            {errorMessage.length > 0 && (
              <div className="flex justify-center text-base text-red-500">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

EbayDetailsForm.propTypes = {
  updateCurrentStep: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default EbayDetailsForm;
