import { ENVIRONMENT } from '../../constants/constants'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  updatePassword
} from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'

// Choose the correct Firebase project settings before deployment
let FIREBASE_CONFIG

if (ENVIRONMENT === 'PRODUCTION') {
  // Firebase config for project "odeo-octoplux"
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyDIElqF2_-yjNLCZ2XiMicHSWFq5tbzP_Y',
    authDomain: 'octoplux.odeo.com.au',
    databaseURL: 'https://odeo-octoplux.asia-southeast1.firebasedatabase.app/',
    projectId: 'odeo-octoplux',
    storageBucket: 'odeo-octoplux.appspot.com',
    messagingSenderId: '119207563661',
    appId: '1:119207563661:web:8b84ad85d623152b852dc4',
    measurementId: 'G-6J8TC6BXRT'
  }
} else {
  // Firebase config for project "octoplux-dev"
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBTPCyMR_1L6T2EXsEu6zD0Q-mMB97nstQ',
    authDomain: 'octoplux-dev.odeo.com.au',
    databaseURL: 'https://octoplux-dev-4207e.asia-southeast1.firebasedatabase.app/',
    projectId: 'octoplux-dev',
    storageBucket: 'octoplux-dev.appspot.com',
    messagingSenderId: '746832772095',
    appId: '1:746832772095:web:82ea7cd3ee2802d15f85ca',
    measurementId: 'G-2K1722S2MB'
  }
}

const firebaseApp = initializeApp(FIREBASE_CONFIG)
const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)

export const doSignInWithEmailAndPassword = (email, password) => {
  const result = signInWithEmailAndPassword(auth, email, password)
    .then(firebaseUser => {
      const result2 = getDoc(doc(firestore, 'users', firebaseUser.user.uid))
        .then(doc => {
          if (doc.exists() && doc.data()) {
            if (
              doc.data().approvedByAdmin &&
              doc.data().phone !== '' &&
              doc.data().name !== ''
            ) {
              return { success: true, uid: firebaseUser.user.uid }
            } else if (!doc.data().approvedByAdmin) {
              // If user is not approved yet, redirect to onboarding page
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Account is not approved yet',
                code: 510
              }
            } else if (doc.data().phone === '' || doc.data().name === '') {
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Incomplete user profile',
                code: 520
              }
            } else {
              // If it reaches this unknown catchall state, immediately sign the user out
              doSignOut()
              return {
                success: false,
                details: 'Login attempt failed.',
                code: 530
              }
            }
          } else {
            // If user document does not exist, immediately sign the user out
            doSignOut()
            return {
              success: false,
              details: 'Login attempt failed.',
              code: 540
            }
          }
        })
        .catch(error => {
          // If the user document cannot be retrieve, immediately sign the user out
          doSignOut()
          return { success: false, details: error, code: 598 }
        })

      return result2
    })
    .catch(error => {
      return { success: false, details: error, code: 599 }
    })

  return result
}

export const doSignInWithGoogle = () => {
  const googleProvider = new GoogleAuthProvider()
  const result = signInWithPopup(auth, googleProvider)
    .then(firebaseUser => {
      const result2 = getDoc(doc(firestore, 'users', firebaseUser.user.uid))
        .then(doc => {
          if (doc.exists() && doc.data()) {
            if (
              doc.data().approvedByAdmin &&
              doc.data().phone !== '' &&
              doc.data().name !== ''
            ) {
              return { success: true, uid: firebaseUser.user.uid }
            } else if (!doc.data().approvedByAdmin) {
              // If user is not approved yet, redirect to onboarding
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Account is not approved yet',
                code: 510
              }
            } else if (doc.data().phone === '' || doc.data().name === '') {
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Incomplete user profile',
                code: 520
              }
            } else {
              // If it reaches this unknown catchall state, immediately sign the user out
              doSignOut()
              return {
                success: false,
                details: 'Login attempt failed.',
                code: 530
              }
            }
          } else {
            // If user document does not exist, create a default user doc
            setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
              provider: '',
              name: '',
              email: '',
              phone: '',
              enabled: true,
              isTenant: false,
              approvedByAdmin: false,
              updatedAt: new Date(),
              updatedBy: firebaseUser.user.uid,
              createdAt: new Date(),
              createdBy: firebaseUser.user.uid
            })
            return { success: false, details: 'New user', code: 540 }
          }
        })
        .catch(error => {
          // If the user document cannot be retrieve, immediately sign the user out
          doSignOut()
          return { success: false, details: error, code: 598 }
        })
      return result2
    })
    .catch(error => {
      return { success: false, details: error, code: 550 }
    })
  return result
}

export const doSignInWithTwitter = () => {
  const twitterProvider = new TwitterAuthProvider()
  const result = signInWithPopup(auth, twitterProvider)
    .then(firebaseUser => {
      const result2 = getDoc(doc(firestore, 'users', firebaseUser.user.uid))
        .then(doc => {
          if (doc.exists() && doc.data()) {
            if (
              doc.data().approvedByAdmin &&
              doc.data().phone !== '' &&
              doc.data().name !== ''
            ) {
              return { success: true, uid: firebaseUser.user.uid }
            } else if (!doc.data().approvedByAdmin) {
              // If user is not approved yet, redirect to onboarding
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Account is not approved yet',
                code: 510
              }
            } else if (doc.data().phone === '' || doc.data().name === '') {
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Incomplete user profile',
                code: 520
              }
            } else {
              // If it reaches this unknown catchall state, immediately sign the user out
              doSignOut()
              return {
                success: false,
                details: 'Login attempt failed.',
                code: 530
              }
            }
          } else {
            // If user document does not exist, create a default user doc
            setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
              provider: '',
              name: '',
              email: '',
              phone: '',
              enabled: true,
              isTenant: false,
              approvedByAdmin: false,
              updatedAt: new Date(),
              updatedBy: firebaseUser.user.uid,
              createdAt: new Date(),
              createdBy: firebaseUser.user.uid
            })
            return { success: false, details: 'New user', code: 540 }
          }
        })
        .catch(error => {
          // If the user document cannot be retrieve, immediately sign the user out
          doSignOut()
          return { success: false, details: error, code: 598 }
        })
      return result2
    })
    .catch(error => {
      return { success: false, details: error, code: 550 }
    })
  return result
}

export const doSignInWithFacebook = () => {
  const facebookProvider = new FacebookAuthProvider()
  const result = signInWithPopup(auth, facebookProvider)
    .then(firebaseUser => {
      const result2 = getDoc(doc(firestore, 'users', firebaseUser.user.uid))
        .then(doc => {
          if (doc.exists() && doc.data()) {
            if (
              doc.data().approvedByAdmin &&
              doc.data().phone !== '' &&
              doc.data().name !== ''
            ) {
              return { success: true, uid: firebaseUser.user.uid }
            } else if (!doc.data().approvedByAdmin) {
              // If user is not approved yet, redirect to onboarding
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Account is not approved yet',
                code: 510
              }
            } else if (doc.data().phone === '' || doc.data().name === '') {
              return {
                success: false,
                uid: firebaseUser.user.uid,
                details: 'Incomplete user profile',
                code: 520
              }
            } else {
              // If it reaches this unknown catchall state, immediately sign the user out
              doSignOut()
              return {
                success: false,
                details: 'Login attempt failed.',
                code: 530
              }
            }
          } else {
            // If user document does not exist, create a default user doc
            setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
              provider: '',
              name: '',
              email: '',
              phone: '',
              enabled: true,
              isTenant: false,
              approvedByAdmin: false,
              updatedAt: new Date(),
              updatedBy: firebaseUser.user.uid,
              createdAt: new Date(),
              createdBy: firebaseUser.user.uid
            })
            return { success: false, details: 'New user', code: 540 }
          }
        })
        .catch(error => {
          // If the user document cannot be retrieve, immediately sign the user out
          doSignOut()
          return { success: false, details: error, code: 598 }
        })
      return result2
    })
    .catch(error => {
      return { success: false, details: error, code: 550 }
    })
  return result
}

export const doSignOut = async () => {
  return await signOut(auth)
    .then(() => {
      return { success: true }
    })
    .catch(function (error) {
      return { success: false, details: error }
    })
}

export const doPasswordReset = email => {
  sendPasswordResetEmail(auth, email)
}

export const doPasswordUpdate = password => {
  updatePassword(auth.currentUser, password)
}

export default firebaseApp
