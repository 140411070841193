import { useContext, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { AuthContext } from '../Firebase/firebaseContext'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { XERO_REDIRECT_URI, XERO_CLIENT_ID, XERO_OAUTH_SCOPES } from '../../constants/constants'
import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore'
import PropTypes from 'prop-types'

const XeroOAuthForm = props => {
  const { updateCurrentStep, platform } = props
  const { user } = useContext(AuthContext)
  const { firestore, userDoc } = useContext(FirestoreContext)

  useEffect(() => { }, [userDoc])

  const handleBack = () => {
    updateCurrentStep(1)
  }

  const handleXeroLogin = async () => {
    const stateUuid = uuidv4()
    const userAuthorizationUrl = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${XERO_REDIRECT_URI}&scope=${XERO_OAUTH_SCOPES}&state=${stateUuid}`

    const xeroOauthDoc = await getDoc(doc(firestore, 'xero_oauth', user.uid))
    if (!xeroOauthDoc.exists()) {
      await setDoc(doc(firestore, 'xero_oauth', user.uid), {
        data: {
          scopes: XERO_OAUTH_SCOPES,
          state: stateUuid,
          userAuthorizationUrl
        },
        oauthInProgress: true,
        createdAt: new Date(),
        updatedAt: new Date()
      })
      window.location.href = userAuthorizationUrl
    } else {
      const data = {
        ...xeroOauthDoc.data().data,
        scopes: XERO_OAUTH_SCOPES,
        state: stateUuid,
        userAuthorizationUrl
      }

      await updateDoc(doc(firestore, 'xero_oauth', user.uid), {
        data,
        oauthInProgress: true,
        updatedAt: new Date()
      })
      window.location.href = userAuthorizationUrl
    }
  }

  return (
    <>
      <div className='max-w-3xl container mx-auto px-4'>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div className='space-y-8 divide-y divide-gray-200'>
            <div className='pt-8'>
              <div>
                <h3 className='text-lg leading-6 font-medium text-gray-900'>
                  {platform} Login
                </h3>
                <p className='mt-1 text-sm text-gray-500'>
                  Login to your {platform} account to grant access to the ODEO Connector to connect to your {platform} platform.
                </p>
              </div>
              <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
                <div className='col-span-6'>

                  <button
                    onClick={() => handleXeroLogin()}
                    className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >

                    Login to Xero
                  </button>

                </div>

              </div>
            </div>
          </div>

          <div className='pt-5'>
            <div className='flex justify-end'>
              <button
                type='button'
                onClick={() => handleBack()}
                className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

XeroOAuthForm.propTypes = {
  updateCurrentStep: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired
}

export default XeroOAuthForm
