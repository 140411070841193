import { useEffect, useContext, useState } from 'react'
import _ from 'lodash'
import { Layout, Select, Input, Row, Col } from 'antd'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Navigate } from 'react-router-dom'
import { FirestoreContext } from '../Firebase/firestoreContext'
import ShopifyProductDetails from './shopifyProductDetails'
import { doc, getDoc } from '@firebase/firestore'
import { child, get, ref } from '@firebase/database'

const Products = () => {
  const { firestore, rtdb, userDoc } = useContext(FirestoreContext)
  const [gridApi, setGridApi] = useState(null)
  const [, setGridColumnApi] = useState(null)
  const [quickFilterText, setQuickFilterText] = useState('')
  const [orderList, setOrderList] = useState([])
  const [storeData, setStoreData] = useState()
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [orderDetailsDrawerVisible, setOrderDetailsDrawerVisible] = useState(
    false
  )
  const [, setClearAllChildren] = useState(false)

  useEffect(() => {
    const doAsyncStuff = async () => {
      if (rtdb && userDoc) {
        // Get list of all stores related to this tenant/client

        // 1. Get all tenants that user has read access to
        const tenants = []

        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/tenants/read`)
        ).then(tenantSnapshot => {
          if (tenantSnapshot.exists()) {
            tenantSnapshot.forEach(doc => {
              if (doc.val() === true) {
                tenants.push(doc.key)
              }
            })
          }
        })

        // 2. Get all clients that user has read access to
        const clients = []
        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/clients/read`)
        ).then(clientSnapshot => {
          if (clientSnapshot.exists()) {
            clientSnapshot.forEach(doc => {
              if (doc.val() === true) {
                clients.push(doc.key)
              }
            })
          }
        })

        // 3. Get all stores that user has read access to
        const stores = []
        await get(child(ref(rtdb), `user_access/${userDoc.id}/stores/read`)).then(
          storeSnapshot => {
            if (storeSnapshot.exists()) {
              storeSnapshot.forEach(doc => {
                if (doc.val() === true) {
                  stores.push(doc.key)
                }
              })
            }
          }
        )

        // 4. Iterate through all tenants -> clients -> stores
        const storeData = []
        await Promise.all(
          tenants.map(async tenant => {
            await get(child(ref(rtdb), `tenants/${tenant}/clients`)).then(
              clientList => {
                const clientListDoc = clientList.val()
                Promise.all(
                  Object.keys(clientListDoc).map(client => {
                    const clientDoc = clientList.val()[client]
                    // check if client in clients
                    if (clients.includes(client)) {
                      Promise.all(
                        Object.keys(clientDoc.stores).map(store => {
                          // check if store in stores
                          if (stores.includes(store)) {
                            const newStoreObj = {}
                            newStoreObj.tenantId = tenant
                            newStoreObj.clientId = client
                            newStoreObj.storeId = store
                            newStoreObj.name = clientDoc.stores[store].name
                            storeData.push(newStoreObj)
                          }
                          return null
                        })
                      )
                    }
                    return null
                  })
                )
              }
            )
          })
        ).then(() => {
          setStoreData(storeData)
        })
      }
    }
    doAsyncStuff()
  }, [rtdb, userDoc])

  useEffect(() => {
    const doAsyncStuff = async () => {
      if (selectedStore) {
        gridApi.showLoadingOverlay()
        const temp = []

        await get(child(ref(rtdb), `orders/${selectedStore.storeId}`)).then(
          snapshot => {
            if (snapshot.exists()) {
              snapshot.forEach(doc => {
                temp.push({
                  id: doc.key,
                  storeId: selectedStore.storeId,
                  clientId: selectedStore.clientId,
                  tenantId: selectedStore.tenantId,
                  orderedAt: doc.val().createdAt,
                  ...doc.val()
                })
              })
              const sorted = _.orderBy(temp, 'orderedAt', ['desc'])

              setOrderList(sorted)
            } else {
              setOrderList([])
            }
            gridApi.hideOverlay()
          }
        )
      }
    }
    doAsyncStuff()
  }, [selectedStore])

  const { Header, Content } = Layout
  const { Option } = Select
  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const handleRowSelected = async event => {
    //   Only check the node that is selected, not the node that was deselected
    if (event.node.isSelected()) {
      // Get the order from firestore

      getDoc(
        doc(
          firestore,
          'tenants',
          event.node.data.tenantId,
          'clients',
          event.node.data.clientId,
          'stores',
          event.node.data.id
        )
      ).then(doc => {
        setSelectedProduct(doc.data())
        setOrderDetailsDrawerVisible(true)
        setClearAllChildren(false)
      })
    }
  }

  const onDrawerClose = () => {
    setOrderDetailsDrawerVisible(false)
    setClearAllChildren(true)
    setSelectedProduct({})
  }

  const handleQuickFilterTextChanged = e => {
    setQuickFilterText(e.target.value)
  }

  function onChange (value) {
    setSelectedStore(storeData[value])
  }

  return (
    <>
      {userDoc
        ? (
          <>
            <Header className='site-layout-background' style={{ padding: 0 }} />
            <Content style={{ margin: '0 16px' }}>
              <div
                className='site-layout-background'
                style={{ padding: 24, minHeight: 360 }}
              >
                <Row style={{ paddingBottom: 10 }}>
                  {storeData
                    ? (
                      <Select
                        showSearch
                        style={{ width: '20%' }}
                        placeholder='Select a store'
                        optionFilterProp='store'
                        onChange={onChange}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0}
                      >
                        {storeData &&
                      storeData.map((store, i) => {
                        return (
                          <Option key={store.name} value={i}>
                            {store.name}
                          </Option>
                        )
                      })}
                      </Select>
                      )
                    : (
                      <Select
                        style={{ width: '20%' }}
                        placeholder='Loading stores...'
                        disabled
                        loading
                      />
                      )}
                </Row>
                <Row style={{ paddingBottom: 10 }}>
                  <Col sm={24} lg={8}>
                    <Input
                      onChange={handleQuickFilterTextChanged}
                      placeholder='Search'
                    />
                  </Col>
                </Row>
                <Row>
                  <div
                    className='ag-theme-alpine'
                    style={{ height: 600, width: '80%' }}
                  >
                    <AgGridReact
                      defaultColDef={{ resizable: true }}
                      quickFilterText={quickFilterText}
                      rowSelection='single'
                      onRowDoubleClicked={handleRowSelected}
                      onGridReady={onGridReady}
                      rowData={orderList}
                    >
                      <AgGridColumn field='itemId' flex={1} filter />
                      <AgGridColumn field='buyer' flex={1} filter />
                      <AgGridColumn field='platform' flex={1} filter />
                      <AgGridColumn field='fulfillmentStatus' filter flex={1} />
                      <AgGridColumn field='orderedAt' filter flex={1} />
                    </AgGridReact>
                  </div>
                </Row>
              </div>
            </Content>
          </>
          )
        : (
          <>{userDoc && <Navigate to='/' />}</>
          )}
      {selectedProduct && (
        <ShopifyProductDetails
          onDrawerClose={onDrawerClose}
          orderDetailsDrawerVisible={orderDetailsDrawerVisible}
          selectedOrder={selectedProduct}
        />
      )}
    </>
  )
}

export default Products
