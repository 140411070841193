import { useState } from 'react'
import { Layout, Select, Form, Input, Button, Divider } from 'antd'

const { Content } = Layout
const { Option } = Select
const cryptoRandomString = require('crypto-random-string')

const LinkClients = () => {
  const [form] = Form.useForm()
  const [storeId, setStoreID] = useState(cryptoRandomString({ length: 20 }))

  const handleGenerateNewStoreID = () => {
    const newStoreId = cryptoRandomString({ length: 20 })
    setStoreID(newStoreId)
  }
  return (
    <>
      {/* <Header className='site-layout-background' style={{ padding: 0 }} /> */}
      <Content style={{ margin: '0 16px' }}>
        <Form
          className='site-layout-background'
          style={{ padding: 12, minHeight: 360 }}
          form={form}
          layout='vertical'
          requiredMark
        >
          <Divider orientation='left'>Store Details</Divider>
          <Form.Item label='Select Platform' name='selectPlatform' required>
            <Select
              placeholder='Select a platform'
              allowClear
            >
              <Option value='Shopify'>Shopify</Option>
              <Option value='eBay'>eBay</Option>
              <Option value='WooCommerce'>WooCommerce</Option>
              <Option value='Amazon'>Amazon</Option>
            </Select>
          </Form.Item>
          <Form.Item
            // style={{ width: '50%' }}
            label='Store Name'
            required
            tooltip='This is a required field'
          >
            <Input placeholder='example.myshopify.com' />
          </Form.Item>
          <Form.Item
            // style={{ width: '50%' }}
            label='Store ID'
            required
            tooltip='This is a required field'
          >
            <Input placeholder='input placeholder' value={storeId} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' onClick={() => handleGenerateNewStoreID()}>
              Generate New Store ID
            </Button>
          </Form.Item>

          {/* <Form.Item label='Required Mark' name='requiredMark'>
            <Radio.Group>
              <Radio.Button value='optional'>Optional</Radio.Button>
              <Radio.Button value>Required</Radio.Button>
              <Radio.Button value={false}>Hidden</Radio.Button>
            </Radio.Group>
          </Form.Item> */}
          <Divider orientation='left'>Webhook Keys</Divider>
          <Form.Item
            // style={{ width: '50%' }}
            label='Secret Key'
            required
            tooltip='This is a required field'
          >
            <Input placeholder='Secret Webhook Key' />
          </Form.Item>
          <Divider orientation='left'>REST API Keys</Divider>
          <Form.Item
            // style={{ width: '50%' }}
            label='Public Key'
            required
            tooltip='This is a required field'
          >
            <Input placeholder='Public API Key' />
          </Form.Item>

          <Form.Item
            // style={{ width: '50%' }}
            label='Secret Key'
            required
            tooltip='This is a required field'
          >
            <Input placeholder='Secret API Key' />
          </Form.Item>
          <Form.Item>
            <Button type='primary'>Submit</Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  )
}

export default LinkClients
