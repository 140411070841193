import { useContext, useEffect, useState, Fragment, useRef } from 'react'
import _ from 'lodash'
import { Row, Col, Checkbox } from 'antd'
import { child, get, ref, getDatabase, onValue } from '@firebase/database'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { AppSettingsContext } from '../Context/appSettingsContext'
import { UPDATE_SETTING } from '../../reducers/actionTypes'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import ClientProfileTab from './clientProfileTab'
import ClientStoresTab from './clientStoresTab'
import AddNewConnectorTab from '../ConnectorDashboard'
import Breadcrumb from '../Breadcrumb'
import { BanIcon, CheckIcon, PlusCircleIcon } from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, ExclamationIcon } from '@heroicons/react/outline'
import AddClientForm from './addClientForm'

const tabs = [
  { name: 'Details', href: '#', current: true },
  { name: 'Stores', href: '#', current: false },
  { name: 'Add Connector', href: '#', current: false }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const Clients = () => {
  const { rtdb, userDoc } = useContext(FirestoreContext)
  const { appSettings, dispatchAppSettings } = useContext(AppSettingsContext)
  const [tenant] = useState({
    id: 'zY3KWhYaCQYy4b0bLR2M', // Need to change this for production
    name: 'Next3PL'
  })
  const { userAccess } = useContext(FirestoreContext)
  const [tenantReadAccessAllowed, setTenantReadAccessAllowed] = useState(false)
  const [tenantWriteAccessAllowed, setTenantWriteAccessAllowed] = useState(false)
  const [clientReadAccessAllowed, setClientReadAccessAllowed] = useState(false)
  const [clientWriteAccessAllowed, setClientWriteAccessAllowed] = useState(false)
  const [isAdministrator, setAdministrator] = useState(false)
  const [selectedClient, setSelectedClient] = useState()
  const [tenantClients, setTenantClients] = useState([])
  const [tenantClientChange, setTenantClientChange] = useState()
  const [displayClients, setDisplayClients] = useState([])
  const [quickFilterText, setQuickFilterText] = useState('')
  const [, setGridApi] = useState(null)
  const [, setGridColumnApi] = useState(null)
  const [clientDetailsDrawerVisible, setClientDetailsDrawerVisible] = useState(
    false
  )
  const [clearAllChildren, setClearAllChildren] = useState(false)
  const [currentTab, setCurrentTab] = useState('Details')
  const [addClientDrawerVisible, setAddClientDrawerVisible] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [detailsChanged, setDetailsChanged] = useState(false)
  const [initialRun, setInitialRun] = useState(true)
  const cancelButtonRef = useRef(null)

  const evaluateUserAccessToTenant = () => {
    if (userAccess) {
      setAdministrator(userAccess.administrator)
      if (tenant && tenant.id) {
        if (userAccess.administrator || _.includes(userAccess.tenants.write, tenant.id)) {
        // If user has WRITE access then the user automatically has READ access.
          setTenantWriteAccessAllowed(true)
          setTenantReadAccessAllowed(true)
        } else {
          setTenantReadAccessAllowed(userAccess.administrator || _.includes(userAccess.tenants.read, tenant.id))
        }
      } else if (userAccess.administrator) {
        setTenantWriteAccessAllowed(true)
        setTenantReadAccessAllowed(true)
      } else {
        setTenantWriteAccessAllowed(false)
        setTenantReadAccessAllowed(false)
      }
    } else {
      setTenantWriteAccessAllowed(false)
      setTenantReadAccessAllowed(false)
    }
  }

  const evaluateUserAccessToClient = () => {
    if (userAccess) {
      setAdministrator(userAccess.administrator)
      if (selectedClient && selectedClient.id) {
        if (userAccess.administrator || _.includes(userAccess.clients.write, selectedClient.id)) {
        // If user has WRITE access then the user automatically has READ access.
          setClientWriteAccessAllowed(true)
          setClientReadAccessAllowed(true)
        } else {
          setClientReadAccessAllowed(userAccess.administrator || _.includes(userAccess.clients.read, selectedClient.id))
        }
      } else if (userAccess.administrator) {
        setClientWriteAccessAllowed(true)
        setClientReadAccessAllowed(true)
      } else {
        setClientWriteAccessAllowed(false)
        setClientReadAccessAllowed(false)
      }
    } else {
      setClientWriteAccessAllowed(false)
      setClientReadAccessAllowed(false)
    }
  }

  useEffect(() => {
    // Evaluate the user's access to the tenant every time the userAccess property or the tenant changed.
    evaluateUserAccessToTenant()
  }, [userAccess, tenant])

  useEffect(() => {
    // Evaluate the user's access to the selected client every time the userAccess or selectedClient property changed.
    evaluateUserAccessToClient()
  }, [userAccess, selectedClient])

  useEffect(() => {
    const fetchData = async () => {
      if (rtdb && userDoc) {
        // Get list of all clients that tenant has access to
        // 1. Get all tenants that user has read access to
        const tenants = []

        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/tenants/read`)
        ).then(tenantSnapshot => {
          if (tenantSnapshot.exists()) {
            tenantSnapshot.forEach(doc => {
              if (doc.val() === true) {
                tenants.push(doc.key)
              }
            })
          }
        })

        // 2. Get all clients that user has read access to
        const clients = []
        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/clients/read`)
        ).then(clientSnapshot => {
          if (clientSnapshot.exists()) {
            clientSnapshot.forEach(doc => {
              if (doc.val() === true) {
                clients.push(doc.key)
              }
            })
          }
        })

        // 3. Iterate through all tenants -> clients
        const clientData = []
        await Promise.all(
          tenants.map(async tenant => {
            await get(child(ref(rtdb), `tenants/${tenant}/clients`)).then(
              clientList => {
                const clientListDoc = clientList.val()
                Promise.all(
                  Object.keys(clientListDoc).map(client => {
                    const clientDoc = clientList.val()[client]
                    // loop through stores and get platforms
                    const platformList = []
                    if (clientDoc.stores) {
                      Object.keys(clientDoc.stores).map(store => {
                        if (
                          !platformList.includes(
                            clientDoc.stores[store].platform
                          )
                        ) {
                          platformList.push(clientDoc.stores[store].platform)
                        }
                      })
                    }
                    // check if client in clients
                    if (clients.includes(client)) {
                      clientData.push({
                        id: client,
                        platforms: platformList,
                        ...clientDoc
                      })
                    }
                    return null
                  })
                )
              }
            )
          })
        ).then(() => {
          clientData.sort((a, b) => (a.name > b.name ? 1 : -1)) // sort alphabetically
          setTenantClients(clientData)
        })
      }
    }
    fetchData()
  }, [rtdb, userDoc])

  useEffect(() => {
     const doStuff = async () => {
      if (rtdb && tenantClients.length > 0) {
        if (initialRun) {
          // only setup the snapshots on the initial load of page
          await Promise.all(
            tenantClients.map(client => {
              const db = getDatabase()
              const clientRef = ref(
                db,
                'tenants/' + tenant.id + '/clients/' + client.id
              )
              onValue(clientRef, snapshot => {
                setTenantClientChange({ id: client.id, ...snapshot.val() })
              })
            })
          ).then(() => {
            setInitialRun(false) // once snapshots have been setup, set initial run to false
          })
        }
      }
    }
    doStuff();
  }, [rtdb, tenantClients])

  const setupNewClientSnapshot = (tenantId, clientId) => {
    const db = getDatabase()
    const clientRef = ref(db, 'tenants/' + tenantId + '/clients/' + clientId)
    onValue(clientRef, snapshot => {
      setTenantClientChange({ id: clientId, ...snapshot.val() })
    })
  }

  useEffect(() => {
    if (tenantClientChange) {
      processTenantClientAddedOrModifiedEvent(tenantClientChange)
    }
  }, [tenantClientChange])

  useEffect(() => {
    // update displayUsers depending whether hide inactive members is enabled.
    if (appSettings.hideDisabledClients) {
      const enabledClients = tenantClients.filter(client => client.enabled)
      setDisplayClients(enabledClients)
    } else {
      setDisplayClients(tenantClients)
    }
  }, [tenantClients, appSettings, appSettings.hideDisabledClients])

  const processTenantClientAddedOrModifiedEvent = newTenantClient => {
    const index = _.findIndex(tenantClients, { id: newTenantClient.id })
    if (index < 0) {
      // if client does not exist yet then add client
      const newArray = tenantClients.concat({
        ...newTenantClient
      })
      newArray.sort((a, b) => (a.name > b.name ? 1 : -1)) // sort alphabetically
      setTenantClients(newArray)

      // open new client in a popup drawer
      setAddClientDrawerVisible(false)
      setConfirmModal(false)
      setDetailsChanged(false)
      setSelectedClient(newTenantClient)
      setClientDetailsDrawerVisible(true)
      setClearAllChildren(false)
      handleTabChange({ name: 'Details' })
    } else {
      // if client already exists, then need to replace it
      const newArray = _.cloneDeep(tenantClients)
      const updatedTenantClient = _.cloneDeep(tenantClients[index])
      newArray.splice(index, 1, {
        ...updatedTenantClient,
        ...newTenantClient
      })
      setTenantClients(newArray)
    }
  }

  // const processTenantClientRemovedEvent = () => {
  //   const index = _.findIndex(tenantClients, { id: tenantClientChange.doc.id })
  //   if (index >= 0) {
  //     const newArray = _.cloneDeep(tenantClients)
  //     newArray.splice(index, 1)
  //     setTenantClients(newArray)
  //   }
  // }

  const onGridReady = params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.api.showLoadingOverlay()
  }

  const handleQuickFilterTextChanged = e => {
    setQuickFilterText(e.target.value)
  }

  const handleHideDisabledClientsChanged = e => {
    dispatchAppSettings({
      type: UPDATE_SETTING,
      payload: { hideDisabledClients: e.target.checked }
    })
  }

  const handleRowSelected = event => {
    //   Only check the node that is selected, not the node that was deselected
    if (event.node.isSelected()) {
      setSelectedClient(event.node.data)
      setClientDetailsDrawerVisible(true)
      setClearAllChildren(false)
      handleTabChange({ name: 'Details' })
    }
  }

  const confirmClose = () => {
    if (detailsChanged) {
      setConfirmModal(true)
    } else {
      setClientDetailsDrawerVisible(false)
      setAddClientDrawerVisible(false)
      setClearAllChildren(true)
      setSelectedClient({})
      handleTabChange({ name: 'Details' })
    }
  }

  const handleConfirmClose = () => {
    setClientDetailsDrawerVisible(false) // close client details drawer
    setAddClientDrawerVisible(false) // close add client drawer
    setConfirmModal(false)
    setDetailsChanged(false)
    handleTabChange({ name: 'Details' })
  }

  const handleAddClientClick = () => {
    setAddClientDrawerVisible(true)
  }

  const handleTabChange = tab => {
    setDetailsChanged(false)
    tabs.find(tab => tab.current).current = false
    setCurrentTab(tab.name)
    tabs.find(newTab => newTab.name === tab.name).current = true
  }

  const enabledCellRenderer = cell => {
    if (cell.data.enabled) {
      return (
        <CheckIcon
          className='mr-3 -ml-1 h-10 w-5 text-green-500'
          aria-hidden='true'
        />
      )
    } else {
      return (
        <BanIcon
          className='mr-3 -ml-1 h-10 w-5 text-red-500'
          aria-hidden='true'
        />
      )
    }
  }

  return (
    <>
      <Breadcrumb
        pages={[{ name: 'Clients', href: '#', current: true }]}
        hidden={false}
      />
      {tenantReadAccessAllowed
        ? (
          <div
            className='site-layout-background'
            style={{ padding: 24, minHeight: 360 }}
          >
            <div className='grid grid-cols-3 gap-4 pb-4'>
              <div className='col-span-3 sm:col-span-1 order-2 sm:order-none'>
                <input
                  type='text'
                  className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                  onChange={handleQuickFilterTextChanged}
                  placeholder='Search'
                />
              </div>
              <div className='col-span-3 sm:col-start-3 flex justify-end float-left e order-1 sm:order-none'>
                <button
                  type='button'
                  className='inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 '
                  onClick={() => handleAddClientClick()}
                >
                  <PlusCircleIcon
                    className='mr-3 -ml-1 h-5 w-5'
                    aria-hidden='true'
                  />
                  Add Client
                </button>
              </div>
            </div>
            <Row style={{ paddingBottom: 10 }}>
              <Col sm={24} lg={8}>
                <Checkbox
                  checked={appSettings.hideDisabledClients}
                  onChange={handleHideDisabledClientsChanged}
                >
                  Hide disabled clients
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <div className='ag-theme-alpine w-screen sm:mx-0 min-h-2/3vh'>
                <AgGridReact
                  defaultColDef={{ resizable: true }}
                  frameworkComponents={{
                    enabledCellRenderer: enabledCellRenderer
                  }}
                  quickFilterText={quickFilterText}
                  rowSelection='single'
                  onRowClicked={handleRowSelected}
                  onRowSelected={handleRowSelected}
                  onGridReady={onGridReady}
                  rowData={displayClients}
                >
                  <AgGridColumn
                    field='active'
                    width={200}
                    filter
                    cellRenderer='enabledCellRenderer'
                  />
                  <AgGridColumn field='name' filter flex={1} />
                  <AgGridColumn field='platforms' filter flex={2} />
                </AgGridReact>
              </div>
            </Row>
          </div>
          )
        : 'Access Denied'}

      <Transition.Root show={addClientDrawerVisible} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed inset-0 overflow-hidden z-30'
          open={addClientDrawerVisible}
          onClose={confirmClose}
        >
          <div className='absolute inset-0 overflow-hidden'>
            <Transition.Child
              as={Fragment}
              enter='ease-in-out duration-500'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in-out duration-500'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-y-0 right-0 pl-10  flex max-w-screen sm:max-w-5xl'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <div className='w-screen'>
                  <div className='h-full flex flex-col pt-6 bg-white shadow-xl overflow-y-scroll'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-lg font-medium text-gray-900'>
                          Add New Client
                        </Dialog.Title>
                        <div className='ml-3 h-7 flex items-center'>
                          <button
                            className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                            onClick={() => setAddClientDrawerVisible(false)}
                          >
                            <span className='sr-only'>Close panel</span>
                            <XIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='mt-6 relative flex-1 px-4 sm:px-6'>
                      {/* Replace with your content */}
                      <div className='absolute inset-0 px-4 sm:px-6 py-4 bg-gray-100'>
                        <AddClientForm
                          setupNewClientSnapshot={setupNewClientSnapshot}
                          tenantId={tenant.id}
                          setDetailsChanged={setDetailsChanged}
                          setAddClientDrawerVisible={setAddClientDrawerVisible}
                        />
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {selectedClient && clientReadAccessAllowed
        ? (
          <Transition.Root show={clientDetailsDrawerVisible} as={Fragment}>
            <Dialog
              as='div'
              static
              className='fixed inset-0 overflow-hidden z-30'
              open={clientDetailsDrawerVisible}
              onClose={confirmClose}
            >
              <div className='absolute inset-0 overflow-hidden '>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-500'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-500'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                </Transition.Child>

                <div className='fixed inset-y-0 right-0 max-w-full flex '>
                  <Transition.Child
                    as={Fragment}
                    enter='transform transition ease-in-out duration-500 sm:duration-700'
                    enterFrom='translate-x-full'
                    enterTo='translate-x-0'
                    leave='transform transition ease-in-out duration-500 sm:duration-700'
                    leaveFrom='translate-x-0'
                    leaveTo='translate-x-full'
                  >
                    <div className='w-screen max-w-screen sm:max-w-9xl '>
                      <div className='h-full flex flex-col pt-6 bg-white shadow-xl overflow-y-scroll'>
                        <div className='px-4 sm:px-6'>
                          <div className='flex items-start justify-between'>
                            <Dialog.Title className='text-lg font-medium text-gray-900'>
                              {selectedClient.name}{' '}
                              <span
                                style={{
                                  fontWeight: 'normal',
                                  fontSize: '0.7em',
                                  color: '#888888'
                                }}
                              >
                                (Client ID : {selectedClient.id})
                              </span>
                            </Dialog.Title>
                            <div className='ml-3 h-7 flex items-center'>
                              <button
                                className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                onClick={() => {
                                  setClientDetailsDrawerVisible(false)
                                }}
                              >
                                <span className='sr-only'>Close panel</span>
                                <XIcon className='h-6 w-6' aria-hidden='true' />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mt-6 relative flex-1 bg-gray-200'>
                          <div>
                            <nav
                              className=' flex space-x-8 px-4 pt-4 bg-white'
                              x-descriptions='Tab component'
                            >
                              {tabs.map(tab => (
                                <button
                                  key={tab.name}
                                  onClick={() => handleTabChange(tab)}
                                  className={classNames(
                                    tab.current
                                      ? 'border-indigo-500 text-indigo-600'
                                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                                  )}
                                >
                                  {tab.name}
                                </button>
                              ))}
                            </nav>
                          </div>
                          <div className='bg-gray-200'>
                            {currentTab === 'Details'
                              ? (
                                <ClientProfileTab
                                  tenant={tenant}
                                  parentClient={selectedClient}
                                  setDetailsChanged={setDetailsChanged}
                                />
                                )
                              : currentTab === 'Stores'
                                ? (
                                  <ClientStoresTab
                                    tenant={tenant}
                                    client={selectedClient}
                                    clear={clearAllChildren}
                                    setDetailsChanged={setDetailsChanged}
                                  />
                                  )
                                : (
                                  <AddNewConnectorTab
                                    hidden
                                    tenantId={tenant.id}
                                    clientId={selectedClient.id}
                                    handleTabChange={handleTabChange}
                                    parentClient={selectedClient}
                                  />
                                  )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          )
        : (
            ''
          )}
      <Transition.Root show={confirmModal} as={Fragment}>
        <Dialog
          as='div'
          static
          className='fixed z-50 inset-0 overflow-y-auto'
          initialFocus={cancelButtonRef}
          open={confirmModal}
          onClose={setConfirmModal}
        >
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 z-80'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationIcon
                      className='h-6 w-6 text-red-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg leading-6 font-medium text-gray-900'
                    >
                      Unsaved Changes
                    </Dialog.Title>
                    <div className='mt-2'>
                      <p className='text-sm text-gray-500'>
                        Are you sure you want to close this window? All unsaved
                        changes will be discarded
                      </p>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    type='button'
                    className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
                    onClick={() => handleConfirmClose()}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                    onClick={() => setConfirmModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Clients
