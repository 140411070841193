import React, { useContext, useEffect, useState } from 'react'
import firebaseApp from './firebaseConfig'
import { AuthContext } from './firebaseContext'
import { getStorage } from 'firebase/storage'
import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import PropTypes from 'prop-types'
export const FirestoreContext = React.createContext()

export const FirestoreProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [firestore, setFirestore] = useState(null)
  const [rtdb, setRtdb] = useState(null)
  const [storage, setStorage] = useState(null)
  const [userAccess, setUserAccess] = useState(null)
  const [userDoc, setUserDoc] = useState(null)
  const [userSessionDoc, setUserSessionDoc] = useState(null)

  useEffect(() => {
    setFirestore(getFirestore(firebaseApp))
    setStorage(getStorage(firebaseApp))
    setRtdb(getDatabase(firebaseApp))
  }, [])

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId')
    console.log(`FirestoreProvider sessionId = ${sessionId}`)
    if (user && sessionId) {
      getDoc(doc(firestore, 'users', user.uid)).then(document => {
        if (document.exists) {
          setUserDoc({ id: document.id, ...document.data() })

          getDoc(doc(firestore, `users/${user.uid}/user_sessions/${sessionId}`)).then(async userSessionDocument => {
            if (!userSessionDocument.exists()) {
              setDoc(doc(firestore, `users/${user.uid}/user_sessions/${sessionId}`), {
                createdAt: new Date(),
                heartbeatUpdatedAt: new Date()
              })
            }
          })
        }
      })

      getDoc(doc(firestore, 'user_access', user.uid)).then(doc => {
        if (doc.exists) {
          setUserAccess({ id: doc.id, ...doc.data() })
        }
      })

      const unsubscribe1 = onSnapshot(
        doc(firestore, 'users', user.uid),
        snapshot => {
          setUserDoc({ id: snapshot.id, ...snapshot.data() })
        }
      )

      const unsubscribe2 = onSnapshot(
        doc(firestore, 'user_access', user.uid),
        snapshot => {
          setUserAccess({ id: snapshot.id, ...snapshot.data() })
        }
      )

      const unsubscribe3 = onSnapshot(
        doc(firestore, `users/${user.uid}/user_sessions/${sessionId}`),
        snapshot => {
          setUserSessionDoc({ id: snapshot.id, ...snapshot.data() })
        }
      )

      // Clean up. Stop listening for changes
      return () => {
        unsubscribe1()
        unsubscribe2()
        unsubscribe3()
      }
    }
  }, [user])

  return (
    <FirestoreContext.Provider
      value={{ firestore, rtdb, storage, userDoc, userAccess, userSessionDoc }}
    >
      {children}
    </FirestoreContext.Provider>
  )
}

FirestoreProvider.propTypes = {
  children: PropTypes.object
}
