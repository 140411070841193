import React, { useEffect, useState } from 'react'
import firebaseApp from './firebaseConfig'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

export const AuthContext = React.createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    const unsubscribe = auth.onAuthStateChanged(u => {
      setUser(u)
      setLoading(false)

      if ((u !== null) && !localStorage.getItem('sessionId')) {
        const sessionId = uuidv4()
        console.log(`set sessionId ${sessionId}`)
        localStorage.setItem('sessionId', sessionId)
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.object
}
