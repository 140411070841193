import { useContext, useEffect, useState, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { FirestoreContext } from '../Firebase/firestoreContext'
import {
  CheckIcon,
  SelectorIcon,
  ArrowNarrowRightIcon
} from '@heroicons/react/solid'
import { collection, getDocs, orderBy, query } from '@firebase/firestore'
import DatePicker from 'react-datepicker'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import { MinusCircleIcon } from '@heroicons/react/outline'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}
// For development purposes, need to fetch accounts from user's xero login
const paymentTypes = ['PayPal', 'Gift Card', 'AfterPay', 'Shopify Payment']
const accounts = ['Account #1', 'Account #2', 'Account #3']

const stores = [
  { name: 'Sweet Sheets' },
  {
    name: 'Clean Cables'
  },
  {
    name: 'Fermented Fabrics'
  }
]

const itemCategories = ['SHIPPING', 'DISCOUNT', 'ITEM']
const itemCodes = ['ITEM001', 'ITEM002', 'ITEM003', 'ITEM004']

export default function XeroLinkingPage () {
  const { firestore, userDoc } = useContext(FirestoreContext)
  const [setEcommConnectorList] = useState([]) // list of ecommerce connectors
  const [xeroConnectorList, setXeroConnectorList] = useState([]) // list of xero connectors
  const [selectedStore, setSelectedStore] = useState({})
  const [selectedXeroConnector, setSelectedXeroConnector] = useState({
    name: ''
  })
  const [startDate, setStartDate] = useState(new Date())
  const [paymentMappingList, setPaymentMappingList] = useState([
    { paymentType: 'Default', account: '' }
  ])
  const [itemCodeMappingList, setItemCodeMappingList] = useState([
    { category: '', itemCode: '', account: '' }
  ])
  useEffect(() => {
    // Retrieve Client's stores
    if (userDoc) {
      const ecommConnectors = []

      const xeroConnectors = [{ name: 'Connector 1' }, { name: 'Connector 2' }] // for dev use hardcoded list

      const q = query(
        collection(firestore, 'users', userDoc.id, 'stores'),
        orderBy('platform')
      )
      getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().platform === 'Xero') {
            xeroConnectors.push(doc.data())
          } else {
            ecommConnectors.push(doc.data())
          }
        })
        setXeroConnectorList(xeroConnectors)
        setEcommConnectorList(ecommConnectors)
      })
    }
  }, [userDoc, firestore])

  const handleAddPaymentMappingRow = () => {
    const newPaymentMappingList = _.cloneDeep(paymentMappingList)
    newPaymentMappingList.push({ paymentType: '', account: '' })
    setPaymentMappingList(newPaymentMappingList)
  }

  const handleRemovePaymentMappingRow = index => {
    //   don't remove the first default row
    if (index > 0) {
      const newPaymentMappingList = _.cloneDeep(paymentMappingList)
      newPaymentMappingList.splice(index, 1)
      setPaymentMappingList(newPaymentMappingList)
    }
  }

  const handleAddItemCodeMappingRow = () => {
    const newItemCodeMappingList = _.cloneDeep(itemCodeMappingList)
    newItemCodeMappingList.push({ category: '', itemCode: '', account: '' })
    setItemCodeMappingList(newItemCodeMappingList)
  }

  const handleRemoveItemCodeMappingRow = index => {
    const newItemCodeMappingList = _.cloneDeep(itemCodeMappingList)
    newItemCodeMappingList.splice(index, 1)
    setItemCodeMappingList(newItemCodeMappingList)
  }

  const handlePaymentTypeMappingChange = (value, event, index) => {
    const newPaymentMappingList = _.cloneDeep(paymentMappingList)
    if (event === 'paymentType') {
      newPaymentMappingList[index].paymentType = value
    } else if (event === 'account') {
      newPaymentMappingList[index].account = value
    }
    setPaymentMappingList(newPaymentMappingList)
  }

  const handleItemCodeMappingChange = (value, event, index) => {
    const newItemMappingList = _.cloneDeep(itemCodeMappingList)

    if (event === 'category') {
      newItemMappingList[index].category = value
    } else if (event === 'itemCode') {
      newItemMappingList[index].itemCode = value
    } else if (event === 'account') {
      newItemMappingList[index].account = value
    }

    setItemCodeMappingList(newItemMappingList)
  }
  return (
    <div className='bg-gray-100'>
      {/* <Breadcrumb
        hidden={false}
        pages={[{ name: 'Xero Links Setup', href: '#', current: true }]}
      /> */}

      <div className='space-y-6 max-w-6xl container mx-auto pr-4 pb-6'>
        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Xero/Shopify Details
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Choose which Xero Connector and Shopify Store you want to link
              </p>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form className='space-y-6' action='#' method='POST'>
                <div className='mt-1 sm:w-1/2'>
                  <div>
                    <Listbox
                      value={selectedXeroConnector}
                      onChange={e => setSelectedXeroConnector(e)}
                    >
                      <Listbox.Label className='block text-sm font-medium text-gray-700'>
                        Select a Xero Connector
                      </Listbox.Label>
                      <div className='mt-1 relative'>
                        <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer sm:text-sm'>
                          <span className='block truncate'>
                            {selectedXeroConnector.name !== ''
                              ? selectedXeroConnector.name
                              : 'Select a Xero Connector'}
                          </span>
                          <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                            <SelectorIcon
                              className='h-5 w-5 text-gray-400'
                              aria-hidden='true'
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          leave='transition ease-in duration-100'
                          leaveFrom='opacity-100'
                          leaveTo='opacity-0'
                        >
                          <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                            {xeroConnectorList.map(connector => (
                              <Listbox.Option
                                key={connector.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? 'text-white bg-indigo-600'
                                      : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )}
                                value={connector}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'block truncate'
                                      )}
                                    >
                                      {connector.name}
                                    </span>

                                    {selected
                                      ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon
                                            className='h-5 w-5'
                                            aria-hidden='true'
                                          />
                                        </span>
                                        )
                                      : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>

                <div>
                  <div className='mt-1 sm:w-1/2'>
                    <div>
                      <Listbox
                        value={selectedStore}
                        onChange={e => setSelectedStore(e)}
                      >
                        <Listbox.Label className='block text-sm font-medium text-gray-700'>
                          Select a Shopify Store
                        </Listbox.Label>
                        <div className='mt-1 relative'>
                          <Listbox.Button className='bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer sm:text-sm'>
                            <span className='block truncate'>
                              {selectedStore.name
                                ? selectedStore.name
                                : 'Select a Shopify Store'}
                            </span>
                            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                              <SelectorIcon
                                className='h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                              {stores.map(store => (
                                <Listbox.Option
                                  key={store.name}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-indigo-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )}
                                  value={store}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {store.name}
                                      </span>

                                      {selected
                                        ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? 'text-white'
                                                : 'text-indigo-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon
                                              className='h-5 w-5'
                                              aria-hidden='true'
                                            />
                                          </span>
                                          )
                                        : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Shopify Settings
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Select which information you want to receive from Shopify
              </p>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form className='space-y-6' action='#' method='POST'>
                <fieldset>
                  <legend className='text-base font-medium text-gray-900'>
                    Order Financial Statuses
                  </legend>
                  <p className='text-sm text-gray-500'>
                    Select which financial statuses you want to enable.
                  </p>
                  <div className='mt-4 space-y-4'>
                    <div className='flex items-start'>
                      <div className='h-5 flex items-center'>
                        <input
                          id='authorized'
                          name='authorized'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='authorized'
                          className='font-medium text-gray-700'
                        >
                          Authorized
                        </label>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='paid'
                          name='paid'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='paid'
                          className='font-medium text-gray-700'
                        >
                          Paid
                        </label>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='partially-paid'
                          name='partially-paid'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='partially-paid'
                          className='font-medium text-gray-700'
                        >
                          Partially Paid
                        </label>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='refunded'
                          name='refunded'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='refunded'
                          className='font-medium text-gray-700'
                        >
                          Refunded
                        </label>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='partially-refunded'
                          name='partially-refunded'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='partially-refunded'
                          className='font-medium text-gray-700'
                        >
                          Partially Refunded
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className='relative pb-2'>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                </div>
                <fieldset>
                  <div>
                    <legend className='text-base font-medium text-gray-900'>
                      Order Fulfillment Statuses
                    </legend>
                    <p className='text-sm text-gray-500'>
                      Select which order statuses you want to enable.
                    </p>
                  </div>
                  <div className='mt-4 space-y-4'>
                    <div className='flex items-center'>
                      <input
                        id=''
                        name='fulfilled'
                        type='checkbox'
                        className='h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='fulfilled'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Fulfilled
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='partial'
                        name='partial'
                        type='checkbox'
                        className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='partial'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Partially Fulfilled
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='unfulfilled'
                        name='unfulfilled'
                        type='checkbox'
                        className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='unfulfilled'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Unfulfilled
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div className='relative pb-2'>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                </div>
                <fieldset>
                  <div>
                    <legend className='text-base font-medium text-gray-900'>
                      Order Sources
                    </legend>
                    <p className='text-sm text-gray-500'>
                      Select which sources you want to enable.
                    </p>
                  </div>
                  <div className='mt-4 space-y-4'>
                    <div className='flex items-center'>
                      <input
                        id=''
                        name='web'
                        type='checkbox'
                        className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='web'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Web
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='draft'
                        name='draft'
                        type='checkbox'
                        className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='draft'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Draft Orders
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='pos'
                        name='pos'
                        type='checkbox'
                        className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                      />
                      <label
                        htmlFor='pos'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        POS
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div className='relative pb-2'>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                </div>
                <div className='sm:col-span-4'>
                  <label
                    htmlFor='date'
                    className='text-base font-medium text-gray-900'
                  >
                    Exclude order transactions before
                  </label>
                  <p className='text-sm text-gray-500'>
                    Any transactions before this date will not be sent to Xero
                  </p>
                  <div className='mt-4 flex mb-4'>
                    <DatePicker
                      className='border border-gray-300 rounded-md'
                      dateFormat='dd/MM/yyyy'
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                    />
                  </div>
                </div>
                <div className='relative pb-2'>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Xero Accounts Mapping
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Map your transactions to your Xero accounts
              </p>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form className='space-y-6' action='#' method='POST'>
                <div>
                  <legend className='text-base font-medium text-gray-900'>
                    Payment type to account mapping
                  </legend>
                  <p className='text-sm text-gray-500'>
                    Choose which Xero account you want to map to each chosen
                    payment type
                  </p>
                </div>
                {paymentMappingList.map((payment, index) => {
                  return (
                    <div key={index} className='grid grid-cols-4 sm:w-full'>
                      {/* Left column */}
                      {index === 0
                        ? (
                          <div className='animate-fade-in'>
                            <Listbox>
                              <div className='mt-1 relative '>
                                <Listbox.Button className=' h-10 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default sm:text-sm bg-gray-100'>
                                  <span className='block truncate'>Default</span>
                                </Listbox.Button>
                              </div>
                            </Listbox>
                          </div>
                          )
                        : (
                          <div className='animate-fade-in'>
                            <Listbox
                              onChange={e =>
                                handlePaymentTypeMappingChange(
                                  e,
                                  'paymentType',
                                  index
                                )}
                            >
                              <div className='mt-1 relative '>
                                <Listbox.Button className=' h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                  <span className='block truncate'>
                                    {paymentMappingList[index].paymentType !== ''
                                      ? paymentMappingList[index].paymentType
                                      : 'Payment Type'}
                                  </span>
                                  <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                    <SelectorIcon
                                      className='h-5 w-5 text-gray-400'
                                      aria-hidden='true'
                                    />
                                  </span>
                                </Listbox.Button>
                                <Transition
                                  as={Fragment}
                                  leave='transition ease-in duration-100'
                                  leaveFrom='opacity-100'
                                  leaveTo='opacity-0'
                                >
                                  <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                    {paymentTypes.map(paymentType => (
                                      <Listbox.Option
                                        key={paymentType}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? 'text-white bg-indigo-600'
                                              : 'text-gray-900',
                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                          )}
                                        value={paymentType}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? 'font-semibold'
                                                  : 'font-normal',
                                                'block truncate'
                                              )}
                                            >
                                              {paymentType}
                                            </span>

                                            {selected
                                              ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? 'text-white'
                                                      : 'text-indigo-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className='h-5 w-5'
                                                    aria-hidden='true'
                                                  />
                                                </span>
                                                )
                                              : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </Listbox>
                          </div>
                          )}
                      {/* Middle column */}
                      <div className='h-8 flex justify-center items-center mt-3'>
                        <ArrowNarrowRightIcon className='h-full text-gray-700 animate-fade-in' />
                      </div>
                      {/* Right column */}
                      <div className='animate-fade-in'>
                        <Listbox
                          onChange={e =>
                            handlePaymentTypeMappingChange(e, 'account', index)}
                        >
                          <div className='mt-1 relative '>
                            <Listbox.Button className=' h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                              <span className='block truncate'>
                                {paymentMappingList[index].account !== ''
                                  ? paymentMappingList[index].account
                                  : 'Xero Accounts'}
                              </span>
                              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon
                                  className='h-5 w-5 text-gray-400'
                                  aria-hidden='true'
                                />
                              </span>
                            </Listbox.Button>
                            <Transition
                              as={Fragment}
                              leave='transition ease-in duration-100'
                              leaveFrom='opacity-100'
                              leaveTo='opacity-0'
                            >
                              <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                {accounts.map(account => (
                                  <Listbox.Option
                                    key={account}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? 'text-white bg-indigo-600'
                                          : 'text-gray-900',
                                        'cursor-default select-none relative py-2 pl-3 pr-9'
                                      )}
                                    value={account}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected
                                              ? 'font-semibold'
                                              : 'font-normal',
                                            'block truncate'
                                          )}
                                        >
                                          {account}
                                        </span>

                                        {selected
                                          ? (
                                            <span
                                              className={classNames(
                                                active
                                                  ? 'text-white'
                                                  : 'text-indigo-600',
                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                              )}
                                            >
                                              <CheckIcon
                                                className='h-5 w-5'
                                                aria-hidden='true'
                                              />
                                            </span>
                                            )
                                          : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                      {index !== 0 && (
                        <div className='flex justify-start items-center pl-5'>
                          <button
                            type='button'
                            onClick={() => handleRemovePaymentMappingRow(index)}
                          >
                            <MinusCircleIcon className='text-red-400 h-6 hover:opacity-70 stroke-2' />
                          </button>
                        </div>
                      )}
                    </div>
                  )
                })}
                <div className='pt-5 pb-4 animate-fade-in sm:w-3/4'>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      onClick={() => handleAddPaymentMappingRow()}
                      className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none '
                    >
                      Add New Payment Mapping
                    </button>
                  </div>
                </div>
              </form>
              <div className='relative pb-2'>
                <div
                  className='absolute inset-0 flex items-center'
                  aria-hidden='true'
                >
                  <div className='w-full border-t border-gray-300' />
                </div>
              </div>
              <div className='mt-5 md:mt-6 md:col-span-4 '>
                <form className='space-y-6' action='#' method='POST'>
                  <div>
                    <legend className='text-base font-medium text-gray-900'>
                      Xero Item Code Mappings
                    </legend>
                    <p className='text-sm text-gray-500'>
                      Select which Xero Account you want to map to for each
                      chosen Xero Item
                    </p>
                  </div>

                  {itemCodeMappingList.map((item, index) => {
                    return (
                      <div
                        key={item}
                        className='grid grid-cols-4 sm:w-full space-x-3'
                      >
                        {/* Left column */}
                        <div className='animate-fade-in'>
                          <Listbox
                            onChange={e =>
                              handleItemCodeMappingChange(e, 'category', index)}
                            value={itemCodeMappingList[index].category}
                          >
                            <div className='mt-1 relative '>
                              <Listbox.Button className=' h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                <span className='block truncate'>
                                  {itemCodeMappingList[index].category !== ''
                                    ? itemCodeMappingList[index].category
                                    : 'Category'}
                                </span>
                                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                  <SelectorIcon
                                    className='h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                  {itemCategories.map(category => {
                                    if (
                                      category === 'SHIPPING' &&
                                      itemCodeMappingList.some(
                                        item => item.category === 'SHIPPING'
                                      )
                                    ) {
                                      // todo
                                      console.log('TODO')
                                    } else {
                                      return (
                                        <Listbox.Option
                                          key={category}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? 'text-white bg-indigo-600'
                                                : 'text-gray-900',
                                              'cursor-default select-none relative py-2 pl-3 pr-9'
                                            )}
                                          value={category}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? 'font-semibold'
                                                    : 'font-normal',
                                                  'block truncate'
                                                )}
                                              >
                                                {category}
                                              </span>

                                              {selected
                                                ? (
                                                  <span
                                                    className={classNames(
                                                      active
                                                        ? 'text-white'
                                                        : 'text-indigo-600',
                                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                                    )}
                                                  >
                                                    <CheckIcon
                                                      className='h-5 w-5'
                                                      aria-hidden='true'
                                                    />
                                                  </span>
                                                  )
                                                : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    }
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        {/* Middle Column */}
                        <div className='animate-fade-in'>
                          <Listbox
                            onChange={e =>
                              handleItemCodeMappingChange(e, 'itemCode', index)}
                          >
                            <div className='mt-1 relative '>
                              <Listbox.Button className=' h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                <span className='block truncate'>
                                  {itemCodeMappingList[index].itemCode !== ''
                                    ? itemCodeMappingList[index].itemCode
                                    : 'Xero Item Code'}
                                </span>
                                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                  <SelectorIcon
                                    className='h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                  {itemCodes.map(itemCode => (
                                    <Listbox.Option
                                      key={itemCode}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? 'text-white bg-indigo-600'
                                            : 'text-gray-900',
                                          'cursor-default select-none relative py-2 pl-3 pr-9'
                                        )}
                                      value={itemCode}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? 'font-semibold'
                                                : 'font-normal',
                                              'block truncate'
                                            )}
                                          >
                                            {itemCode}
                                          </span>
                                          {selected
                                            ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? 'text-white'
                                                    : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon
                                                  className='h-5 w-5'
                                                  aria-hidden='true'
                                                />
                                              </span>
                                              )
                                            : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        {/* Right column */}
                        <div className='animate-fade-in'>
                          <Listbox
                            onChange={e =>
                              handleItemCodeMappingChange(e, 'account', index)}
                          >
                            <div className='mt-1 relative '>
                              <Listbox.Button className=' h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                                <span className='block truncate'>
                                  {itemCodeMappingList[index].account !== ''
                                    ? itemCodeMappingList[index].account
                                    : 'Xero Accounts'}
                                </span>
                                <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                  <SelectorIcon
                                    className='h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave='transition ease-in duration-100'
                                leaveFrom='opacity-100'
                                leaveTo='opacity-0'
                              >
                                <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                                  {accounts.map(account => (
                                    <Listbox.Option
                                      key={account}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? 'text-white bg-indigo-600'
                                            : 'text-gray-900',
                                          'cursor-default select-none relative py-2 pl-3 pr-9'
                                        )}
                                      value={account}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={classNames(
                                              selected
                                                ? 'font-semibold'
                                                : 'font-normal',
                                              'block truncate'
                                            )}
                                          >
                                            {account}
                                          </span>

                                          {selected
                                            ? (
                                              <span
                                                className={classNames(
                                                  active
                                                    ? 'text-white'
                                                    : 'text-indigo-600',
                                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                                )}
                                              >
                                                <CheckIcon
                                                  className='h-5 w-5'
                                                  aria-hidden='true'
                                                />
                                              </span>
                                              )
                                            : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        <div className='flex justify-start items-center pl-5'>
                          <button
                            type='button'
                            onClick={() =>
                              handleRemoveItemCodeMappingRow(index)}
                          >
                            <MinusCircleIcon className='text-red-400 h-6 hover:opacity-70 stroke-2' />
                          </button>
                        </div>
                      </div>
                    )
                  })}
                  <div className='pt-5 pb-4 animate-fade-in sm:w-3/4'>
                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={() => handleAddItemCodeMappingRow()}
                        className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none '
                      >
                        Add New Item Mapping
                      </button>
                    </div>
                  </div>
                </form>
                <div className='relative pb-2'>
                  <div
                    className='absolute inset-0 flex items-center'
                    aria-hidden='true'
                  >
                    <div className='w-full border-t border-gray-300' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-3 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Notifications
              </h3>
              <p className='mt-1 text-sm text-gray-500'>
                Decide which communications you`&apos;`d like to receive and
                how.
              </p>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <form className='space-y-6' action='#' method='POST'>
                <fieldset>
                  <legend className='text-base font-medium text-gray-900' />
                  <div className='mt-4 space-y-4'>
                    <div className='flex items-start'>
                      <div className='h-5 flex items-center'>
                        <input
                          id='comments'
                          name='comments'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='comments'
                          className='font-medium text-gray-700'
                        >
                          Comments
                        </label>
                        <p className='text-gray-500'>
                          Get notified when someones posts a comment on a
                          posting.
                        </p>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='candidates'
                          name='candidates'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='candidates'
                          className='font-medium text-gray-700'
                        >
                          Candidates
                        </label>
                        <p className='text-gray-500'>
                          Get notified when a candidate applies for a job.
                        </p>
                      </div>
                    </div>
                    <div className='flex items-start'>
                      <div className='flex items-center h-5'>
                        <input
                          id='offers'
                          name='offers'
                          type='checkbox'
                          className=' h-4 w-4 text-indigo-600 border-gray-300 rounded'
                        />
                      </div>
                      <div className='ml-3 text-sm'>
                        <label
                          htmlFor='offers'
                          className='font-medium text-gray-700'
                        >
                          Offers
                        </label>
                        <p className='text-gray-500'>
                          Get notified when a candidate accepts or rejects an
                          offer.
                        </p>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <div>
                    <legend className='text-base font-medium text-gray-900'>
                      Payment type to account mapping
                    </legend>
                    <p className='text-sm text-gray-500'>
                      Choose which Xero account you want to map to each chosen
                      payment type
                    </p>
                  </div>
                  <div className='mt-4 space-y-4'>
                    <div className='flex items-center'>
                      <input
                        id='push-everything'
                        name='push-notifications'
                        type='radio'
                        className=' h-4 w-4 text-indigo-600 border-gray-300'
                      />
                      <label
                        htmlFor='push-everything'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Everything
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='push-email'
                        name='push-notifications'
                        type='radio'
                        className=' h-4 w-4 text-indigo-600 border-gray-300'
                      />
                      <label
                        htmlFor='push-email'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        Same as email
                      </label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='push-nothing'
                        name='push-notifications'
                        type='radio'
                        className=' h-4 w-4 text-indigo-600 border-gray-300'
                      />
                      <label
                        htmlFor='push-nothing'
                        className='ml-3 block text-sm font-medium text-gray-700'
                      >
                        No push notifications
                      </label>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>

        <div className='flex justify-end'>
          <button
            type='button'
            className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 '
          >
            Cancel
          </button>
          <button
            type='submit'
            className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 '
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}

XeroLinkingPage.propTypes = {
  onFinish: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  updateCurrentStep: PropTypes.func.isRequired
}
