import { Fragment, useContext, useEffect, useState } from 'react'
import { Col, Row, Button } from 'antd'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Navigate } from 'react-router-dom'
import { FirestoreContext } from '../Firebase/firestoreContext'
import ShopifyOrderDetails from './shopifyOrderDetails.js'
import Breadcrumb from '../Breadcrumb'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { DateTime } from 'luxon'
import { child, get, ref } from 'firebase/database'
import { doc, getDoc } from 'firebase/firestore'
import { AuthContext } from '../Firebase/firebaseContext'
import { GOOGLE_CLOUD_PROJECT_ID } from '../../constants/constants'
import Text from 'antd/es/typography/Text'

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

let lastDynamoDbQuery = {}
let latestOrderDateTimeIsSet = false
let totalRows = 0
let dataCache = []
let loadDataFromStart = true
let isoEarliestOrderDateTime = ''

const OrderStatus = () => {
  const { user } = useContext(AuthContext)
  const { firestore, rtdb, userDoc } = useContext(FirestoreContext)
  const [gridApi, setGridApi] = useState(null)
  const [, setGridColumnApi] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [storeData, setStoreData] = useState()
  const [selectedStore, setSelectedStore] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [stateTotalRows, setStateTotalRows] = useState(0)
  const [latestOrderDateTime, setLatestOrderDateTime] = useState('')
  const [earliestOrderDateTime, setEarliestOrderDateTime] = useState('')
  const [orderDetailsDrawerVisible, setOrderDetailsDrawerVisible] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (rtdb && userDoc) {
        // Get list of all stores related to this tenant/client

        // 1. Get all tenants that user has read access to
        const tenants = []

        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/tenants/read`)
        ).then(tenantSnapshot => {
          if (tenantSnapshot.exists()) {
            tenantSnapshot.forEach(doc => {
              if (doc.val() === true) {
                tenants.push(doc.key)
              }
            })
          }
        })

        // 2. Get all clients that user has read access to
        const clients = []
        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/clients/read`)
        ).then(clientSnapshot => {
          if (clientSnapshot.exists()) {
            clientSnapshot.forEach(doc => {
              if (doc.val() === true) {
                clients.push(doc.key)
              }
            })
          }
        })

        // 3. Get all stores that user has read access to
        const stores = []
        await get(
          child(ref(rtdb), `user_access/${userDoc.id}/stores/read`)
        ).then(storeSnapshot => {
          if (storeSnapshot.exists()) {
            storeSnapshot.forEach(doc => {
              if (doc.val() === true) {
                stores.push(doc.key)
              }
            })
          }
        })

        // 4. Iterate through all tenants -> clients -> stores
        const storeData = []
        await Promise.all(
          tenants.map(async tenant => {
            await get(child(ref(rtdb), `tenants/${tenant}/clients`)).then(
              clientList => {
                const clientListDoc = clientList.val()
                Promise.all(
                  Object.keys(clientListDoc).map(client => {
                    const clientDoc = clientList.val()[client]
                    // check if client in clients
                    if (clients.includes(client)) {
                      if (clientDoc.stores) {
                        Promise.all(
                          Object.keys(clientDoc.stores).map(store => {
                            // check if store in stores
                            if (stores.includes(store)) {
                              const newStoreObj = {}
                              newStoreObj.tenantId = tenant
                              newStoreObj.clientId = client
                              newStoreObj.storeId = store
                              newStoreObj.name = clientDoc.stores[store].name
                              storeData.push(newStoreObj)
                            }
                            return null
                          })
                        )
                      }
                    }
                    return null
                  })
                )
              }
            )
          })
        ).then(() => {
          storeData.sort((a, b) => (a.name > b.name ? 1 : -1)) // sort alphabetically
          setStoreData(storeData)
        })
      }
    }
    fetchData()
  }, [rtdb, userDoc])

  useEffect( () => {
    const doAsyncStuff = async () => {
      if (selectedStore) {
        // Reset totalRows whenever the store changed.
        lastDynamoDbQuery = {}
        latestOrderDateTimeIsSet = false
        totalRows = 0
        dataCache = []
        loadDataFromStart = true
        isoEarliestOrderDateTime = ''
        setStateTotalRows(totalRows)
        setLatestOrderDateTime('')
        setEarliestOrderDateTime('')

        gridApi.showLoadingOverlay()

        const idToken = await user.getIdToken()
        const currentDateTime = DateTime.now().toISO()

        const dataSource = {
          rowCount: null,
          getRows: function (params) {
            let httpMethod = 'GET'
            let url = `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/order/getOrdersByDateFrom/${selectedStore.tenantId}/${selectedStore.clientId}/${selectedStore.storeId}/${currentDateTime}/false/`
            let body = null
            if ((totalRows > 0) && lastDynamoDbQuery.LastEvaluatedKey) {
              httpMethod = 'POST'
              url = `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/order/getNextOrdersByDateFrom/${selectedStore.tenantId}/${selectedStore.clientId}/${selectedStore.storeId}/${currentDateTime}/false/`
              body = JSON.stringify(lastDynamoDbQuery)
            }

            const fetchParams = {
              method: httpMethod,
              headers: { Authorization: 'Bearer ' + idToken, 'Content-Type': 'application/json' }
            }

            if (httpMethod == 'POST' && body) {
              fetchParams['body'] = body
            }
            window
              .fetch(url, fetchParams)
              .then(res => res.json())
              .then(json => {
                lastDynamoDbQuery = {
                  requestParams: json.result.requestParams,
                  LastEvaluatedKey: json.result.response.LastEvaluatedKey
                }

                if (json.success) {
                  totalRows += json.result.response.Items.length
                  setStateTotalRows(totalRows)

                  const temp = []
                  json.result.response.Items.forEach(item => {
                    if (!latestOrderDateTimeIsSet) {
                      setLatestOrderDateTime(DateTime.fromISO(item.createdAt.S)
                        .setZone('Australia/Sydney')
                        .toFormat('d-MMM-yyyy h:mm:ss a'))
                      latestOrderDateTimeIsSet = true
                    }

                    isoEarliestOrderDateTime = item.createdAt.S
                    setEarliestOrderDateTime(DateTime.fromISO(item.createdAt.S)
                      .setZone('Australia/Sydney')
                      .toFormat('d-MMM-yyyy h:mm:ss a'))

                    const data = {
                      id: item.firestoreDocId.S,
                      storeId: selectedStore.storeId,
                      clientId: selectedStore.clientId,
                      tenantId: selectedStore.tenantId,
                      buyer: item.buyer.S,
                      orderId: item.orderName.S,
                      orderedAt: DateTime.fromISO(item.createdAt.S)
                        .setZone('Australia/Sydney')
                        .toFormat('d-MMM-yyyy h:mm:ss a'),
                      paymentStatus: item.payment.S,
                      fulfillmentStatus: item.fulfillment.S,
                      amount: `$${item.amount.N}`
                    }
                    temp.push(data)
                    dataCache.push(data)
                  })
                  let lastRow = -1
                  if (params.endRow > totalRows) {
                    lastRow = totalRows
                  }

                  gridApi.hideOverlay()
                  params.successCallback(temp, lastRow)
                }
              })
          }
        }
        gridApi.setDatasource(dataSource)
      }
    }
    doAsyncStuff()
  }, [selectedStore])

  useEffect(() => {
    const doAsyncStuff = async () => {
      /*
     * Update the agGrid datasource when the search text changes
     */
      if (gridApi) {
        if (searchText.trim() !== '') {
          loadDataFromStart = true
          const dataSource = {
            rowCount: null,
            getRows: function (params) {
              const dataAfterFiltering = filterAggridData(
                dataCache
              )
              params.successCallback(dataAfterFiltering, dataAfterFiltering.length)
            }
          }
          gridApi.setDatasource(dataSource)
        } else {
          const idToken = await user.getIdToken()
          const currentDateTime = DateTime.now().toISO()
          const dataSource = {
            rowCount: null,
            getRows: function (params) {
              let httpMethod = 'GET'
              let url = `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/order/getOrdersByDateFrom/${selectedStore.tenantId}/${selectedStore.clientId}/${selectedStore.storeId}/${currentDateTime}/false/`
              let body = null
              if (!loadDataFromStart && (dataCache.length > 0) && lastDynamoDbQuery.LastEvaluatedKey) {
                httpMethod = 'POST'
                url = `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/order/getNextOrdersByDateFrom/${selectedStore.tenantId}/${selectedStore.clientId}/${selectedStore.storeId}/${currentDateTime}/false/`
                body = JSON.stringify(lastDynamoDbQuery)
              }

              const fetchParams = {
                method: httpMethod,
                headers: { Authorization: 'Bearer ' + idToken, 'Content-Type': 'application/json' }
              }

              if (httpMethod == 'POST' && body) {
                fetchParams['body'] = body
              }

              window
                .fetch(url, fetchParams)
                .then(res => res.json())
                .then(json => {
                  loadDataFromStart = false
                  lastDynamoDbQuery = {
                    requestParams: json.result.requestParams,
                    LastEvaluatedKey: json.result.response.LastEvaluatedKey
                  }

                  if (json.success) {
                    const temp = []
                    json.result.response.Items.forEach(item => {
                      if (!latestOrderDateTimeIsSet) {
                        setLatestOrderDateTime(DateTime.fromISO(item.createdAt.S)
                          .setZone('Australia/Sydney')
                          .toFormat('d-MMM-yyyy h:mm:ss a'))
                        latestOrderDateTimeIsSet = true
                      }

                      if (isoEarliestOrderDateTime > item.createdAt.S) {
                        console.log(`new isoEarliestOrderDateTime = ${item.createdAt.S}`)
                        isoEarliestOrderDateTime = item.createdAt.S
                        setEarliestOrderDateTime(DateTime.fromISO(item.createdAt.S)
                          .setZone('Australia/Sydney')
                          .toFormat('d-MMM-yyyy h:mm:ss a'))
                      } else {
                        console.log(`isoEarliestOrderDateTime = ${isoEarliestOrderDateTime}`)
                      }

                      const data = {
                        id: item.firestoreDocId.S,
                        storeId: selectedStore.storeId,
                        clientId: selectedStore.clientId,
                        tenantId: selectedStore.tenantId,
                        buyer: item.buyer.S,
                        orderId: item.orderName.S,
                        orderedAt: DateTime.fromISO(item.createdAt.S)
                          .setZone('Australia/Sydney')
                          .toFormat('d-MMM-yyyy h:mm:ss a'),
                        paymentStatus: item.payment.S,
                        fulfillmentStatus: item.fulfillment.S,
                        amount: `$${item.amount.N}`
                      }

                      temp.push(data)
                      if (!dataCache.some(x => x.orderId === data.orderId)) {
                        totalRows += 1
                        setStateTotalRows(totalRows)
                        dataCache.push(data)
                      }
                    })
                    let lastRow = -1
                    if (params.endRow > dataCache.length) {
                      // lastRow = data.length
                      lastRow = totalRows
                    }
                    gridApi.hideOverlay()
                    params.successCallback(temp, lastRow)
                  }
                })
            }
          }

          gridApi.setDatasource(dataSource)
          gridApi.setRowCount(dataCache.length, false)
        }

      }
    }
    doAsyncStuff()
  }, [searchText])

  const onGridReady = async params => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
    params.api.sizeColumnsToFit()
  }

  function filterAggridData (data) {
    if (searchText.trim() === '') {
      return data
    }
    let resultOfFilter = []
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      if (item.orderId.includes(searchText) || item.buyer.includes(searchText)) {
        resultOfFilter.push(item)
      }
    }
    return resultOfFilter
  }

  const handleRowSelected = async event => {
    //   Only check the node that is selected, not the node that was deselected
    if (event.node.isSelected()) {
      console.log(`Retrieving order ${event.node.data.orderId} for store id ${event.node.data.storeId}...`)
      // Get the order from firestore
      setOrderDetailsDrawerVisible(true)

      getDoc(
        doc(
          firestore,
          'tenants',
          event.node.data.tenantId,
          'clients',
          event.node.data.clientId,
          'stores',
          event.node.data.storeId,
          'orders',
          event.node.data.id
        )
      ).then(doc => {
        setSelectedOrder({
          tenantId: event.node.data.tenantId,
          clientId: event.node.data.clientId,
          storeId: event.node.data.storeId,
          ...doc.data()
        })
      })
    }
  }

  const handleSearchTextChanged = (e) => {
    setSearchText(e.target.value)
  }

  const handleLoadMore = (e) => {
    console.log(`dataCache.length = ${dataCache.length}`)
    console.log(`Loading ${dataCache.length + 450} ...`)
    gridApi.setRowCount(dataCache.length + 4500, false)
    gridApi.ensureIndexVisible(dataCache.length + 450)
  }

  const onDrawerClose = () => {
    setOrderDetailsDrawerVisible(false)
  }

  return (
    <>
      {userDoc
        ? (
          <>
            <Breadcrumb
              pages={[{ name: 'Orders', href: '#', current: true }]}
              hidden={false}
            />
            <div style={{ padding: 24, minHeight: 360 }}>
              {storeData
                ? (
                  <Listbox value={selectedStore} onChange={setSelectedStore}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                          Stores
                        </Listbox.Label>
                        <div className="mt-1 relative max-w-sm mb-4">
                          <Listbox.Button
                            className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedStore && selectedStore.name
                                ? selectedStore.name
                                : 'Choose a store'}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options
                              static
                              className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                              {storeData.map(store => (
                                <Listbox.Option
                                  key={store.name}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-indigo-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )}
                                  value={store}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {store.name}
                                      </span>

                                      {selected
                                        ? (
                                          <span
                                            className={classNames(
                                              active
                                                ? 'text-white'
                                                : 'text-indigo-600',
                                              'absolute inset-y-0 right-0 flex items-center pr-4'
                                            )}
                                          >
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )
                                        : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                )
                : (
                  <Listbox value={selectedStore} onChange={setSelectedStore}>
                    {() => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                          Stores
                        </Listbox.Label>
                        <div className="mt-1 relative max-w-sm mb-4">
                          <Listbox.Button
                            className=" disabled bg-white relative w-full border border-gray-300 bg-gray-50 rounded-md shadow-sm pl-3 pr-10 py-2 text-gray-400 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <span className="block truncate">
                              Loading Stores...
                            </span>
                          </Listbox.Button>
                        </div>
                      </>
                    )}
                  </Listbox>
                )}

              {totalRows > 0 ?
                <Row justify="space-between" style={{ paddingBottom: 10 }}>
                  <Col span={16}>
                    <Text strong>Orders loaded: </Text><Text>{totalRows.toLocaleString()}</Text> &nbsp; <Text strong>Date
                    range: </Text> <Text>{latestOrderDateTime} to {earliestOrderDateTime}</Text> &nbsp; <Button type="primary" size={'small'} onClick={handleLoadMore}>Load more</Button>
                  </Col>
                  <Col span={8}>
                    <div>
                      <input
                        type="text"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        onChange={handleSearchTextChanged}
                        placeholder="Search"
                      />
                    </div>
                  </Col>
                </Row> : ''}
              <Row>
                <div className="ag-theme-alpine w-screen sm:mx-0 min-h-2/3vh">
                  <AgGridReact
                    components={{
                      loadingRenderer1: function (params) {
                        if (params.value !== undefined) {
                          return params.value
                        } else {
                          return '<img src="https://www.ag-grid.com/example-assets/loading.gif">'
                        }
                      },
                      loadingRenderer2: function (params) {
                        if (params.value !== undefined) {
                          return params.value
                        } else {
                          return 'Loading data...'
                        }
                      },
                    }}
                    defaultColDef={{ resizable: true }}
                    rowSelection="single"
                    onRowClicked={handleRowSelected}
                    onGridReady={onGridReady}
                    rowModelType={'infinite'}
                    cacheBlockSize={500}
                    cacheOverflowSize={100}
                  >
                    <AgGridColumn field="orderId" flex={1} suppressMenu={true} cellRenderer="loadingRenderer1"/>
                    <AgGridColumn field="buyer" flex={1} suppressMenu={true} cellRenderer="loadingRenderer2"/>
                    <AgGridColumn field="amount" flex={1} suppressMenu={true}/>
                    <AgGridColumn field="paymentStatus" flex={1} suppressMenu={true}/>
                    <AgGridColumn field="fulfillmentStatus" flex={1} suppressMenu={true}/>
                    <AgGridColumn field="orderedAt" flex={2} suppressMenu={true}/>
                  </AgGridReact>
                </div>
              </Row>
            </div>
          </>
        )
        : (
          <>{userDoc && <Navigate to='/' />}</>
        )}

      <ShopifyOrderDetails
        onDrawerClose={onDrawerClose}
        orderDetailsDrawerVisible={orderDetailsDrawerVisible}
        selectedOrder={selectedOrder}
      />
    </>
  )
}

export default OrderStatus
