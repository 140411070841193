import { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FirestoreContext } from '../Firebase/firestoreContext'

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CaretRightOutlined,
  EyeInvisibleOutlined,
  LoadingOutlined,
  EyeTwoTone
} from '@ant-design/icons'
import { Popconfirm, Collapse, Tag, Input, DatePicker, Switch } from 'antd'
import { AuthContext } from '../Firebase/firebaseContext'
import { doc, getDoc } from '@firebase/firestore'
import moment from 'moment'
import _ from 'lodash'
import 'react-datepicker/dist/react-datepicker.css'
import { GOOGLE_CLOUD_PROJECT_ID } from '../../constants/constants'

const { Panel } = Collapse
const dateFormat = 'D-MMM-YYYY h:mm:ss a'

export default function ClientStoreMagento (props) {
  const { firestore } = useContext(FirestoreContext)
  const {
    tenant,
    client,
    store: propStore,
    editModeEnabled,
    onSave,
    onCancel,
    setDetailsChanged,
    scratchStore,
    setScratchStore
  } = props
  const { user } = useContext(AuthContext)
  const [storeWebhooks, setStoreWebhooks] = useState([])
  const [, setStore] = useState({})
  const [accessTokenKey, setAccessTokenKey] = useState({ changed: false })
  const [accessTokenSecretKey, setAccessTokenSecretKey] = useState({
    changed: false
  })
  const [consumerSecretKey, setConsumerSecretKey] = useState({ changed: false })
  const [ftpPassword, setFtpPassword] = useState({ changed: false })
  const [waitingForResponse, setWaitingForResponse] = useState(false)
  const [
    excludeOrdersBeforeDateString,
    setExcludeOrdersBeforeDateString
  ] = useState()
  const [filters, setFilters] = useState([])

  const [unchangedScratchStore, setUnchangedScratchStore] = useState()
  const [unchangedFilters, setUnchangedFilters] = useState()

  useEffect(() => {}, [])
  useEffect(() => {
    getDoc(
      doc(
        firestore,
        'tenants',
        tenant.id,
        'clients',
        client.id,
        'stores',
        propStore.id
      )
    ).then(docSnapshot => {
      if (docSnapshot.exists()) {
        setStore({ id: docSnapshot.id, ...docSnapshot.data() })
        if (
          docSnapshot.data().fulfillment &&
          docSnapshot.data().fulfillment.excludeOrdersBefore
        ) {
          setExcludeOrdersBeforeDateString(
            moment(
              docSnapshot.data().fulfillment.excludeOrdersBefore.toDate()
            ).format(dateFormat)
          )
        }

        // Set scratchstore data, need to deep merge
        const data1 = _.cloneDeep(docSnapshot.data())
        setScratchStore({ id: docSnapshot.id, ...data1 })
        const data2 = _.cloneDeep(docSnapshot.data())
        setUnchangedScratchStore({
          id: docSnapshot.id,
          ...data2
        })

        // Set filters
        if (docSnapshot.data().fulfillment) {
          if (docSnapshot.data().fulfillment.excludeOrderFiltersV2) {
            setFilters(
              _.cloneDeep(docSnapshot.data().fulfillment.excludeOrderFiltersV2)
            )
            setUnchangedFilters(
              _.cloneDeep(docSnapshot.data().fulfillment.excludeOrderFiltersV2)
            )
          }
        }
      }
    })
  }, [propStore])

  useEffect(() => {
    // TODO: uncomment for production, will throw a CORS error when launching server on localhost
    if (!_.isEmpty(scratchStore) && !editModeEnabled) {
      retrieveSecretFromDynamoDB(
        'stores',
        scratchStore.id,
        scratchStore.accessTokenKey
      ).then(credential => {
        setAccessTokenKey({ ...accessTokenKey, value: credential })
      })
      retrieveSecretFromDynamoDB(
        'stores',
        scratchStore.id,
        scratchStore.accessTokenSecretKey
      ).then(credential => {
        setAccessTokenSecretKey({ ...accessTokenSecretKey, value: credential })
      })
      retrieveSecretFromDynamoDB(
        'stores',
        scratchStore.id,
        scratchStore.consumerSecretKey
      ).then(credential => {
        setConsumerSecretKey({ ...consumerSecretKey, value: credential })
      })

      //   if (scratchStore.webhookSecretKey !== undefined) {
      //     retrieveSecretFromDynamoDB(
      //       'stores',
      //       scratchStore.id,
      //       scratchStore.webhookSecretKey
      //     ).then(credential => {
      //       setWebhookSecret({ ...webhookSecret, value: credential })
      //     })
      //   } else {
      //     setWebhookSecret({ ...webhookSecret, value: 'N/A' })
      //   }
    }
  }, [scratchStore])

  const retrieveSecretFromDynamoDB = async (type, typeId, secretKey) => {
    if (type && typeId && secretKey) {
      const idToken = await user.getIdToken()
      const rc = await window
        .fetch(
          `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/protected/manage-secret/${type}/${typeId}/${secretKey}`,
          {
            headers: { Authorization: 'Bearer ' + idToken }
          }
        )
        .then(res => res.json())
        .then(json => {
          if (json.success) {
            return json.item.credential.S
          } else {
            return ''
          }
        })
      return rc
    } else {
      return ''
    }
  }

  const showPopconfirm = webhook => {
    if (webhook.created) {
      // show delete webhook popupconform
      const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
      let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

      const tempWebhooks = [...storeWebhooks]
      if (element) {
        element = {
          ...element,
          popconfirmText: `Are you sure you want to delete the webhook '${webhook.topic}'?`,
          popconfirmVisible: true,
          icon: <CloseCircleTwoTone twoToneColor='#eb2f96' />
        }
        tempWebhooks.splice(index, 1, element)
        setStoreWebhooks(tempWebhooks)
      }
    } else {
      // show create webhook popupconform
      const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
      let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

      const tempWebhooks = [...storeWebhooks]
      if (element) {
        element = {
          ...element,
          popconfirmText: `Are you sure you want to create the webhook '${webhook.topic}'?`,
          popconfirmVisible: true,
          icon: <CheckCircleTwoTone twoToneColor='#52c41a' />
        }
        tempWebhooks.splice(index, 1, element)
        setStoreWebhooks(tempWebhooks)
      }
    }
  }

  const handleWebhookOk = webhook => {
    const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
    let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

    const tempWebhooks = [...storeWebhooks]
    if (element) {
      element = {
        ...element,
        popconfirmText: '',
        popconfirmVisible: false
      }
      tempWebhooks.splice(index, 1, element)
      setStoreWebhooks(tempWebhooks)
    }
  }

  const handleWebhookCancel = webhook => {
    const index = _.findIndex(storeWebhooks, { topic: webhook.topic })
    let element = storeWebhooks.find(({ topic }) => topic === webhook.topic)

    const tempWebhooks = [...storeWebhooks]
    if (element) {
      element = {
        ...element,
        popconfirmText: '',
        popconfirmVisible: false
      }
      tempWebhooks.splice(index, 1, element)
      setStoreWebhooks(tempWebhooks)
    }
  }

  const handleCancel = () => {
    // Call the parent's handleCancel() function to cancel any changes made.
    setScratchStore(_.cloneDeep(unchangedScratchStore))
    setFilters(_.cloneDeep(unchangedFilters))
    setDetailsChanged(false)
    onCancel()
  }

  const handleSave = () => {
    // Check if any filters are empty
    setWaitingForResponse(true)
    const filteredFilters = []
    Promise.all(
      filters.map(filter => {
        if (filter.value) {
          const obj = {}
          obj[filter.key] = filter.value
          filteredFilters.push(obj)
        }
        return true
      })
    ).then(() => {
      scratchStore.fulfillment.excludeOrderFiltersV2 = filteredFilters
      setUnchangedScratchStore(_.cloneDeep(scratchStore))
      setUnchangedFilters(_.cloneDeep(filteredFilters))
      setFilters(_.cloneDeep(filteredFilters))
      // Call the parent's handleSave() method. You can pass back an object that contains the values to be saved.
      // Update firestore document
      onSave(scratchStore).then(() => {
        setWaitingForResponse(false)
      })
    })
    setDetailsChanged(false)
  }

  const handleStoreEnabledChange = async checked => {
    setScratchStore({ ...scratchStore, enabled: checked })
    setDetailsChanged(true)
  }

  const handleFtpPasswordChange = e => {
    setFtpPassword({
      changed: true,
      value: e.target.value
    })
    setDetailsChanged(true)
  }

  const handleNameChanged = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.name = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleAPIKeyChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.apiKey = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleApiPathChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.apiPath = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleOrderPrefixChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.orderPrefix = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpOutputFilePrefixChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.ftpOutputFilePrefix = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpHostChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.host = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleFtpUsernameChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.username = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleInboxLocationChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.inboxLocation = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleOutboxLocationChange = e => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.ftp.outboxLocation = e.target.value
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleEnableFulfillmentChange = checked => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.fulfillment.enabled = checked
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleSyncInventoryLevelChange = checked => {
    const newScratchStore = { ...scratchStore }
    newScratchStore.syncInventoryLevel = checked
    setScratchStore(newScratchStore)
    setDetailsChanged(true)
  }

  const handleExcludeOrdersBeforeDateChange = (date, dateString) => {
    // const newScratchStore = { ...scratchStore }
    // newScratchStore.fulfillment.excludeOrdersBeforeDateString = date
    // setExcludeOrdersBeforeDateString(dateString)
  }

  return (
    <div className='bg-gray-100'>
      {/* <Breadcrumb
        hidden={false}
        pages={[{ name: 'Xero Links Setup', href: '#', current: true }]}
      /> */}

      <div className='space-y-6 max-w-6xl container mx-auto pr-4 pb-6'>
        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-4 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                API Details
              </h3>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-3'>
              <table style={{ marginTop: 10 }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Store ID :
                    </td>
                    <td style={{ paddingLeft: 5, width: 600 }}>
                      <Tag color='green' key={scratchStore.id}>
                        {scratchStore.id}
                      </Tag>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Enabled :
                    </td>
                    <td style={{ paddingLeft: 5 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        checked={scratchStore.enabled}
                        onChange={handleStoreEnabledChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Platform :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Tag color='green' key={scratchStore.platform}>
                        {scratchStore.platform}
                      </Tag>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Store :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={scratchStore.name}
                        onChange={handleNameChanged}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Access Token Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input.Password
                        styles={{ borderRadius: '6px', borderColour: 'black' }}
                        bordered
                        value={accessTokenKey.value}
                        disabled={!accessTokenKey.value}
                        iconRender={visible =>
                          visible
                            ? (
                              <EyeTwoTone />
                              )
                            : accessTokenKey.value
                              ? (
                                <EyeInvisibleOutlined />
                                )
                              : (
                                <LoadingOutlined />
                                )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Access Token Secret Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input.Password
                        styles={{ borderRadius: '6px', borderColour: 'black' }}
                        bordered
                        value={accessTokenSecretKey.value}
                        disabled={!accessTokenSecretKey.value}
                        iconRender={visible =>
                          visible
                            ? (
                              <EyeTwoTone />
                              )
                            : accessTokenKey.value
                              ? (
                                <EyeInvisibleOutlined />
                                )
                              : (
                                <LoadingOutlined />
                                )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Consumer Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={scratchStore.consumerKey}
                        onChange={handleAPIKeyChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 160 }}
                    >
                      Consumer Secret Key :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <Input.Password
                        styles={{ borderRadius: '6px', borderColour: 'black' }}
                        bordered
                        value={consumerSecretKey.value}
                        disabled={!consumerSecretKey.value}
                        iconRender={visible =>
                          visible
                            ? (
                              <EyeTwoTone />
                              )
                            : accessTokenKey.value
                              ? (
                                <EyeInvisibleOutlined />
                                )
                              : (
                                <LoadingOutlined />
                                )}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      API Path :
                    </td>
                    <td style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}>
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={scratchStore.apiPath}
                        onChange={e => handleApiPathChange(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ paddingTop: 10, width: 700 }}>
                      <Collapse
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                      >
                        <Panel header='Webhooks' key='1'>
                          {storeWebhooks.map(webhook => {
                            return (
                              <Popconfirm
                                key={webhook.topic}
                                icon={webhook.icon}
                                visible={
                                  webhook.popconfirmVisible && editModeEnabled
                                }
                                title={webhook.popconfirmText}
                                okText='Yes'
                                cancelText='No'
                                onConfirm={() => handleWebhookOk(webhook)}
                                onCancel={() => handleWebhookCancel(webhook)}
                              >
                                <Tag
                                  style={{ margin: 4, cursor: 'pointer' }}
                                  color={webhook.created ? 'green' : 'red'}
                                  visible
                                  onClick={() => showPopconfirm(webhook)}
                                >
                                  {webhook.topic}
                                </Tag>
                              </Popconfirm>
                            )
                          })}
                        </Panel>
                      </Collapse>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
          <div className='md:grid md:grid-cols-4 md:gap-6'>
            <div className='md:col-span-1'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>
                Shopify Settings
              </h3>
            </div>
            <div className='mt-5 md:mt-0 md:col-span-3'>
              <table style={{ marginTop: 10 }}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Enable Fulfillment :
                    </td>
                    <td style={{ paddingLeft: 5, width: 120 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                        checked={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.enabled
                        }
                        onChange={handleEnableFulfillmentChange}
                      />
                    </td>
                    <td style={{ textAlign: 'right', width: 120 }}>
                      Inventory Syncing :
                    </td>
                    <td style={{ paddingLeft: 5 }}>
                      <Switch
                        disabled={!editModeEnabled}
                        checkedChildren='On'
                        unCheckedChildren='Off'
                        checked={scratchStore.syncInventoryLevel}
                        onChange={handleSyncInventoryLevelChange}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Order Prefix :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.orderPrefix
                        }
                        onChange={handleOrderPrefixChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Exclude Orders Before :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <DatePicker
                        showTime
                        onChange={handleExcludeOrdersBeforeDateChange}
                        value={
                          excludeOrdersBeforeDateString
                            ? moment(excludeOrdersBeforeDateString, dateFormat)
                            : ''
                        }
                        format={dateFormat}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{ paddingTop: 10, textAlign: 'right', width: 120 }}
                    >
                      Output File Prefix :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 600 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpOutputFilePrefixChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.ftpOutputFilePrefix
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Host :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpHostChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.host
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      User name :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleFtpUsernameChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.username
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Password :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <Input.Password
                        readOnly={!editModeEnabled}
                        value={ftpPassword.value}
                        onChange={handleFtpPasswordChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Inbox Location :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleInboxLocationChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.inboxLocation
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingTop: 10, textAlign: 'right' }}>
                      Outbox Location :
                    </td>
                    <td
                      colSpan={3}
                      style={{ paddingTop: 10, paddingLeft: 5, width: 400 }}
                    >
                      <input
                        type='text'
                        className='mt-1 block w-full border-gray-300 text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500'
                        readOnly={!editModeEnabled}
                        onChange={handleOutboxLocationChange}
                        value={
                          scratchStore.fulfillment &&
                          scratchStore.fulfillment.ftp.outboxLocation
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {editModeEnabled && (
          <div className='flex justify-end'>
            <button
              type='button'
              onClick={() => handleCancel()}
              className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50'
            >
              Cancel
            </button>
            <button
              onClick={() => handleSave()}
              type='submit'
              className='ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700'
            >
              Save
              {waitingForResponse && (
                <svg
                  className='animate-spin ml-3 -mr-1 h-5 w-5 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  />
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  />
                </svg>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ClientStoreMagento.propTypes = {
  onFinish: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  updateCurrentStep: PropTypes.func.isRequired,
  tenant: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  clear: PropTypes.bool.isRequired,
  editModeEnabled: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setDetailsChanged: PropTypes.func.isRequired,
  scratchStore: PropTypes.object.isRequired,
  setScratchStore: PropTypes.func.isRequired
}
