import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Result, Row, Spin } from 'antd'
import { AuthContext } from '../Firebase/firebaseContext'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { LOGIN } from '../../constants/routes'
import { GOOGLE_CLOUD_PROJECT_ID } from '../../constants/constants'
import { doSignOut } from '../Firebase/firebaseConfig'
import Logo from '../img/odeo-logo.png'
import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore'

const Onboarding = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { firestore } = useContext(FirestoreContext)
  const [userDoc, setUserDoc] = useState()
  const [phone, setPhone] = useState('')
  const [fullname, setFullname] = useState('')
  const [company, setCompany] = useState()
  const [detailsEntered, setDetailsEntered] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isNextLogisticsCustomer] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [invitationCode, setInvitationCode] = useState('')
  useEffect(() => {
    if (user) {
      if (firestore) {
        getDoc(doc(firestore, 'users', user.uid)).then(doc => {
          setIsLoading(false)
          if (doc.exists()) {
            setUserDoc({ ...doc.data(), id: doc.id })
            setFullname(doc.data().name)
            if (doc.data().onboarded) {
              setDetailsEntered(true)
            }
          } else {
            // When a user is created in firebase authentication, there should be a firebase function that detects the new account
            // and creates a correponding entry for the new user in the firestore 'users' collection. However, if it has not been created
            // we create it here as a fallback.

            const payload = {
              name: '',
              phone: '',
              approvedByAdmin: true, // Is this supposed to be set to true?
              enabled: true,
              createdAt: new Date(),
              createdBy: user.uid,
              updatedAt: new Date(),
              updatedBy: user.uid,
            }

            setDoc(doc(firestore, 'users', user.uid), payload)
              .then(() => {
                setUserDoc({ ...payload, id: user.uid })
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
      }
    }
  }, [user])

  const onFinish = e => {
    e.preventDefault()
    setDetailsEntered(true)
    const payload = {
      name: fullname,
      phone: phone,
      company: company,
      onboarded: true,
      email: user.email,
      isNextLogisticsCustomer: isNextLogisticsCustomer,
      invitationCode: invitationCode,
      updatedAt: new Date(),
      updatedBy: user.uid,
    }

    updateDoc(doc(firestore, 'users', user.uid), payload)
      .then(() => {
        const newUserDoc = userDoc
        newUserDoc.onboarded = true
        newUserDoc.name = fullname
        newUserDoc.phone = phone
        newUserDoc.company = company
        newUserDoc.isNextLogisticsCustomer = isNextLogisticsCustomer
        setUserDoc({ newUserDoc })

        // If user is not a Next Logistics customer, give them some default permissions so that they can access basic profile page and connector dashboard
        if (!isNextLogisticsCustomer) {
          window
            .fetch(
              `https://australia-southeast1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/user_access_cloud_function`,
              {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ uid: user.uid }),
              },
            )
            .then(res => res.json()) // expecting a json response
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleBackHome = async () => {
    await doSignOut().then(() => {
      navigate(LOGIN)
    })
  }

  return !isLoading ? (
    user ? (
      !detailsEntered ? (
        <div
          className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-16 w-auto" src={Logo} alt="Odeo"/>
            <h2
              className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Onboarding Details
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <form className="space-y-6 " onSubmit={e => onFinish(e)}>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Full name
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      autoComplete="name"
                      required
                      onChange={e => setFullname(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      required
                      onChange={e => setCompany(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone Number
                  </label>
                  <div className="mt-1">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      required
                      onChange={e => setPhone(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <div>
                      <label
                        htmlFor="invitation-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Invitation Code (optional)
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          If you have a code from a valid 3PL partner, please
                          enter it here
                        </p>
                      </label>
                      <div className="mt-4">
                        <input
                          id="invitation-code"
                          name="invitation-code"
                          type="text"
                          placeholder="Invitation Code"
                          onChange={e => setInvitationCode(e.target.value)}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600  border-gray-300 rounded"
                    value={acceptTerms}
                    required
                    onClick={() => setAcceptTerms(!acceptTerms)}
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Accept{' '}
                    <span>
                      <a
                        className="text-indigo-600 hover:text-indigo-500"
                        rel="noreferrer"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/odeo-octoplux.appspot.com/o/public%2Fdoc%2FODEO%20Connector%20Terms%20and%20Conditions.pdf?alt=media"
                      >
                        Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                <div>
                  <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : isNextLogisticsCustomer || userDoc.isNextLogisticsCustomer ? (
        <Result
          style={{ minHeight: '90vh', paddingTop: '20vh' }}
          status="success"
          title="Thank you for filling in your details"
          subTitle="We are reviewing your details now. This may take up to 1-2 business days. You will be notified of any changes in your email"
          extra={[
            <button
              key="back-button"
              type="button"
              onClick={() => handleBackHome()}
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Back Home
            </button>,
          ]}
        />
      ) : (
        <Navigate to="/private/connector-dashboard"/>
      )
    ) : (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            onClick={() => {
              Navigate(LOGIN)
            }}
            type="primary"
          >
            Back to Login
          </Button>
        }
      />
    )
  ) : (
    <Row justify="center" style={{ marginTop: '20vh' }}>
      <Spin size="large"/>
    </Row>
  )
}

export default Onboarding
