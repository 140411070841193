import { useContext, useEffect, useState } from 'react'
import { ScaleIcon } from '@heroicons/react/outline'
import {
  CashIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  OfficeBuildingIcon
} from '@heroicons/react/solid'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import random from 'random-number'
import { FirestoreContext } from '../Firebase/firestoreContext'
import { useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import {
  GOOGLE_API_GATEWAY_2,
  USER_SESSION_MAX_IDLE_TIME_MINUTES,
} from '../../constants/constants'
import { LOGOUT } from '../../constants/routes'
import { AuthContext } from '../Firebase/firebaseContext'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

const cards = [
  {
    name: 'Orders Received (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '3542'
  },
  {
    name: 'Orders Fulfilled (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '209'
  },
  {
    name: 'Total value of orders (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '$25000.00'
  },
  {
    name: 'Orders Received (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '3542'
  },
  {
    name: 'Orders Fulfilled (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '209'
  },
  {
    name: 'Total value of orders (last 7 days)',
    href: '#',
    icon: ScaleIcon,
    amount: '$25000.00'
  }
  // More items...
]
const transactions = [
  {
    id: 1,
    name: 'Payment to Molly Sanders',
    href: '#',
    amount: '$20,000',
    currency: 'USD',
    status: 'success',
    date: 'July 11, 2020',
    datetime: '2020-07-11'
  }
  // More transactions...
]
const statusStyles = {
  success: 'bg-green-100 text-green-800',
  processing: 'bg-yellow-100 text-yellow-800',
  failed: 'bg-gray-100 text-gray-800'
}

const Dashboard = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { userSessionDoc } = useContext(FirestoreContext)
  const [sessionHeartbeatSent, setSessionHeartbeatSent] = useState(false)

  useEffect(() => {
    console.log('Home here 100 userSessionDoc', userSessionDoc)
    // User needs to be authenticated before using this page
    if (userSessionDoc && userSessionDoc.heartbeatUpdatedAt) {
      console.log('Home here 110')
      const inactiveSessionExpiry = DateTime.now().minus({ minutes: USER_SESSION_MAX_IDLE_TIME_MINUTES })
      if (inactiveSessionExpiry.toSeconds() > userSessionDoc.heartbeatUpdatedAt.seconds) {
        // user session has timed out due to inactivity. Log the user out.
        console.error('session expired.')
        navigate(LOGOUT)
      } else {
        // user is authenticated and user session has not timed out due to inactivity
        console.log('session not expired.')
        sendUserSessionHeartbeat()
      }
    } else if (userSessionDoc) {
      console.log('Home here 120')
      // Display side navbar if user is fully authenticated
      sendUserSessionHeartbeat()
    }
  }, [userSessionDoc])

  const sendUserSessionHeartbeat = async () => {
    if (!sessionHeartbeatSent) {
      const idToken = await user.getIdToken()
      window
        .fetch(
          `${GOOGLE_API_GATEWAY_2}/user-session-heartbeat/?userSessionId=${userSessionDoc.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + idToken
            }
          }
        ).then(res => res.json())
        .then(() => {
          setSessionHeartbeatSent(true)
        })
    }
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: 'Chart.js Line Chart'
      }
    }
  }

  const labels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July'
  ]

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => random({ min: -1000, max: 1000 })),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => random({ min: -1000, max: 1000 })),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)'
      }
    ]
  }

  return (
    <>
      <main className='flex-1 relative pb-8 z-0 overflow-y-auto bg-gray-100'>
        <div className='bg-white shadow'>
          <div className='px-4 sm:px-6 lg:max-w-8xl lg:mx-auto lg:px-8'>
            <div className='py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200'>
              <div className='flex-1 min-w-0'>
                {/* Profile */}
                <div className='flex items-center'>
                  <img
                    className='hidden h-16 w-16 rounded-full sm:block'
                    src='https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                    alt=''
                  />
                  <div>
                    <div className='flex items-center'>
                      <img
                        className='h-16 w-16 rounded-full sm:hidden'
                        src='https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg'
                        alt=''
                      />
                      <h1 className='ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate'>
                        Good morning x
                      </h1>
                    </div>
                    <dl className='mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                      <dt className='sr-only'>Company</dt>
                      <dd className='flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6'>
                        <OfficeBuildingIcon
                          className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400'
                          aria-hidden='true'
                        />
                        Duke street studio
                      </dd>
                      <dt className='sr-only'>Account status</dt>
                      <dd className='mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize'>
                        <CheckCircleIcon
                          className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
                          aria-hidden='true'
                        />
                        Verified account
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-8'>
          <div className='max-w-8xl mx-auto px-4 sm:px-6 lg:px-8'>
            <h2 className='text-lg leading-6 font-medium text-gray-900'>
              Overview
            </h2>
            <div className='mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3'>
              {/* Card */}
              {cards.map(card => (
                <div
                  key={card.name}
                  className='bg-white overflow-hidden shadow rounded-lg'
                >
                  <div className='p-5'>
                    <div className='flex items-center'>
                      <div className='flex-shrink-0'>
                        <card.icon
                          className='h-6 w-6 text-gray-400'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='ml-5 w-0 flex-1'>
                        <dl>
                          <dt className='text-sm font-medium text-gray-500 truncate'>
                            {card.name}
                          </dt>
                          <dd>
                            <div className='text-lg font-medium text-gray-900'>
                              {card.amount}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className='bg-gray-50 px-5 py-3'>
                    <div className='text-sm'>
                      <a
                        href={card.href}
                        className='font-medium text-cyan-700 hover:text-cyan-900'
                      >
                        View all
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className='max-w-8xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8'>
            Recent activity
          </h2>

          {/* Activity list (smallest breakpoint only) */}
          <div className='shadow sm:hidden'>
            <Line options={options} data={data} />
          </div>
          {/* Activity table (small breakpoint and up) */}
          <div className='hidden sm:block mx-16'>
            <Line options={options} data={data} />
          </div>
        </div>
      </main>
    </>
  )
}

export default Dashboard
