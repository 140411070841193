import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './components/Firebase/firebaseContext'
import { FirestoreProvider } from './components/Firebase/firestoreContext'
import { AppSettingsProvider } from './components/Context/appSettingsContext'
import { BrowserRouter } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthProvider>
      <FirestoreProvider>
        <AppSettingsProvider>

            <App />
        </AppSettingsProvider>
      </FirestoreProvider>
    </AuthProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
