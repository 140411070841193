import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navigation from '../Navigation'
import * as ROUTES from '../../constants/routes'
import { AuthContext } from '../Firebase/firebaseContext'
import ProtectedRoute from '../ProtectedRoute'
import 'antd/dist/antd.min.css'
import Logout from '../Logout'
import Onboarding from '../Onboarding'
import LinkStore from '../LinkStore'
import Next3PLClient from '../Next3PL/client'
import OrderStatus from '../OrderStatus'
import Products from '../Products'
import ConnectorDashboard from '../ConnectorDashboard'
import EbayOauthSuccess from '../ConnectorDashboard/ebayOauthSuccessPage'
import XeroOauthSuccess from '../ConnectorDashboard/xeroOauthSuccessPage'
import Dashboard from '../Dashboard'
import Header from '../Header'
import XeroLinkingPage from '../ConnectorDashboard/xeroLinkingPage'
import ViewConnectors from '../ViewConnectors'
import Login from '../Login'

const App = () => {
  const { user, loading } = useContext(AuthContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    console.log('App user =', user)
  }, [user])

  return (
    <Router>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Navigation
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <Header
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <main className='min-h-screen bg-gray-100'>
            <div className="">
              <div className="mx-auto">
                {!loading && (
                <Routes>
                  <Route exact={true} path="/" element={<Login/>}/>
                  <Route exact={true} path={ROUTES.LOGIN} element={<Login/>}/>

                  <Route
                    exact={true}
                    path={ROUTES.LOGOUT}
                    element={
                      <ProtectedRoute user={user}>
                        <Logout/>
                      </ProtectedRoute>}
                  />
                  <Route
                    exact={true}
                    path={ROUTES.DASHBOARD}
                    element={
                      <ProtectedRoute user={user}>
                        <Dashboard/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.USER_ONBOARDING}
                    tenantRoute={false}
                    element={
                      <ProtectedRoute user={user}>
                        <Onboarding/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.EBAY_OAUTH_SUCCESS}
                    element={
                      <ProtectedRoute user={user}>
                        <EbayOauthSuccess/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.XERO_OAUTH_SUCCESS}
                    element={
                      <ProtectedRoute user={user}>
                        <XeroOauthSuccess/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.XERO_LINKS}
                    element={
                      <ProtectedRoute user={user}>
                        <XeroLinkingPage/>
                      </ProtectedRoute>}
                  />

                  {/* <ProtectedRoute
                          isAuthenticated={isAuthenticated}
                          path={ROUTES.NEXT3PL_TENANT_PROFILE}
                          tenantRoute={true}
                          element={Next3PLTenantProfile}
                        /> */}

                  <Route
                    exact={true}
                    path={ROUTES.NEXT3PL_CLIENT}
                    element={
                      <ProtectedRoute user={user}>
                        <Next3PLClient/>
                      </ProtectedRoute>}
                  />
                  {/* <ProtectedRoute
                          isAuthenticated={isAuthenticated}
                          path={ROUTES.USER_PROFILE}
                          tenantRoute={false}
                          element={UserProfile}
                        /> */}

                  <Route
                    exact={true}
                    path={ROUTES.ADD_CONNECTOR}
                    element={
                      <ProtectedRoute user={user}>
                        <ConnectorDashboard/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.VIEW_CONNECTORS}
                    element={
                      <ProtectedRoute user={user}>
                        <ViewConnectors/>
                      </ProtectedRoute>}
                  />

                  {/* <ProtectedRoute
                          isAuthenticated={isAuthenticated}
                          path={ROUTES.BILLING}
                          tenantRoute={false}
                          element={Billing}
                        /> */}

                  <Route
                    exact={true}
                    path={ROUTES.LINK_STORE}
                    element={
                      <ProtectedRoute user={user}>
                        <LinkStore/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.ORDER_STATUS}
                    element={
                      <ProtectedRoute user={user}>
                        <OrderStatus/>
                      </ProtectedRoute>}
                  />

                  <Route
                    exact={true}
                    path={ROUTES.PRODUCTS}
                    element={
                      <ProtectedRoute user={user}>
                        <Products/>
                      </ProtectedRoute>}
                  />
                </Routes>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </Router>
  )
}

export default App
